import React, {useContext} from 'react';
import Drawer from '@mui/material/Drawer';
import clsx from 'clsx';
import UserInfo from '../../../../shared/components/UserInfo';
import Navigation from '../../Navigation/VerticleNav';
import {toggleNavCollapsed} from '../../../../redux/ui/Actions';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import useStyles from './AppSidebar.style';
import Scrollbar from '../../Scrollbar';
import AppContext from '../../../utility/AppContext';
import AppContextPropsType from '../../../../types/AppContextPropsType';
import {selectUI} from '../../../../redux/ui/Selectors';

interface AppSidebarProps {
  variant?: string;
  position?: 'left' | 'bottom' | 'right' | 'top';
}

const AppSidebar: React.FC<AppSidebarProps> = ({
  position = 'left',
  variant,
}) => {
  const dispatch = useDispatch();
  const {themeMode} = useContext<AppContextPropsType>(AppContext);
  const {navCollapsed} = useSelector(selectUI);

  const handleToggleDrawer = () => {
    dispatch(toggleNavCollapsed());
  };

  const classes = useStyles({themeMode});
  const sidebarClasses = classes.sidebarStandard;

  return (
    <>
      <Drawer
        anchor={position}
        open={navCollapsed}
        onClose={ev => handleToggleDrawer()}
        classes={{
          root: clsx(variant),
          paper: clsx(variant),
        }}
        style={{position: 'absolute'}}
        sx={{ display: { md: 'none', xs: 'block' } }}
      >
        <Box height='100%'>
          <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
            <UserInfo />
            <Scrollbar
              scrollToTop={false}
              className={classes.drawerScrollAppSidebar}>
              <Navigation />
            </Scrollbar>
          </Box>
        </Box>
      </Drawer>
      <Box
        height='100%'
        sx={{ display: { xs: 'none', md: 'block' } }}
        className={clsx(
          classes.container,
          'app-sidebar',
          navCollapsed ? '' : 'mini-sidebar-collapsed',
        )}>
        <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
          <UserInfo />
          <Scrollbar scrollToTop={false} className={classes.scrollAppSidebar}>
            <Navigation />
          </Scrollbar>
        </Box>
      </Box>
    </>
  );
};

export default AppSidebar;
