export class AdminUserSearchItem implements IAdminUserSearchItem {
    id?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    personNumber?: number | undefined;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    firstName?: string | undefined;
    knownAsName?: string | undefined;
    lastName?: string | undefined;
    middleName?: string | undefined;
    email?: string | undefined;
    phoneNumbers?: string[] | undefined;
    linkedInUrl?: string | undefined;
    isContact?: boolean;
    isAdminUser?: boolean;
    isAdminUserDisabled?: boolean;
    isUser?: boolean;
    isDeleted?: boolean;
    dateDeleted?: Date | undefined;
    deletedByUserId?: string | undefined;
    fullName?: string | undefined;
    isDisabled?: boolean;
    dateDisabled?: Date | undefined;
    disabledByUserId?: string | undefined;
    disabledReason?: string | undefined;
    jobTitle?: string | undefined;
    jobDepartment?: string | undefined;
    userReference?: string | undefined;

    constructor(data?: IAdminUserSearchItem) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'];
            this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
            this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
            this.personNumber = _data['personNumber'];
            this.createdByPersonId = _data['createdByPersonId'];
            this.updatedByPersonId = _data['updatedByPersonId'];
            this.firstName = _data['firstName'];
            this.knownAsName = _data['knownAsName'];
            this.lastName = _data['lastName'];
            this.middleName = _data['middleName'];
            this.email = _data['email'];
            if (Array.isArray(_data['phoneNumbers'])) {
                this.phoneNumbers = [] as any;
                for (const item of _data['phoneNumbers'])
                    this.phoneNumbers!.push(item);
            }
            this.linkedInUrl = _data['linkedInUrl'];
            this.isContact = _data['isContact'];
            this.isAdminUser = _data['isAdminUser'];
            this.isAdminUserDisabled = _data['isAdminUserDisabled'];
            this.isUser = _data['isUser'];
            this.isDeleted = _data['isDeleted'];
            this.dateDeleted = _data['dateDeleted'] ? new Date(_data['dateDeleted'].toString()) : <any>undefined;
            this.deletedByUserId = _data['deletedByUserId'];
            this.fullName = _data['fullName'];
            this.isDisabled = _data['isDisabled'];
            this.dateDisabled = _data['dateDisabled'] ? new Date(_data['dateDisabled'].toString()) : <any>undefined;
            this.disabledByUserId = _data['disabledByUserId'];
            this.disabledReason = _data['disabledReason'];
            this.jobTitle = _data['jobTitle'];
            this.jobDepartment = _data['jobDepartment'];
            this.userReference = _data['userReference'];
        }
    }

    static fromJS(data: any): AdminUserSearchItem {
        data = typeof data === 'object' ? data : {};
        const result = new AdminUserSearchItem();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id;
        data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
        data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
        data['personNumber'] = this.personNumber;
        data['createdByPersonId'] = this.createdByPersonId;
        data['updatedByPersonId'] = this.updatedByPersonId;
        data['firstName'] = this.firstName;
        data['knownAsName'] = this.knownAsName;
        data['lastName'] = this.lastName;
        data['middleName'] = this.middleName;
        data['email'] = this.email;
        if (Array.isArray(this.phoneNumbers)) {
            data['phoneNumbers'] = [];
            for (const item of this.phoneNumbers)
                data['phoneNumbers'].push(item);
        }
        data['linkedInUrl'] = this.linkedInUrl;
        data['isContact'] = this.isContact;
        data['isAdminUser'] = this.isAdminUser;
        data['isAdminUserDisabled'] = this.isAdminUserDisabled;
        data['isUser'] = this.isUser;
        data['isDeleted'] = this.isDeleted;
        data['dateDeleted'] = this.dateDeleted ? this.dateDeleted.toISOString() : <any>undefined;
        data['deletedByUserId'] = this.deletedByUserId;
        data['fullName'] = this.fullName;
        data['isDisabled'] = this.isDisabled;
        data['dateDisabled'] = this.dateDisabled ? this.dateDisabled.toISOString() : <any>undefined;
        data['disabledByUserId'] = this.disabledByUserId;
        data['disabledReason'] = this.disabledReason;
        data['jobTitle'] = this.jobTitle;
        data['jobDepartment'] = this.jobDepartment;
        data['userReference'] = this.userReference;
        return data;
    }
}

export interface IAdminUserSearchItem {
    id?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    personNumber?: number | undefined;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    firstName?: string | undefined;
    knownAsName?: string | undefined;
    lastName?: string | undefined;
    middleName?: string | undefined;
    email?: string | undefined;
    phoneNumbers?: string[] | undefined;
    linkedInUrl?: string | undefined;
    isContact?: boolean;
    isAdminUser?: boolean;
    isAdminUserDisabled?: boolean;
    isUser?: boolean;
    isDeleted?: boolean;
    dateDeleted?: Date | undefined;
    deletedByUserId?: string | undefined;
    fullName?: string | undefined;
    isDisabled?: boolean;
    dateDisabled?: Date | undefined;
    disabledByUserId?: string | undefined;
    disabledReason?: string | undefined;
    jobTitle?: string | undefined;
    jobDepartment?: string | undefined;
    userReference?: string | undefined;
}