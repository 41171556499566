import {createAction} from '@reduxjs/toolkit';
import {ICommonCacheActionType} from '../Common.actions';
import {AccountSearchItem} from '../../types/views/AccountSearchItem';


export const ACCOUNT_CACHE: ICommonCacheActionType = {
  ADD: 'accountCache/add',
  UPDATE: 'accountCache/updated',
  MERGE_ITEMS: 'accountCache/merge',
  ERROR: 'accountCache/error',
  REMOVE: 'accountCache/remove'
};

export const accountCacheAdd = createAction<string[]>(ACCOUNT_CACHE.ADD);
export const accountCacheUpdate = createAction<string[]>(ACCOUNT_CACHE.UPDATE);
export const accountCacheMerge = createAction<AccountSearchItem[]>(ACCOUNT_CACHE.MERGE_ITEMS);
export const accountCacheError = createAction<string>(ACCOUNT_CACHE.ERROR);
export const accountCacheRemove = createAction<string>(ACCOUNT_CACHE.REMOVE);
