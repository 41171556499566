import React from 'react';
import {AppRouteData, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {sessionPaths} from './paths';
import { accountPaths } from '../accounts/paths';


const sessionSectionBreadcrumbs = [new BreadcrumbItemProps('Accounts', accountPaths.AccountSearch)];

const DetailPage = React.lazy(() => import('./SessionProfile'));
const SearchPage = React.lazy(() => import('../accounts/AccountSearch'));

const sessionRouteConfig = [
    { path: sessionPaths.SessionSearch, element: <SearchPage />, handle: AppRouteData.create('Search', undefined, sessionSectionBreadcrumbs) } ,
    { path: sessionPaths.SessionProfile, element: <DetailPage />, handle: AppRouteData.create('Details', undefined, sessionSectionBreadcrumbs) } ,
];

export default sessionRouteConfig;