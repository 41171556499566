import { createSelector } from '@reduxjs/toolkit';
import {AppState} from '../store';

export const selectUI = (state: AppState) => state.ui;

export const selectUILoading = createSelector(
  selectUI,
  (state) => state.loading
);

export const selectUIError = createSelector(
  selectUI,
  (state) => state.error
);

export const selectIsAppDrawerOpen = createSelector(
  selectUI,
  (state) => state.isAppDrawerOpen
);

export const selectExpandedCategories = createSelector(
  selectUI,
  (state) => state.expandedCategories
);