import {AppRouteData, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import React from 'react';
import {AppPermissions} from '../../shared/constants/AppEnums';
import {inboxPaths} from './paths';

const sectionBreadcrumbs = [new BreadcrumbItemProps('Inbox', inboxPaths.InboxView)];

const InboxPage = React.lazy(() => import('./Inbox'));
const ConversationPage = React.lazy(() => import('./Inbox'));

const messagingAuth =[
  AppPermissions.PlatformAdmin,
];

const messagingRouteConfig = [
  { path: inboxPaths.InboxView, element: <InboxPage />, handle: AppRouteData.create('Inbox', messagingAuth, sectionBreadcrumbs) } ,
  { path: inboxPaths.InboxThreadView, element: <ConversationPage />, handle: AppRouteData.create('Conversation', messagingAuth, sectionBreadcrumbs) } ,
]

export default messagingRouteConfig;
