import {all, call, takeEvery, put, select} from '@redux-saga/core/effects';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {PayloadAction} from '@reduxjs/toolkit';
import {AppState} from '../store';
import {IAccountParticipantsCacheState} from './Reducer';
import {coreApiConfig} from '../../config';
import {parseErrorInfo} from '../../@template/helpers/errors';
import {AxiosResponse} from 'axios';
import {
  ACCOUNT_PARTICIPANT_CACHE,
  accountParticipantCacheError,
  accountParticipantCacheMerge,
  accountParticipantCacheUpdate
} from './Actions';
import {AccountParticipantSearchItem} from '../../types/views/AccountParticipantSearchItem';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const accountParticipantsCacheState = (state: AppState) => state.peopleCache;

export function* LoadAccountParticipantCacheItems(idList: string[]) {
  console.log('SAGA - accountParticipantsCacheSaga - LoadAccountParticipantCacheItems');
  try {
    const res: AxiosResponse = yield authApi.request({
      baseURL: coreApiConfig.Url,
      method: 'PUT',
      url: '/accountParticipants/list',
      data: idList
    });
    const people: AccountParticipantSearchItem[] = res.data.map((x: any) => AccountParticipantSearchItem.fromJS(x));
    yield put(accountParticipantCacheMerge(people));
  } catch (err) {
    yield put(accountParticipantCacheError(parseErrorInfo(err)));
  }
}

export function* AddAccountParticipantCacheItems() {
  console.log('SAGA - accountParticipantsCacheSaga - AddAccountParticipantCacheItems');
  yield call(delay, 200);
  const currentAccountParticipantCache: IAccountParticipantsCacheState = yield select(accountParticipantsCacheState);
  const currentPendingList = currentAccountParticipantCache.accountParticipantPendingIdList;
  if (currentPendingList && currentPendingList.length > 0) {
    yield put(accountParticipantCacheUpdate([...currentPendingList]));
  }
}

export function* watchLoadAccountParticipantCacheItems(){
  yield takeEvery(ACCOUNT_PARTICIPANT_CACHE.UPDATE, ({ payload }: PayloadAction<string[]>) => LoadAccountParticipantCacheItems(payload));
}

export function* watchAddAccountParticipantCacheItems(){
  yield takeEvery(ACCOUNT_PARTICIPANT_CACHE.ADD, ({ payload }: PayloadAction<string[]>) => AddAccountParticipantCacheItems());
}

export default function* accountParticipantsCacheSaga() {
  yield all ([
    call(watchLoadAccountParticipantCacheItems),
    call(watchAddAccountParticipantCacheItems)
  ])
}