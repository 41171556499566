import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {FILE} from './Actions';
import {FileMetadata} from '../../types/models/files/FileMetadata';
import {defaultFileSearchRequest, FileMetadataSearchRequest} from '../../types/requests/FileMetadataSearchRequest';
import {SearchRequest} from '../../types/common/SearchRequest';
import { PagedEntityListOfFileMetadataSearchItem } from 'types/views/PagedEntityListOfFileMetadataSearchItem';

export interface IFileState {
  file: FileMetadata | undefined;
  fileLoading: boolean;
  fileLoadError: string | undefined;
  fileSaving: boolean;
  fileSaveError: string | undefined;
  filesSearchParams: FileMetadataSearchRequest;
  filesSearchResult: PagedEntityListOfFileMetadataSearchItem | undefined;
  filesSearching: boolean;
  filesRefreshing: boolean;
  filesSearchError: string | undefined;
}

const initialState: IFileState = {
  file: undefined,
  fileLoading: false,
  fileLoadError: undefined,
  fileSaving: false,
  fileSaveError: undefined,
  filesSearchParams: defaultFileSearchRequest(),
  filesSearchResult: undefined,
  filesSearching: false,
  filesRefreshing: false,
  filesSearchError: undefined,
}

const filesReducer = createReducer(initialState, {
  [FILE.LOAD]: (state) => {
    state.file = undefined;
    state.fileLoading = true;
    state.fileLoadError = undefined;
  },
  [FILE.LOADED]: (state, action: PayloadAction<FileMetadata>) => {
    state.file = action.payload;
    state.fileLoading = false;
    state.fileLoadError = undefined;
  },
  [FILE.SAVE]: (state) => {
    state.fileSaving = true;
    state.fileSaveError = undefined;
  },
  [FILE.SAVED]: (state, action: PayloadAction<FileMetadata>) => {
    state.file = action.payload;
    state.fileSaving = false;
    state.fileSaveError = undefined;
  },
  [FILE.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.fileSaving = false;
    state.fileLoading = false;
    state.fileLoadError = action.payload;
  },
  [FILE.SEARCH]: (state, action: PayloadAction<SearchRequest<FileMetadataSearchRequest>>) => {
    state.filesSearchParams = action.payload.searchParams;
    state.filesSearchError = undefined;
    state.filesSearching = true;
  },
  [FILE.SEARCH_REFRESH]: (state, action: PayloadAction<SearchRequest<FileMetadataSearchRequest>>) => {
    state.filesSearchParams = action.payload.searchParams;
    state.filesSearchError = undefined;
    state.filesRefreshing = true;
    state.filesSearching = false;
  },
  [FILE.SEARCH_SUCCESS]: (state, action: PayloadAction<PagedEntityListOfFileMetadataSearchItem>) => {
    state.filesSearching = false;
    state.filesRefreshing = false;
    state.filesSearchError = undefined;
    state.filesSearchResult = action.payload;
  },
  [FILE.SEARCH_ERROR]: (state, action: PayloadAction<string>) => {
    state.filesSearching = false;
    state.filesRefreshing = false;
    state.filesSearchError = action.payload;
  },
});

export default filesReducer;
