import {differenceInMilliseconds} from 'date-fns';
import {IMessagingState} from './Reducer';
import {createSelector} from '@reduxjs/toolkit';

export const selectMessagingState = (state: { messaging: IMessagingState }) => state.messaging;

// Groups
export const selectGroupList = createSelector(
  selectMessagingState,
  (subState) => subState.groupsList
);
export const selectGroupsError = createSelector(
  selectMessagingState,
  (subState) => subState.groupsError
);
export const selectGroupsLoading = createSelector(
  selectMessagingState,
  (subState) => subState.groupsLoading
);
export const selectGroupsRefreshing = createSelector(
  selectMessagingState,
  (subState) => subState.groupsRefreshing
);
export const selectGroupThreads = createSelector(
  selectMessagingState,
  (subState) => subState.groupsThreadsList
);
export const selectGroupThreadsError = createSelector(
  selectMessagingState,
  (subState) => subState.groupsThreadsError
);
export const selectGroupThreadsLoading = createSelector(
  selectMessagingState,
  (subState) => subState.groupsThreadsLoading
);
// Summary
export const selectNotificationsSummary = createSelector(
  selectMessagingState,
  (subState) => subState.notificationsSummary
);
export const selectNotificationsSummaryLoading = createSelector(
  selectMessagingState,
  (subState) => subState.notificationsLoading
);
export const selectNotificationsSummaryError = createSelector(
  selectMessagingState,
  (subState) => subState.notificationsError
);
export const selectNotificationsSummaryRefreshing = createSelector(
  selectMessagingState,
  (subState) => subState.notificationsRefreshing
);
// Feed
export const selectMessageSearchParams = createSelector(
  selectMessagingState,
  (subState) => subState.messageSearch
);
const selectMessageListState = createSelector(
  selectMessagingState,
  (subState) => subState.messageList
);
export const selectMessageList = createSelector(
  selectMessageListState,
  (subState) => {
    if (subState) {
      const defaultDate = new Date(1900, 1, 1);
      return [...subState].sort((a, b) => differenceInMilliseconds(a.dateCreated??defaultDate, b.dateCreated ??defaultDate))
    } else {
      return [];
    }
  }
);

export const selectMessageListIsFullHistory = createSelector(
  selectMessagingState,
  (subState) => subState.messageListIsFullHistory
);
export const selectMessageListLoading = createSelector(
  selectMessagingState,
  (subState) => subState.messageListLoading
);
export const selectMessageListRefreshing = createSelector(
  selectMessagingState,
  (subState) => subState.messageListRefreshing
);
export const selectMessageListError = createSelector(
  selectMessagingState,
  (subState) => subState.messageListError
);
export const selectMessageListHistoryLoading = createSelector(
  selectMessagingState,
  (subState) => subState.messageListHistoryLoading
);
// ActionItemMessage
export const selectMessageSaving = createSelector(
  selectMessagingState,
  (subState) => subState.messageSaving
);
export const selectMessageError = createSelector(
  selectMessagingState,
  (subState) => subState.messageError
);
export const selectThread = createSelector(
  selectMessagingState,
  (subState) => subState.thread
);
export const selectThreadLoading = createSelector(
  selectMessagingState,
  (subState) => subState.threadLoading
);
export const selectThreadError = createSelector(
  selectMessagingState,
  (subState) => subState.threadError
);
