import React, {useEffect, useState} from 'react';
import {useAppDispatch} from '../../../@template/utility/AppHooks';
import {useSelector} from 'react-redux';
import {accountCacheAdd} from '../../../redux/accountCache/Actions';
import {generatePath} from 'react-router';
import {accountPaths} from '../paths';
import {toSoftBrokenString} from '../../../@template/helpers/strings';
import Box from '@mui/material/Box';
import AppNavLink from '../../../@template/core/AppNavLink';
import {AppState} from '../../../redux/store';
import {AccountSearchItem} from '../../../types/views/AccountSearchItem';

const getCacheItem = (id: string | undefined) => (state: AppState) => {
  if (!id) return undefined;
  return state.accountCache.accountCache.find((x: AccountSearchItem) => x.id === id);
}

export interface IAccountProfileLinkProps {
  accountId?: string | undefined;
  className?: string | undefined;
  linkProfile?: boolean | undefined;
  wrapContent?: boolean | undefined;
  showIcon?: boolean | undefined;
}

/*
* Links should now be styled by their parent classname
*/
const AccountProfileLink = ({ accountId, linkProfile, className, wrapContent }: IAccountProfileLinkProps) => {
  const dispatch = useAppDispatch();
  const [currentAccountId] = useState(accountId);

  const accountItem = useSelector(getCacheItem(accountId));

  useEffect(() => {
    if (!accountItem && currentAccountId) {
      dispatch(accountCacheAdd([currentAccountId]));
    }
  }, [accountId]);

  if (accountItem) {
    if (linkProfile) {
      return (
        <AppNavLink to={generatePath(accountPaths.AccountProfile, { idParam: currentAccountId })} className={className}>
          {wrapContent ? toSoftBrokenString(accountItem.companyName) : accountItem.companyName }
        </AppNavLink>
      );
    } else {
      return (
        <Box component="span" className={className}>
          {wrapContent ? toSoftBrokenString(accountItem.companyName) : accountItem.companyName}
        </Box>
      );
    }
  }

  return <>Loading account</>;
};

export default AccountProfileLink;