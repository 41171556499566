import {InstructorUserView} from '../../types/views/InstructorUserView';
import {IError} from '../../@template/helpers/errors';
import {defaultForInstructorUserSearchRequest, InstructorUserSearchRequest} from '../../types/requests/InstructorUserSearchRequest';
import {DefaultSortByOptions} from '../../types/common/SortByOptions/DefaultSortByOptions';
import {SortOrderOptions} from '../../types/common/SortOrderOptions';
import {PagedEntityListOfInstructorUserSearchItem} from '../../types/views/PagedEntityListOfInstructorUserSearchItem';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {SearchRequest} from '../../types/common/SearchRequest';
import {INSTRUCTOR_USER_SEARCH, INSTRUCTOR_USERS} from './Actions';
import {PagedEntityListOfAccountSearchItem} from '../../types/views/PagedEntityListOfAccountSearchItem';
import {INSTRUCTOR_USER_SESSION_SEARCH} from '../instructorUser/Actions';
import {SessionSearchRequest} from '../../types/requests/SessionSearchRequest';
import {PagedEntityListOfSessionSearchItem} from '../../types/views/PagedEntityListOfSessionSearchItem';


export interface IInstructorUserState {
    instructorUser: InstructorUserView | undefined;
    instructorUserLoading: boolean | false;
    instructorUserLoadError: string | undefined;
    instructorUserSaving: boolean | false;
    instructorUserSaveError: IError | undefined;
    instructorUserSearchParams: InstructorUserSearchRequest;
    instructorUserSearchSortOption: DefaultSortByOptions;
    instructorUserSearchSortOrder: SortOrderOptions;
    instructorUsersResult: PagedEntityListOfInstructorUserSearchItem | undefined;
    instructorUsersSearching: boolean;
    instructorUserSearchError: string | undefined;
    instructorUserInstructorUsersResult: PagedEntityListOfInstructorUserSearchItem | undefined;
    instructorUserInstructorUsersSearching: boolean;
    instructorUserInstructorUsersError: string | undefined;
    instructorUserSessionsResult: PagedEntityListOfAccountSearchItem | undefined,
    instructorUserSessionsSearching: boolean;
    instructorUserSessionsError: string | undefined;
}

const initialState: IInstructorUserState = {
    instructorUser: undefined,
    instructorUserLoading: false,
    instructorUserLoadError: undefined,
    instructorUserSaving: false,
    instructorUserSaveError: undefined,
    instructorUserSearchParams: defaultForInstructorUserSearchRequest(),
    instructorUserSearchSortOption: DefaultSortByOptions.Relevance,
    instructorUserSearchSortOrder: SortOrderOptions.Desc,
    instructorUsersResult: undefined,
    instructorUsersSearching: false,
    instructorUserSearchError: undefined,
    instructorUserInstructorUsersResult: undefined,
    instructorUserInstructorUsersSearching: false,
    instructorUserInstructorUsersError: undefined,
    instructorUserSessionsResult: undefined,
    instructorUserSessionsSearching: false,
    instructorUserSessionsError: undefined
}

const instructorUserReducer = createReducer(initialState, {
    [INSTRUCTOR_USERS.LOAD]: (state) => {
        state.instructorUser = undefined;
        state.instructorUserLoading = true;
        state.instructorUserLoadError = undefined;
        state.instructorUserSaveError = undefined;
    },
    [INSTRUCTOR_USERS.REFRESH]: (state) => {
        state.instructorUserLoading = false;
        state.instructorUserLoadError = undefined;
        state.instructorUserSaveError = undefined;
    },
    [INSTRUCTOR_USERS.LOADED]: (state, action: PayloadAction<InstructorUserView>) => {
        state.instructorUserLoading = false;
        state.instructorUserLoadError = undefined;
        state.instructorUser = action.payload;
    },
    [INSTRUCTOR_USERS.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
        state.instructorUserLoading = false;
        state.instructorUserLoadError = action.payload;
        state.instructorUser = undefined;
    },
    [INSTRUCTOR_USERS.SAVE]: (state) => {
        state.instructorUserSaving = true;
        state.instructorUserSaveError = undefined;
    },
    [INSTRUCTOR_USERS.SAVED]: (state, action: PayloadAction<InstructorUserView>) => {
        state.instructorUserSaving = false;
        state.instructorUserSaveError = undefined;
        state.instructorUser = action.payload;
    },
    [INSTRUCTOR_USERS.SAVE_ERROR]: (state, action: PayloadAction<IError>) => {
        state.instructorUserSaving = false;
        state.instructorUserSaveError = action.payload;
    },
    [INSTRUCTOR_USER_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<InstructorUserSearchRequest>>) => {
        state.instructorUserSearchParams = action.payload.searchParams;
        state.instructorUserSearchSortOption = action.payload.sortBy??(DefaultSortByOptions.Relevance);
        state.instructorUserSearchSortOrder = action.payload.sortDirection??(SortOrderOptions.Desc);
        state.instructorUsersSearching = true;
        state.instructorUserSearchError = undefined;
    },
    [INSTRUCTOR_USER_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
        state.instructorUsersSearching = false;
        state.instructorUserSearchError = action.payload;
    },
    [INSTRUCTOR_USER_SEARCH.LOADED]: (state, action: PayloadAction<PagedEntityListOfInstructorUserSearchItem>) => {
        state.instructorUsersSearching = false;
        state.instructorUserSearchError = undefined;
        state.instructorUsersResult = action.payload;
    },
    [INSTRUCTOR_USER_SESSION_SEARCH.REFRESH]: (state, action: PayloadAction<SearchRequest<SessionSearchRequest>>) => {
        state.instructorUserSessionsResult = undefined;
        state.instructorUserSessionsSearching = false;
        state.instructorUserSessionsError = undefined;
    },
    [INSTRUCTOR_USER_SESSION_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
        state.instructorUserSessionsSearching = false;
        state.instructorUserSessionsError = action.payload;
    },
    [INSTRUCTOR_USER_SESSION_SEARCH.LOADED]: (state, action: PayloadAction<PagedEntityListOfSessionSearchItem>) => {
        state.instructorUserSessionsSearching = false;
        state.instructorUserSessionsError = undefined;
        state.instructorUserSessionsResult = action.payload;
    },
});

export default instructorUserReducer;