import {Gender, PrimaryHand} from './Enums';

export class AccountParticipant implements IAccountParticipant {
    id?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    accountId?: string;
    participantPersonId?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    gender?: Gender | undefined;
    customGender?: string | undefined;
    ageRange?: string | undefined;
    primaryHand?: PrimaryHand | undefined;
    jobTitle?: string | undefined;
    jobDepartment?: string | undefined;
    userReference?: string | undefined;
    customTextField1?: string | undefined;
    customTextField2?: string | undefined;
    customTextField3?: string | undefined;
    customSelectField1?: string | undefined;
    customSelectField2?: string | undefined;
    customSelectField3?: string | undefined;
    fullName?: string | undefined;

    constructor(data?: IAccountParticipant) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'];
            this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
            this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
            this.createdByPersonId = _data['createdByPersonId'];
            this.updatedByPersonId = _data['updatedByPersonId'];
            this.accountId = _data['accountId'];
            this.participantPersonId = _data['participantPersonId'];
            this.firstName = _data['firstName'];
            this.lastName = _data['lastName'];
            this.email = _data['email'];
            this.gender = _data['gender'];
            this.customGender = _data['customGender'];
            this.ageRange = _data['ageRange'];
            this.primaryHand = _data['primaryHand'];
            this.jobTitle = _data['jobTitle'];
            this.jobDepartment = _data['jobDepartment'];
            this.userReference = _data['userReference'];
            this.customTextField1 = _data['customTextField1'];
            this.customTextField2 = _data['customTextField2'];
            this.customTextField3 = _data['customTextField3'];
            this.customSelectField1 = _data['customSelectField1'];
            this.customSelectField2 = _data['customSelectField2'];
            this.customSelectField3 = _data['customSelectField3'];
            this.fullName = _data['fullName'];
        }
    }

    static fromJS(data: any): AccountParticipant {
        data = typeof data === 'object' ? data : {};
        const result = new AccountParticipant();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id;
        data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
        data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
        data['createdByPersonId'] = this.createdByPersonId;
        data['updatedByPersonId'] = this.updatedByPersonId;
        data['accountId'] = this.accountId;
        data['participantPersonId'] = this.participantPersonId;
        data['firstName'] = this.firstName;
        data['lastName'] = this.lastName;
        data['email'] = this.email;
        data['gender'] = this.gender;
        data['customGender'] = this.customGender;
        data['ageRange'] = this.ageRange;
        data['primaryHand'] = this.primaryHand;
        data['jobTitle'] = this.jobTitle;
        data['jobDepartment'] = this.jobDepartment;
        data['userReference'] = this.userReference;
        data['customTextField1'] = this.customTextField1;
        data['customTextField2'] = this.customTextField2;
        data['customTextField3'] = this.customTextField3;
        data['customSelectField1'] = this.customSelectField1;
        data['customSelectField2'] = this.customSelectField2;
        data['customSelectField3'] = this.customSelectField3;
        data['fullName'] = this.fullName;
        return data;
    }
}

export interface IAccountParticipant {
    id?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    accountId?: string;
    participantPersonId?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    gender?: Gender | undefined;
    customGender?: string | undefined;
    ageRange?: string | undefined;
    primaryHand?: PrimaryHand | undefined;
    jobTitle?: string | undefined;
    jobDepartment?: string | undefined;
    userReference?: string | undefined;
    customTextField1?: string | undefined;
    customTextField2?: string | undefined;
    customTextField3?: string | undefined;
    customSelectField1?: string | undefined;
    customSelectField2?: string | undefined;
    customSelectField3?: string | undefined;
    fullName?: string | undefined;
}