import {AdminAdminUserDetail} from './AdminAdminUserDetail';

export class AdminUserDetailView implements IAdminUserDetailView {
    dateLastLogin?: Date | undefined;
    mobileDeviceId?: string | undefined;
    notificationId?: string | undefined;
    adminUserDetail?: AdminAdminUserDetail | undefined;

    constructor(data?: IAdminUserDetailView) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.dateLastLogin = _data['dateLastLogin'] ? new Date(_data['dateLastLogin'].toString()) : <any>undefined;
            this.mobileDeviceId = _data['mobileDeviceId'];
            this.notificationId = _data['notificationId'];
            this.adminUserDetail = _data['adminUserDetail'] ? AdminAdminUserDetail.fromJS(_data['adminUserDetail']) : <any>undefined;
        }
    }

    static fromJS(data: any): AdminUserDetailView {
        data = typeof data === 'object' ? data : {};
        const result = new AdminUserDetailView();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['dateLastLogin'] = this.dateLastLogin ? this.dateLastLogin.toISOString() : <any>undefined;
        data['mobileDeviceId'] = this.mobileDeviceId;
        data['notificationId'] = this.notificationId;
        data['adminUserDetail'] = this.adminUserDetail ? this.adminUserDetail.toJSON() : <any>undefined;
        return data;
    }
}

export interface IAdminUserDetailView {
    dateLastLogin?: Date | undefined;
    mobileDeviceId?: string | undefined;
    notificationId?: string | undefined;
    adminUserDetail?: AdminAdminUserDetail | undefined;
}