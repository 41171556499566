
export class ActivityFile implements IActivityFile {
  fileId?: string;
  offsetDateCreated?: Date;
  dateCreated?: Date;
  filename?: string | undefined;
  fromPersonId?: string;

  constructor(data?: IActivityFile) {
    if (data) {
      for (const property in data) {
        if (Object.prototype.hasOwnProperty.call(data, property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.fileId = _data['fileId'];
      this.offsetDateCreated = _data['offsetDateCreated'] ? new Date(_data['offsetDateCreated'].toString()) : <any>undefined;
      this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
      this.filename = _data['filename'];
      this.fromPersonId = _data['fromPersonId'];
    }
  }

  static fromJS(data: any): ActivityFile {
    data = typeof data === 'object' ? data : {};
    const result = new ActivityFile();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['fileId'] = this.fileId;
    data['offsetDateCreated'] = this.offsetDateCreated ? this.offsetDateCreated.toISOString() : <any>undefined;
    data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data['filename'] = this.filename;
    data['fromPersonId'] = this.fromPersonId;
    return data;
  }
}

export interface IActivityFile {
  fileId?: string;
  offsetDateCreated?: Date;
  dateCreated?: Date;
  filename?: string | undefined;
  fromPersonId?: string;
}