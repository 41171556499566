import {ICommonSavePayload} from '../Common.actions';
import {DeviceEdit} from '../../types/requests/DeviceEdit';
import {AxiosResponse} from 'axios';
import authenticatedApi from '../../@template/services/auth/authApi/authenticatedApi';
import {coreApiConfig} from '../../config';
import {Device} from '../../types/models/Device';
import {all, call, put, takeEvery} from '@redux-saga/core/effects';
import {
    DEVICE_SEARCH,
    deviceLoadError,
    deviceLoadSuccess, DEVICES,
    deviceSaveError,
    deviceSaveSuccess, deviceSearchError,
    deviceSearchSuccess
} from './Actions';
import {parseErrorInfo, parseErrorToErrorClass} from '../../@template/helpers/errors';
import {SearchRequest} from '../../types/common/SearchRequest';
import {DeviceSearchRequest} from '../../types/requests/DeviceSearchRequest';
import {PagedEntityListOfDeviceSearchItem} from '../../types/views/PagedEntityListOfDeviceSearchItem';
import {PayloadAction} from '@reduxjs/toolkit';

export function* SaveDevice({ id, submission }: ICommonSavePayload<DeviceEdit>) {
    console.log('SAGA - deviceSaga - SaveDevice');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: id ? 'PUT' : 'POST',
            url: id ? `${coreApiConfig.Paths.device}/${id}` : coreApiConfig.Paths.device,
            data: submission
        });
        const device: Device = Device.fromJS(res.data);
        yield put(deviceSaveSuccess(device));
    } catch (err) {
        yield put(deviceSaveError(parseErrorToErrorClass(err)));
    }
}


export function* LoadDevice(id: string) {
    console.log('SAGA - deviceSaga - LoadDevice');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'GET',
            url: `${coreApiConfig.Paths.device}/${id}`,
        });
        const device: Device = Device.fromJS(res.data);
        yield put(deviceLoadSuccess(device));
    } catch (err) {
        yield put(deviceLoadError(parseErrorInfo(err)));
    }
}

export function* SearchDevices({ page, pageSize, searchParams, sortBy, sortDirection }: SearchRequest<DeviceSearchRequest>) {
    console.log('SAGA - deviceSaga - SearchDevices');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'PUT',
            url: `${coreApiConfig.Paths.device}/search?page=${page}&page_size=${pageSize}&sort=${sortBy}&order=${sortDirection}`,
            data: searchParams
        });
        const results: PagedEntityListOfDeviceSearchItem = PagedEntityListOfDeviceSearchItem.fromJS(res.data);
        yield put(deviceSearchSuccess(results));
    } catch (err) {
        yield put(deviceSearchError(parseErrorInfo(err)));
    }
}

export function* watchSaveDevice(){
    yield takeEvery(DEVICES.SAVE, ({ payload } : PayloadAction<ICommonSavePayload<DeviceEdit>>) => SaveDevice(payload));
}

export function* watchLoadDevice(){
    yield takeEvery(DEVICES.LOAD, ({ payload } : PayloadAction<string>) => LoadDevice(payload));
}

export function* watchRefreshDevice(){
    yield takeEvery(DEVICES.REFRESH, ({ payload } : PayloadAction<string>) => LoadDevice(payload));
}

export function* watchSearchDevices(){
    yield takeEvery(DEVICE_SEARCH.LOAD, ({ payload } : PayloadAction<SearchRequest<DeviceSearchRequest>>) => SearchDevices(payload));
}

export default function* deviceSaga() {
    yield all ([
        call(watchSaveDevice),
        call(watchRefreshDevice),
        call(watchLoadDevice),
        call(watchSearchDevices),
    ])
}