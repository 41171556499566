export class ActivityRecipient implements IActivityRecipient {
  personId?: string;
  accountId?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  fullName?: string | undefined;
  companyName?: string | undefined;
  isInternal?: boolean;

  constructor(data?: IActivityRecipient) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.personId = _data['personId'];
      this.accountId = _data['accountId'];
      this.firstName = _data['firstName'];
      this.lastName = _data['lastName'];
      this.fullName = _data['fullName'];
      this.companyName = _data['companyName'];
      this.isInternal = _data['isInternal'];
    }
  }

  static fromJS(data: any): ActivityRecipient {
    data = typeof data === 'object' ? data : {};
    const result = new ActivityRecipient();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['personId'] = this.personId;
    data['accountId'] = this.accountId;
    data['firstName'] = this.firstName;
    data['lastName'] = this.lastName;
    data['fullName'] = this.fullName;
    data['companyName'] = this.companyName;
    data['isInternal'] = this.isInternal;
    return data;
  }
}

export interface IActivityRecipient {
  personId?: string;
  accountId?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  fullName?: string | undefined;
  companyName?: string | undefined;
  isInternal?: boolean;
}