import {Address} from './Address';

export class AccountSetting implements IAccountSetting {
    accountId?: string;
    dateUpdated?: Date;
    updatedByPersonId?: string | undefined;
    billingAddress?: Address | undefined;
    locationAddress?: Address | undefined;
    billingEmail?: string | undefined;
    billingContactPersonId?: string | undefined;
    userReferenceName?: string | undefined;
    customTextField1Name?: string | undefined;
    customTextField2Name?: string | undefined;
    customTextField3Name?: string | undefined;
    customSelectField1Name?: string | undefined;
    customSelectField2Name?: string | undefined;
    customSelectField3Name?: string | undefined;
    customSelectField1Options?: string[] | undefined;
    customSelectField2Options?: string[] | undefined;
    customSelectField3Options?: string[] | undefined;

    constructor(data?: IAccountSetting) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.accountId = _data['accountId'];
            this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
            this.updatedByPersonId = _data['updatedByPersonId'];
            this.billingAddress = _data['billingAddress'] ? Address.fromJS(_data['billingAddress']) : <any>undefined;
            this.locationAddress = _data['locationAddress'] ? Address.fromJS(_data['locationAddress']) : <any>undefined;
            this.billingEmail = _data['billingEmail'];
            this.billingContactPersonId = _data['billingContactPersonId'];
            this.userReferenceName = _data['userReferenceName'];
            this.customTextField1Name = _data['customTextField1Name'];
            this.customTextField2Name = _data['customTextField2Name'];
            this.customTextField3Name = _data['customTextField3Name'];
            this.customSelectField1Name = _data['customSelectField1Name'];
            this.customSelectField2Name = _data['customSelectField2Name'];
            this.customSelectField3Name = _data['customSelectField3Name'];
            if (Array.isArray(_data['customSelectField1Options'])) {
                this.customSelectField1Options = [] as any;
                for (const item of _data['customSelectField1Options'])
                    this.customSelectField1Options!.push(item);
            }
            if (Array.isArray(_data['customSelectField2Options'])) {
                this.customSelectField2Options = [] as any;
                for (const item of _data['customSelectField2Options'])
                    this.customSelectField2Options!.push(item);
            }
            if (Array.isArray(_data['customSelectField3Options'])) {
                this.customSelectField3Options = [] as any;
                for (const item of _data['customSelectField3Options'])
                    this.customSelectField3Options!.push(item);
            }
        }
    }

    static fromJS(data: any): AccountSetting {
        data = typeof data === 'object' ? data : {};
        const result = new AccountSetting();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['accountId'] = this.accountId;
        data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
        data['updatedByPersonId'] = this.updatedByPersonId;
        data['billingAddress'] = this.billingAddress ? this.billingAddress.toJSON() : <any>undefined;
        data['locationAddress'] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
        data['billingEmail'] = this.billingEmail;
        data['billingContactPersonId'] = this.billingContactPersonId;
        data['userReferenceName'] = this.userReferenceName;
        data['customTextField1Name'] = this.customTextField1Name;
        data['customTextField2Name'] = this.customTextField2Name;
        data['customTextField3Name'] = this.customTextField3Name;
        data['customSelectField1Name'] = this.customSelectField1Name;
        data['customSelectField2Name'] = this.customSelectField2Name;
        data['customSelectField3Name'] = this.customSelectField3Name;
        if (Array.isArray(this.customSelectField1Options)) {
            data['customSelectField1Options'] = [];
            for (const item of this.customSelectField1Options)
                data['customSelectField1Options'].push(item);
        }
        if (Array.isArray(this.customSelectField2Options)) {
            data['customSelectField2Options'] = [];
            for (const item of this.customSelectField2Options)
                data['customSelectField2Options'].push(item);
        }
        if (Array.isArray(this.customSelectField3Options)) {
            data['customSelectField3Options'] = [];
            for (const item of this.customSelectField3Options)
                data['customSelectField3Options'].push(item);
        }
        return data;
    }
}

export interface IAccountSetting {
    accountId?: string;
    dateUpdated?: Date;
    updatedByPersonId?: string | undefined;
    billingAddress?: Address | undefined;
    locationAddress?: Address | undefined;
    billingEmail?: string | undefined;
    billingContactPersonId?: string | undefined;
    userReferenceName?: string | undefined;
    customTextField1Name?: string | undefined;
    customTextField2Name?: string | undefined;
    customTextField3Name?: string | undefined;
    customSelectField1Name?: string | undefined;
    customSelectField2Name?: string | undefined;
    customSelectField3Name?: string | undefined;
    customSelectField1Options?: string[] | undefined;
    customSelectField2Options?: string[] | undefined;
    customSelectField3Options?: string[] | undefined;
}