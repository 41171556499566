export interface IAdminPaths {
    AdminUserEdit: string,
    AdminUserCreate: string,
    AdminUserDetails: string,
    AdminUserSearch: string,
}

export const adminUserPaths: IAdminPaths = {
    AdminUserEdit: '/admin/edit/:idParam',
    AdminUserCreate: '/admin/create',
    AdminUserDetails: '/admin/view/:idParam',
    AdminUserSearch: '/admin/search',
};

