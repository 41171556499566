import {ICommonLoadActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {IActivityGroup} from '../../types/models/AcitivityGroup';
import {ActivityThread, IActivityThread} from '../../types/models/ActivityThread';
import {IActivityGroupSearchRequest} from '../../types/requests/ActivityGroupSearchRequest';
import {InboxThreadSummary} from '../../types/views/InboxThreadSummary';
import {IActivityEventSearchRequest} from '../../types/requests/ActivityEventSearchRequest';
import {IActivityEventItem} from '../../types/models/ActivityEventItem';
import {IMessageSubmitRequest} from '../../types/common/MessageSubmitRequest';

export interface IGroupActionTypes extends ICommonLoadActionType {
  REFRESH: string;
  THREADS_LOAD: string;
  THREADS_LOADED: string;
  THREADS_ERROR: string;
}

export interface IFeedActionTypes extends ICommonLoadActionType {
  REFRESH: string;
  HISTORY_LOAD: string;
  HISTORY_LOADED: string;
}

export interface IThreadActionTypes extends ICommonLoadActionType {
  READ: string;
}

export interface IMessageActionTypes {
  SAVE: string;
  ERROR: string;
  SAVED: string;
}

export interface IGroupNotificationActionTypes extends ICommonLoadActionType {
  REFRESH: string;
}

export const GROUPS: IGroupActionTypes = {
  LOAD: 'messaging/groups/load',
  ERROR: 'messaging/groups/loadError',
  LOADED: 'messaging/groups/loadSuccess',
  REFRESH: 'messaging/groups/refresh',
  THREADS_LOAD: 'messaging/groupsThreads/load',
  THREADS_ERROR: 'messaging/groupsThreads/error',
  THREADS_LOADED: 'messaging/groupsThreads/loadSuccess',
};

export const NOTIFICATIONS: ICommonLoadActionType = {
  LOAD: 'messaging/notifications/load',
  ERROR: 'messaging/notifications/loadError',
  LOADED: 'messaging/notifications/loadSuccess',
  REFRESH: 'messaging/notifications/refresh',
};

export const THREAD: IThreadActionTypes = {
  ERROR: 'messaging/thread/error',
  LOAD: 'messaging/thread/load',
  REFRESH: 'messaging/thread/refresh',
  LOADED: 'messaging/thread/loadSuccess',
  READ: 'messaging/thread/markRead',
};

export const MESSAGE: IMessageActionTypes = {
  ERROR: 'messaging/message/error',
  SAVE: 'messaging/message/save',
  SAVED: 'messaging/message/saveSuccess',
};

export const MESSAGE_FEED: IFeedActionTypes = {
  LOAD: 'messaging/feed/load',
  REFRESH: 'messaging/feed/refresh',
  LOADED: 'messaging/feed/loadSuccess',
  ERROR: 'messaging/feed/error',
  HISTORY_LOAD: 'messaging/feed/historyLoad',
  HISTORY_LOADED: 'messaging/feed/historyLoadSuccess',
};

export const groupsLoad = createAction<IActivityGroupSearchRequest>(GROUPS.LOAD);
export const groupsLoadError = createAction<string>(GROUPS.ERROR);
export const groupsLoadSuccess = createAction<IActivityGroup[] | undefined>(GROUPS.LOADED);
export const groupsLoadRefresh= createAction<IActivityGroupSearchRequest>(GROUPS.REFRESH);
export const groupsThreadsLoad = createAction<string>(GROUPS.THREADS_LOAD);
export const groupsThreadsLoadError = createAction<string>(GROUPS.THREADS_ERROR);
export const groupsThreadsLoadSuccess = createAction<IActivityThread[] | undefined>(GROUPS.THREADS_LOADED);

export const notificationsLoad = createAction(NOTIFICATIONS.LOAD);
export const notificationsLoadRefresh = createAction(NOTIFICATIONS.REFRESH);
export const notificationsLoadError = createAction<string>(NOTIFICATIONS.ERROR);
export const notificationsLoadSuccess = createAction<InboxThreadSummary>(NOTIFICATIONS.LOADED);

export const threadLoad = createAction<string>(THREAD.LOAD);
export const threadMarkRead = createAction<string>(THREAD.READ);
export const threadLoadError = createAction<string>(THREAD.ERROR);
export const threadLoadSuccess = createAction<ActivityThread>(THREAD.LOADED);

export const messageError = createAction<string>(MESSAGE.ERROR);
export const messageSave = createAction<IMessageSubmitRequest>(MESSAGE.SAVE);
export const messageSaveSuccess = createAction(MESSAGE.SAVED);

export const messageFeedLoad = createAction<IActivityEventSearchRequest>(MESSAGE_FEED.LOAD);
export const messageFeedRefresh = createAction<IActivityEventSearchRequest>(MESSAGE_FEED.REFRESH);
export const messageFeedLoadSuccess = createAction<IActivityEventItem[] | undefined>(MESSAGE_FEED.LOADED);
export const messageFeedHistoryLoad = createAction<IActivityEventSearchRequest>(MESSAGE_FEED.HISTORY_LOAD);
export const messageFeedHistoryLoadSuccess = createAction<IActivityEventItem[] | undefined>(MESSAGE_FEED.HISTORY_LOADED);
export const messageFeedError = createAction<string>(MESSAGE_FEED.ERROR);
