import {AppRouteData, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import React from 'react';
import {devicePaths} from './paths';


const deviceSectionBreadcrumbs = [new BreadcrumbItemProps('Devices', devicePaths.DeviceSearch)];

const EditPage = React.lazy(() => import('./DeviceEdit'));
const DetailPage = React.lazy(() => import('./DeviceProfile'));
const SearchPage = React.lazy(() => import('./DeviceSearch'));

const deviceRouteConfig = [
    { path: devicePaths.DeviceSearch, element: <SearchPage />, handle: AppRouteData.create('Search', undefined, deviceSectionBreadcrumbs) } ,
    { path: devicePaths.DeviceProfile, element: <DetailPage />, handle: AppRouteData.create('Details', undefined, deviceSectionBreadcrumbs) } ,
    { path: devicePaths.DeviceCreate, element: <EditPage />, handle: AppRouteData.create('Create', undefined, deviceSectionBreadcrumbs) } ,
    { path: devicePaths.DeviceEdit, element: <EditPage />, handle: AppRouteData.create('Edit', undefined, deviceSectionBreadcrumbs) } ,
];

export default deviceRouteConfig;