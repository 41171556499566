import {ICommonSavePayload} from '../Common.actions';
import {AxiosResponse} from 'axios';
import authenticatedApi from '../../@template/services/auth/authApi/authenticatedApi';
import {coreApiConfig} from '../../config';
import {all, call, put, takeEvery} from '@redux-saga/core/effects';
import {parseErrorInfo, parseErrorToErrorClass} from '../../@template/helpers/errors';
import {AccountParticipantEdit} from '../../types/requests/AccountParticipantEdit';
import {AccountParticipant} from '../../types/models/AccountParticipant';
import {
    accountParticipantLoadError,
    accountParticipantLoadSuccess,
    accountParticipantSaveError,
    accountParticipantSaveSuccess, ACCOUNTS_PARTICIPANT
} from './Actions';
import {PayloadAction} from '@reduxjs/toolkit';

export function* SaveAccountParticipant({ id, submission }: ICommonSavePayload<AccountParticipantEdit>) {
    console.log('SAGA - accountParticipantSaga - SaveAccountParticipant');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: id ? 'PUT' : 'POST',
            url: id ? `${coreApiConfig.Paths.accountParticipant}/${id}` : coreApiConfig.Paths.accountParticipant,
            data: submission
        });
        const accountParticipant: AccountParticipant = AccountParticipant.fromJS(res.data);
        yield put(accountParticipantSaveSuccess(accountParticipant));
    } catch (err) {
        yield put(accountParticipantSaveError(parseErrorToErrorClass(err)));
    }
}


export function* LoadAccountParticipant(id: string) {
    console.log('SAGA - accountParticipantSaga - LoadAccountParticipant');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'GET',
            url: `${coreApiConfig.Paths.accountParticipant}/${id}`,
        });
        const accountParticipant: AccountParticipant = AccountParticipant.fromJS(res.data);
        yield put(accountParticipantLoadSuccess(accountParticipant));
    } catch (err) {
        yield put(accountParticipantLoadError(parseErrorInfo(err)));
    }
}

export function* watchSaveAccountParticipant(){
    yield takeEvery(ACCOUNTS_PARTICIPANT.SAVE, ({ payload } : PayloadAction<ICommonSavePayload<AccountParticipantEdit>>) => SaveAccountParticipant(payload));
}

export function* watchLoadAccountParticipant(){
    yield takeEvery(ACCOUNTS_PARTICIPANT.LOAD, ({ payload } : PayloadAction<string>) => LoadAccountParticipant(payload));
}

export function* watchRefreshAccountParticipant(){
    yield takeEvery(ACCOUNTS_PARTICIPANT.REFRESH, ({ payload } : PayloadAction<string>) => LoadAccountParticipant(payload));
}


export default function* accountSaga() {
    yield all ([
        call(watchSaveAccountParticipant),
        call(watchRefreshAccountParticipant),
        call(watchLoadAccountParticipant)
    ])
}