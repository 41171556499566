export class ActivityAccount implements IActivityAccount {
  accountId?: string;
  companyName?: string | undefined;

  constructor(data?: IActivityAccount) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.accountId = _data['accountId'];
      this.companyName = _data['companyName'];
    }
  }

  static fromJS(data: any): ActivityAccount {
    data = typeof data === 'object' ? data : {};
    const result = new ActivityAccount();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['accountId'] = this.accountId;
    data['companyName'] = this.companyName;
    return data;
  }
}

export interface IActivityAccount {
  accountId?: string;
  companyName?: string | undefined;
}