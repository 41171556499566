import {all, call, takeEvery, put, select} from '@redux-saga/core/effects';
import {
  DEVICE_CACHE,
  deviceCacheError,
  deviceCacheMerge,
  deviceCacheUpdate,
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {PayloadAction} from '@reduxjs/toolkit';
import {AppState} from '../store';
import {IDevicesCacheState} from './Reducer';
import {coreApiConfig} from '../../config';
import {parseErrorInfo} from '../../@template/helpers/errors';
import {AxiosResponse} from 'axios';
import {DeviceSearchItem} from '../../types/views/DeviceSearchItem';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const deviceCacheState = (state: AppState) => state.deviceCache;

export function* LoadDeviceCacheItems(idList: string[]) {
  console.log('SAGA - deviceCacheSaga - LoadDeviceCacheItems', idList);
  try {
    const res: AxiosResponse = yield authApi.request({
      baseURL: coreApiConfig.Url,
      method: 'PUT',
      url: '/device/list',
      data: idList
    });
    const devices: DeviceSearchItem[] = res.data.map((x: any) => DeviceSearchItem.fromJS(x));
    yield put(deviceCacheMerge(devices));
  } catch (err) {
    console.log('Cache load error', err);
    yield put(deviceCacheError(parseErrorInfo(err)));
  }
}

export function* AddDeviceCacheItems() {
  console.log('SAGA - deviceCacheSaga - AddDeviceCacheItems');
  yield call(delay, 200);
  const currentDeviceCache: IDevicesCacheState = yield select(deviceCacheState);
  const currentPendingList = currentDeviceCache.devicePendingIdList;
  if (currentPendingList && currentPendingList.length > 0) {
    console.log('SAGA - deviceCacheSaga - AddDeviceCacheItems LOad', currentPendingList);
    yield put(deviceCacheUpdate([...currentPendingList]));
  }
}

export function* watchLoadDeviceCacheItems(){
  yield takeEvery(DEVICE_CACHE.UPDATE, ({ payload }: PayloadAction<string[]>) => LoadDeviceCacheItems(payload));
}

export function* watchAddDeviceCacheItems(){
  yield takeEvery(DEVICE_CACHE.ADD, ({ payload }: PayloadAction<string[]>) => AddDeviceCacheItems());
}

export default function* deviceCacheSaga() {
  yield all ([
    call(watchLoadDeviceCacheItems),
    call(watchAddDeviceCacheItems)
  ])
}