import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {BACKGROUND_WORK_PROGRESS} from './Actions';

export interface IBackgroundWorkProgressState {
  backgroundWorkId: string | undefined;
  backgroundWorkError: string | undefined;
  backgroundWorkErrorList: string[];
  backgroundWorkType: 'FILE' | 'PROGRESS' | undefined;
}

const initialState: IBackgroundWorkProgressState = {
  backgroundWorkError: undefined,
  backgroundWorkErrorList: [],
  backgroundWorkId: undefined,
  backgroundWorkType: undefined
};

const backgroundWorkProgressReducer = createReducer(initialState, {
  [BACKGROUND_WORK_PROGRESS.SAVE]: (state, action: PayloadAction<string>) => {
    state.backgroundWorkId = action.payload;
    state.backgroundWorkError = undefined;
    state.backgroundWorkType = 'PROGRESS';
    state.backgroundWorkErrorList = [];
  },
  [BACKGROUND_WORK_PROGRESS.SAVE_FILE]: (state, action: PayloadAction<string>) => {
    state.backgroundWorkId = action.payload;
    state.backgroundWorkError = undefined;
    state.backgroundWorkType = 'FILE';
    state.backgroundWorkErrorList = [];
  },
  [BACKGROUND_WORK_PROGRESS.ERROR]: (state, action: PayloadAction<string>) => {
    state.backgroundWorkErrorList = [];
    state.backgroundWorkError = action.payload;
  },
  [BACKGROUND_WORK_PROGRESS.SUCCESS]: (state, action: PayloadAction<string[]>) => {
    state.backgroundWorkErrorList = action.payload;
    state.backgroundWorkError = undefined;
  },
  [BACKGROUND_WORK_PROGRESS.CLEAR]: (state) => {
    state.backgroundWorkId = undefined;
    state.backgroundWorkError = undefined;
    state.backgroundWorkType = undefined;
    state.backgroundWorkErrorList = [];
  },
});

export default backgroundWorkProgressReducer;
