
// region Data types
const guidValRE = /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/;
const userHandleRE = /[a-z0-9]*$/;
const tagRE = /[a-zA-Z0-9\s_-]*$/;
const tagRemoveRE = /[^a-zA-Z0-9\s_-]$/;
const integerRE = /^\d*$/;
const alphaRE = /[A-Z]$/;
// endregion

// region Urls etc
const httpsUrlRE = /^(https:\/\/)/;
const webUrlRE = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/;
const facebookUrlRE = /(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(.?)?]/;
const instagramUrlRE = /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/;
const twitterUrlRE = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
const linkedInRE = /(https?:\/\/)?(www\.)?linkedin.com\/(company|in)\/[a-zA-Z0-9(.?)?]/;
// endregion

// region String parsing
const linkableIdsWithBreaks = /(\[P:[a-zA-Z0-9-]{36}\])|(\[C:[a-zA-Z0-9-]{36}\])|([\n]+)/;
const snakeKeysRE = /([a-z])([A-Z0-9])/g;

// endregion

export {
  guidValRE,
  userHandleRE,
  tagRE,
  alphaRE,
  tagRemoveRE,
  httpsUrlRE,
  webUrlRE,
  facebookUrlRE,
  instagramUrlRE,
  twitterUrlRE,
  linkedInRE,
  integerRE,
  linkableIdsWithBreaks,
  snakeKeysRE
};