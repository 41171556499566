import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {ACCOUNT_PARTICIPANT_CACHE} from './Actions';
import {AccountParticipantSearchItem} from '../../types/views/AccountParticipantSearchItem';

export interface IAccountParticipantsCacheState {
  accountParticipantCacheUpdating: boolean | false;
  accountParticipantCacheError: string | undefined;
  accountParticipantPendingIdList: string[];
  accountParticipantCache: AccountParticipantSearchItem[];
}

const initialState: IAccountParticipantsCacheState = {
  accountParticipantCacheUpdating: false,
  accountParticipantCacheError: undefined,
  accountParticipantPendingIdList: [],
  accountParticipantCache: [],
}

const accountParticipantCacheReducer = createReducer(initialState, {
  [ACCOUNT_PARTICIPANT_CACHE.ADD]: (state, action: PayloadAction<string[]>) => {
    state.accountParticipantPendingIdList = [...new Set([...state.accountParticipantPendingIdList, ...action.payload])];
    state.accountParticipantCacheError = undefined;
  },
  [ACCOUNT_PARTICIPANT_CACHE.UPDATE]: (state) => {
    state.accountParticipantPendingIdList = [];
    state.accountParticipantCacheUpdating = true;
    state.accountParticipantCacheError = undefined;
  },
  [ACCOUNT_PARTICIPANT_CACHE.MERGE_ITEMS]: (state, action: PayloadAction<AccountParticipantSearchItem[]>) => {
    const currentAccountParticipantsCache = state.accountParticipantCache;
    action.payload.map(item => {
      if (!currentAccountParticipantsCache.find(x => x.id === item.id)) {
        currentAccountParticipantsCache.push(item);
      }
    });
    state.accountParticipantCache = currentAccountParticipantsCache;
    state.accountParticipantCacheUpdating = false;
    state.accountParticipantCacheError = undefined;
  },
  [ACCOUNT_PARTICIPANT_CACHE.REMOVE]: (state, action: PayloadAction<string>) => {
    state.accountParticipantCache = state.accountParticipantCache.filter(x => x.id !== action.payload);
    state.accountParticipantCacheUpdating = false;
    state.accountParticipantCacheError = undefined;
  },
});

export default accountParticipantCacheReducer;