import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import {responsiveFontSizes, StyledEngineProvider} from '@mui/material';
import defaultConfig from '../../utility/ContextProvider/defaultConfig';
import {selectBackgroundWorkId} from '../../../redux/backgroundWork/Selectors';
import {useSelector} from 'react-redux';
import ProgressLoaderDialog from '../../../modules/progressLoader';


interface IAppThemeProvider {
  children: React.ReactNode;
}
const AppThemeProvider: React.FC<IAppThemeProvider> = (props) => {
  const customTheme = createTheme(defaultConfig.theme);
  const responsiveTheme = responsiveFontSizes(customTheme);

  const backgroundWorkId = useSelector(selectBackgroundWorkId);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider
        theme={responsiveTheme}>
        {backgroundWorkId && <ProgressLoaderDialog />}
        {props.children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default React.memo(AppThemeProvider);
