import React, {ImgHTMLAttributes, useEffect, useState} from 'react';
import {fetchImageAsBase64} from '../../helpers/images';

type ISecureImageProps = ImgHTMLAttributes<HTMLElement>

const SecureImage = ({ src, ...props }: ISecureImageProps) => {
  const [ base64, setBase64 ] = useState<string | undefined>(undefined);

  useEffect(() => {
    if(src){
      fetchImageAsBase64(src).then((response: string | undefined) => {
        setBase64(response);
      }).catch(err => {
        console.log('Error fetching image', err);
      });
    }
  }, [src])

  return <img
    src={base64}
    {...props}
  />
}

export default SecureImage;