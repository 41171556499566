import React, {useContext} from 'react';
import {Box, Hidden} from '@mui/material';
import {makeStyles} from '@mui/styles';
import AppContext from '../../../@template/utility/AppContext';
import {ThemeMode} from '../../constants/AppEnums';
import AppContextPropsType from '../../../types/AppContextPropsType';

const AppLogo = () => {
  const {themeMode} = useContext<AppContextPropsType>(AppContext);
  const useStyles = makeStyles(() => ({
    logoRoot: {
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
    },
    logo: {
      height: 36,
      marginRight: 10,
    },
  }));
  const classes = useStyles();
  return (
      <>
        <Hidden smUp={true}>
          <Box className={classes.logoRoot}>
            <img
                className={classes.logo}
                src={
                  themeMode === ThemeMode.DARK
                      ? '/assets/images/logo-icon-large.png'
                      : '/assets/images/logo-icon-large.png'
                }
                alt='Operator'
            />
          </Box>
        </Hidden>
        <Hidden smDown={true}>
          <Box className={classes.logoRoot}>
            <img
                className={classes.logo}
                src={
                  themeMode === ThemeMode.DARK
                      ? '/assets/images/Operator_Logo_WhiteText.png'
                      : '/assets/images/Operator_Logo_BlackText.png'
                }
                alt='Operator'
            />
          </Box>
        </Hidden>
      </>
  );
};

export default AppLogo;
