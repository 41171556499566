import {RouteTabParameter} from '../../types/common/RouteTabParameter';

export interface SessionPaths {
    SessionProfile: string,
    SessionSearch: string,
}

export const sessionPaths: SessionPaths = {
    SessionProfile: '/sessions/view/:idParam',
    SessionSearch: '/sessions/search',
};

export interface ISessionProfileTabs {
    activity: RouteTabParameter;
    participants: RouteTabParameter;
    details: RouteTabParameter;
}

export const sessionProfileTabs: ISessionProfileTabs = {
    activity: new RouteTabParameter(0, 'Activity', 'activity'),
    participants: new RouteTabParameter(1, 'Participants','participants'),
    details: new RouteTabParameter(1, 'Files', 'files'),
}

export const profileTabs = [
    sessionProfileTabs.activity,
    sessionProfileTabs.participants
];

export const getSessionProfileTab = (query: string | undefined | null) => profileTabs.find(x => x.query === query);
