import {ICommonSaveActionType, ICommonSavePayload, ICommonSearchActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {AdminUserEdit} from '../../types/requests/AdminUserEdit';
import {AdminUserView} from '../../types/views/AdminUserView';
import {IError} from '../../@template/helpers/errors';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {AdminUserSearchRequest} from '../../types/requests/AdminUserSearchRequest';
import {PagedEntityListOfAdminUserSearchItem} from '../../types/views/PagedEntityListOfAdminUserSearchItem';

export const ADMIN_USERS: ICommonSaveActionType = {
    SAVE: 'adminUser/save',
    SAVED: 'adminUser/saveSuccess',
    SAVE_ERROR: 'adminUser/saveError',
    LOAD: 'adminUser/load',
    REFRESH: 'adminUser/refresh',
    LOAD_ERROR: 'adminUser/loadError',
    LOADED: 'adminUser/loadSuccess',
}

export const ADMIN_USER_SEARCH: ICommonSearchActionType = {
    CHANGE_VIEW: 'adminUserSearch/changeView',
    ERROR: 'adminUserSearch/error',
    LOAD: 'adminUserSearch/load',
    LOADED: 'adminUserSearch/loaded',
    REFRESH: 'adminUserSearch/refresh'
}

export const adminUserSave = createAction<ICommonSavePayload<AdminUserEdit>>(ADMIN_USERS.SAVE);
export const adminUserSaveSuccess = createAction<AdminUserView>(ADMIN_USERS.SAVED);
export const adminUserSaveError = createAction<IError>(ADMIN_USERS.SAVE_ERROR);
export const adminUserLoad = createAction<string>(ADMIN_USERS.LOAD);
export const adminUserRefresh = createAction<string>(ADMIN_USERS.REFRESH);
export const adminUserLoadSuccess = createAction<AdminUserView>(ADMIN_USERS.LOADED);
export const adminUserLoadError = createAction<string>(ADMIN_USERS.LOAD_ERROR);
export const adminUserSearch = createAction<ISearchRequest<AdminUserSearchRequest>>(ADMIN_USER_SEARCH.LOAD);
export const adminUserSearchSuccess = createAction<PagedEntityListOfAdminUserSearchItem>(ADMIN_USER_SEARCH.LOADED);
export const adminUserSearchError = createAction<string>(ADMIN_USER_SEARCH.ERROR);