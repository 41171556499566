
export const defaultForAddress = () => {
  return Address.fromJS({
    countryCode: 'AU'
  })
}

export class Address implements IAddress {
  line1?: string | undefined;
  line2?: string | undefined;
  suburb?: string | undefined;
  state?: string | undefined;
  postcode?: string | undefined;
  countryCode?: string | undefined;
  rawLocation?: string | undefined;
  locationDescription?: string | undefined;
  formattedLocation?: string | undefined;
  latitude?: number;
  longitude?: number;

  constructor(data?: IAddress) {
    if (data) {
      for (const property in data) {
        if (Object.prototype.hasOwnProperty.call(data, property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.line1 = _data['line1'];
      this.line2 = _data['line2'];
      this.suburb = _data['suburb'];
      this.state = _data['state'];
      this.postcode = _data['postcode'];
      this.countryCode = _data['countryCode'];
      this.rawLocation = _data['rawLocation'];
      this.locationDescription = _data['locationDescription'];
      this.formattedLocation = _data['formattedLocation'];
      this.latitude = _data['latitude'];
      this.longitude = _data['longitude'];
    }
  }

  static fromJS(data: any): Address {
    data = typeof data === 'object' ? data : {};
    const result = new Address();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['line1'] = this.line1;
    data['line2'] = this.line2;
    data['suburb'] = this.suburb;
    data['state'] = this.state;
    data['postcode'] = this.postcode;
    data['countryCode'] = this.countryCode;
    data['rawLocation'] = this.rawLocation;
    data['locationDescription'] = this.locationDescription;
    data['formattedLocation'] = this.formattedLocation;
    data['latitude'] = this.latitude;
    data['longitude'] = this.longitude;
    return data;
  }
}

export interface IAddress {
  line1?: string | undefined;
  line2?: string | undefined;
  suburb?: string | undefined;
  state?: string | undefined;
  postcode?: string | undefined;
  countryCode?: string | undefined;
  rawLocation?: string | undefined;
  locationDescription?: string | undefined;
  formattedLocation?: string | undefined;
  latitude?: number;
  longitude?: number;
}