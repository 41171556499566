import {RouteTabParameter} from '../../types/common/RouteTabParameter';


export interface InstructorPaths {
    InstructorEdit: string,
    InstructorCreate: string,
    InstructorProfile: string,
    InstructorSearch: string,
}

export const instructorPaths: InstructorPaths = {
    InstructorEdit: '/instructors/edit/:idParam/:accountIdParam',
    InstructorCreate: '/instructors/create/:accountIdParam',
    InstructorProfile: '/instructors/view/:idParam/:accountIdParam',
    InstructorSearch: '/instructors/search',
};

export interface IInstructorProfileTabs {
    activity: RouteTabParameter;
    sessions: RouteTabParameter;
    details: RouteTabParameter;
}

export const instructorProfileTabs: IInstructorProfileTabs = {
    activity: new RouteTabParameter(0, 'Activity', 'activity'),
    details: new RouteTabParameter(1, 'Details', 'details'),
    sessions: new RouteTabParameter(2, 'Sessions', 'sessions'),
}

export const profileTabs = [
    instructorProfileTabs.activity,
    instructorProfileTabs.details,
    instructorProfileTabs.sessions
];

export const getInstructorProfileTab = (query: string | undefined | null) => profileTabs.find(x => x.query === query);
