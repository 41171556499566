
export class SessionSearchItem implements ISessionSearchItem {
    id?: string;
    accountId?: string;
    deviceId?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    instructorPersonId?: string | undefined;
    title?: string | undefined;
    instructorNotes?: string | undefined;
    sessionParticipants?: number | undefined;
    dateSessionStart?: Date | undefined;
    dateSessionEnd?: Date | undefined;
    isDisabled?: boolean;

    constructor(data?: ISessionSearchItem) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'];
            this.accountId = _data['accountId'];
            this.deviceId = _data['deviceId'];
            this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
            this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
            this.createdByPersonId = _data['createdByPersonId'];
            this.updatedByPersonId = _data['updatedByPersonId'];
            this.instructorPersonId = _data['instructorPersonId'];
            this.title = _data['title'];
            this.instructorNotes = _data['instructorNotes'];
            this.sessionParticipants = _data['sessionParticipants'];
            this.dateSessionStart = _data['dateSessionStart'] ? new Date(_data['dateSessionStart'].toString()) : <any>undefined;
            this.dateSessionEnd = _data['dateSessionEnd'] ? new Date(_data['dateSessionEnd'].toString()) : <any>undefined;
            this.isDisabled = _data['isDisabled'];
        }
    }

    static fromJS(data: any): SessionSearchItem {
        data = typeof data === 'object' ? data : {};
        const result = new SessionSearchItem();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id;
        data['accountId'] = this.accountId;
        data['deviceId'] = this.deviceId;
        data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
        data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
        data['createdByPersonId'] = this.createdByPersonId;
        data['updatedByPersonId'] = this.updatedByPersonId;
        data['instructorPersonId'] = this.instructorPersonId;
        data['title'] = this.title;
        data['instructorNotes'] = this.instructorNotes;
        data['sessionParticipants'] = this.sessionParticipants;
        data['dateSessionStart'] = this.dateSessionStart ? this.dateSessionStart.toISOString() : <any>undefined;
        data['dateSessionEnd'] = this.dateSessionEnd ? this.dateSessionEnd.toISOString() : <any>undefined;
        data['isDisabled'] = this.isDisabled;
        return data;
    }
}

export interface ISessionSearchItem {
    id?: string;
    accountId?: string;
    deviceId?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    instructorPersonId?: string | undefined;
    title?: string | undefined;
    instructorNotes?: string | undefined;
    sessionParticipants?: number | undefined;
    dateSessionStart?: Date | undefined;
    dateSessionEnd?: Date | undefined;
    isDisabled?: boolean;
}