import {SessionView} from '../../types/views/SessionView';
import {
    defaultForSessionSearchRequest,
    SessionSearchRequest
} from '../../types/requests/SessionSearchRequest';
import {DefaultSortByOptions} from '../../types/common/SortByOptions/DefaultSortByOptions';
import {SortOrderOptions} from '../../types/common/SortOrderOptions';
import {PagedEntityListOfSessionSearchItem} from '../../types/views/PagedEntityListOfSessionSearchItem';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {SESSION_SEARCH, SESSIONS} from '../session/Actions';
import {SearchRequest} from '../../types/common/SearchRequest';


export interface ISessionState {
    session: SessionView | undefined;
    sessionLoading: boolean | false;
    sessionLoadError: string | undefined;
    sessionSearchParams: SessionSearchRequest;
    sessionSearchSortOption: DefaultSortByOptions;
    sessionSearchSortOrder: SortOrderOptions;
    sessionsResult: PagedEntityListOfSessionSearchItem | undefined;
    sessionsSearching: boolean;
    sessionSearchError: string | undefined;
    sessionSessionsResult: PagedEntityListOfSessionSearchItem | undefined;
    sessionSessionsSearching: boolean;
    sessionSessionsError: string | undefined;
}

const initialState: ISessionState = {
    session: undefined,
    sessionLoading: false,
    sessionLoadError: undefined,
    sessionSearchParams: defaultForSessionSearchRequest(),
    sessionSearchSortOption: DefaultSortByOptions.Relevance,
    sessionSearchSortOrder: SortOrderOptions.Desc,
    sessionsResult: undefined,
    sessionsSearching: false,
    sessionSearchError: undefined,
    sessionSessionsResult: undefined,
    sessionSessionsSearching: false,
    sessionSessionsError: undefined,
}

const sessionReducer = createReducer(initialState, {
    [SESSIONS.LOAD]: (state) => {
        state.session = undefined;
        state.sessionLoading = true;
        state.sessionLoadError = undefined;
    },
    [SESSIONS.REFRESH]: (state) => {
        state.sessionLoading = false;
        state.sessionLoadError = undefined;
    },
    [SESSIONS.LOADED]: (state, action: PayloadAction<SessionView>) => {
        state.sessionLoading = false;
        state.sessionLoadError = undefined;
        state.session = action.payload;
    },
    [SESSIONS.ERROR]: (state, action: PayloadAction<string>) => {
        state.sessionLoading = false;
        state.sessionLoadError = action.payload;
        state.session = undefined;
    },
    [SESSION_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<SessionSearchRequest>>) => {
        state.sessionSearchParams = action.payload.searchParams;
        state.sessionSearchSortOption = action.payload.sortBy??(DefaultSortByOptions.Relevance);
        state.sessionSearchSortOrder = action.payload.sortDirection??(SortOrderOptions.Desc);
        state.sessionsSearching = true;
        state.sessionSearchError = undefined;
    },
    [SESSION_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
        state.sessionsSearching = false;
        state.sessionSearchError = action.payload;
    },
    [SESSION_SEARCH.LOADED]: (state, action: PayloadAction<PagedEntityListOfSessionSearchItem>) => {
        state.sessionsSearching = false;
        state.sessionSearchError = undefined;
        state.sessionsResult = action.payload;
    },
});

export default sessionReducer;