import {PhoneNumber} from '../models/PhoneNumber';

export class PersonListItem implements IPersonListItem {
    id?: string;
    firstName?: string | undefined;
    knownAsName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    isUser?: boolean;
    isContact?: boolean;
    isInstructor?: boolean;
    isAdminUser?: boolean;
    isParticipant?: boolean;
    accountId?: string | undefined;
    fullName?: string | undefined;
    phoneNumbers?: PhoneNumber[] | undefined;

    constructor(data?: IPersonListItem) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'];
            this.firstName = _data['firstName'];
            this.knownAsName = _data['knownAsName'];
            this.lastName = _data['lastName'];
            this.email = _data['email'];
            this.isUser = _data['isUser'];
            this.isContact = _data['isContact'];
            this.isInstructor = _data['isInstructor'];
            this.isAdminUser = _data['isAdminUser'];
            this.isParticipant = _data['isParticipant'];
            this.accountId = _data['accountId'];
            this.fullName = _data['fullName'];
            if (Array.isArray(_data['phoneNumbers'])) {
                this.phoneNumbers = [] as any;
                for (const item of _data['phoneNumbers'])
                    this.phoneNumbers!.push(PhoneNumber.fromJS(item));
            }
        }
    }

    static fromJS(data: any): PersonListItem {
        data = typeof data === 'object' ? data : {};
        const result = new PersonListItem();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id;
        data['firstName'] = this.firstName;
        data['knownAsName'] = this.knownAsName;
        data['lastName'] = this.lastName;
        data['email'] = this.email;
        data['isUser'] = this.isUser;
        data['isContact'] = this.isContact;
        data['isInstructor'] = this.isInstructor;
        data['isAdminUser'] = this.isAdminUser;
        data['isParticipant'] = this.isParticipant;
        data['accountId'] = this.accountId;
        data['fullName'] = this.fullName;
        if (Array.isArray(this.phoneNumbers)) {
            data['phoneNumbers'] = [];
            for (const item of this.phoneNumbers)
                data['phoneNumbers'].push(item.toJSON());
        }
        return data;
    }
}

export interface IPersonListItem {
    id?: string;
    firstName?: string | undefined;
    knownAsName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    isUser?: boolean;
    isContact?: boolean;
    isInstructor?: boolean;
    isAdminUser?: boolean;
    isParticipant?: boolean;
    accountId?: string | undefined;
    fullName?: string | undefined;
    phoneNumbers?: PhoneNumber[] | undefined;
}
