import {GridLayout} from '../../../types/layout/Grid';

// Page layouts
export const formPageTitle = new GridLayout({ xs: 12, sm: 12, md: 4, lg: 3, xl: 2 });
export const formPageContent = new GridLayout({ xs: 12, sm: 12, md: 8, lg: 7, xl: 6 });

// Content layouts
export const fullCol = new GridLayout({xs: 12, sm: 12, md: 12, lg: 12, xl: 12 });
export const oneTwelfthCol = new GridLayout({xs: 12, sm: 6, md: 1, lg: 1, xl: 1 });
export const oneSixthCol = new GridLayout({xs: 12, sm: 6, md: 2, lg: 2, xl: 2 });
export const quarterCol = new GridLayout({xs: 12, sm: 6, md: 3, lg: 3, xl: 3 });
export const threeQuarterCol = new GridLayout({xs: 12, sm: 6, md: 9, lg: 9, xl: 9 });
export const halfCol = new GridLayout({ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 });
export const thirdCol = new GridLayout({ xs: 5, sm: 4 });
export const twoThirdCol = new GridLayout({ xs: 7, sm: 8 });

// Search result specific
export const statusCol = new GridLayout({xs: 12, sm: 4, md: 3, lg: 2, xl: 2 });
export const contentCol = new GridLayout({xs: 12, sm: 8, md: 9, lg: 10, xl: 10 });

// Profile specific
export const profileHeaderDetailCol = new GridLayout({ xs: 12, sm: 12, md: 3, lg: 5, xl: 6 });
export const profileSecondaryCol = new GridLayout({ xs: 12, sm: 12, md: 6, lg: 5, xl: 4 });
export const profileOptionsCol = new GridLayout({ xs: 12, sm: 12, md: 3, lg: 2, xl: 2 });

// Layout with button or small menu
export const optionsContentCol = new GridLayout({xs: 11, sm: 11, md: 11, lg: 11, xl: 11 });
export const optionsMenuCol = new GridLayout({xs: 1, sm: 1, md: 1, lg: 1, xl: 1 });

export const stageGateLayoutCol = new GridLayout({ xs: 3, sm: 3, md : 3 });
