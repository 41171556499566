import {ActivityGroup} from '../../types/models/AcitivityGroup';
import {ActivityGroupSearchRequest} from '../../types/requests/ActivityGroupSearchRequest';
import {coreApiConfig, activityApiConfig} from '../../config';
import authenticatedApi from '../../@template/services/auth/authApi/authenticatedApi';
import {all, call, put, takeLatest} from '@redux-saga/core/effects';
import {
  GROUPS,
  groupsLoadError,
  groupsLoadSuccess,
  groupsThreadsLoadError,
  groupsThreadsLoadSuccess, MESSAGE,
  MESSAGE_FEED,
  messageError,
  messageFeedError,
  messageFeedLoadSuccess,
  messageSaveSuccess,
  NOTIFICATIONS,
  notificationsLoadError,
  notificationsLoadSuccess,
  THREAD,
  threadLoadError,
  threadLoadSuccess,
} from './Actions';
import {EventContext} from '../../types/common/EventContext';
import {ActivityThread} from '../../types/models/ActivityThread';
import {InboxThreadSummary} from '../../types/views/InboxThreadSummary';
import {ActivityEventSearchRequest} from '../../types/requests/ActivityEventSearchRequest';
import {ActivityEventItem} from '../../types/models/ActivityEventItem';
import {PayloadAction} from '@reduxjs/toolkit';
import {IMessageSubmitRequest} from '../../types/common/MessageSubmitRequest';
import {parseErrorInfo} from '../../@template/helpers/errors';
import {AxiosResponse} from 'axios';


function* InboxGroupsList(searchParams: ActivityGroupSearchRequest) {
  console.log('SAGA - messageSaga - InboxGroupsList');
  try {
    const res: AxiosResponse = yield authenticatedApi.request({
      baseURL: activityApiConfig.Url,
      method: 'PUT',
      url: `${activityApiConfig.Paths.inbox}/groups`,
      data: searchParams
    });
    const groups = res.data.map((x: any) => ActivityGroup.fromJS(x));
    yield put(groupsLoadSuccess(groups));
  } catch (error) {
    yield put(groupsLoadError(parseErrorInfo(error)));
  }
}

function* InboxGroupThreadsList(groupId: string) {
  console.log('SAGA - messageSaga - InboxGroupThreadsList');
  try {
    const res: AxiosResponse = yield authenticatedApi.request({
      baseURL: activityApiConfig.Url,
      method: 'GET',
      url: `${activityApiConfig.Paths.inbox}/groups/${groupId}/threads`,
    });
    const threads = res.data.map((x: any) => ActivityThread.fromJS(x));
    yield put(groupsThreadsLoadSuccess(threads));
  } catch (error) {
    yield put(groupsThreadsLoadError(parseErrorInfo(error)));
  }
}

function* InboxSummaryLoad() {
  console.log('SAGA - messageSaga - InboxSummaryLoad');
  try {
    const res: AxiosResponse = yield authenticatedApi.request({
      baseURL: activityApiConfig.Url,
      method: 'GET',
      url: `${activityApiConfig.Paths.inbox}/summary`,
    });
    const summary = InboxThreadSummary.fromJS(res.data);
    yield put(notificationsLoadSuccess(summary));
  } catch (error) {
    yield put(notificationsLoadError(parseErrorInfo(error)));
  }
}

function* ThreadSearch(searchParams: ActivityEventSearchRequest) {
  console.log('SAGA - messageSaga - ThreadSearch');
  try {
    const res: AxiosResponse = yield authenticatedApi.request({
      baseURL: activityApiConfig.Url,
      method: 'PUT',
      url: `${activityApiConfig.Paths.events}/items/search`,
      data: searchParams
    });
    const messageList = res.data.map((x: any) => ActivityEventItem.fromJS(x));
    yield put(messageFeedLoadSuccess(messageList));
  } catch (error) {
    yield put(messageFeedError(parseErrorInfo(error)));
  }
}

function* ThreadMarkRead(threadId: string) {
  console.log('SAGA - messageSaga - ThreadMarkRead');
  try {
    yield authenticatedApi.request({
      baseURL: activityApiConfig.Url,
      method: 'GET',
      url: `${activityApiConfig.Paths.inbox}/threads/${threadId}/read`,
    });
  } catch (error) {
    console.log(error);
  }
}

function* ThreadLoad(threadId: string) {
  console.log('SAGA - messageSaga - ThreadLoad');
  try {
    const res: AxiosResponse = yield authenticatedApi.request({
      baseURL: activityApiConfig.Url,
      method: 'GET',
      url: `${activityApiConfig.Paths.events}/thread/${threadId}`,
    });
    const thread = ActivityThread.fromJS(res.data);
    yield put(threadLoadSuccess(thread));
  } catch (error) {
    yield put(threadLoadError(parseErrorInfo(error)));
  }
}

function* ThreadHistoryLoad(searchParams: ActivityEventSearchRequest) {
  console.log('SAGA - messageSaga - ThreadHistoryLoad');
  try {
    const res: AxiosResponse = yield authenticatedApi.request({
      baseURL: activityApiConfig.Url,
      method: 'PUT',
      url: `${activityApiConfig.Paths.events}/items/search`,
      data: searchParams
    });
    const messageList = res.data.map((x: any) => ActivityEventItem.fromJS(x));
    yield put(messageFeedLoadSuccess(messageList));
  } catch (error) {
    yield put(messageFeedError(parseErrorInfo(error)));
  }
}

function* MessageSave({ eventContext, messageRequest, threadId }: IMessageSubmitRequest) {
  console.log('SAGA - messageSaga - MessageSave');
  let path = '';
  let contextId = '';
  switch (eventContext) {
    case EventContext.InternalAccount:
      path = coreApiConfig.Paths.account;
      contextId = threadId.replace('a_', '');
      break;
    case EventContext.InternalDevice:
      path = coreApiConfig.Paths.device;
      contextId = threadId.replace('d_', '');
      break;
    case EventContext.InternalInstructor:
      path = coreApiConfig.Paths.instructorUser;
      contextId = threadId.replace('i_', '');
      break;
    case EventContext.InternalParticipant:
      path = coreApiConfig.Paths.participant;
      contextId = threadId.replace('p_', '');
      break;
    default:
      yield put(messageError('Context not understood'));
      return;
  }
  try {
    yield authenticatedApi.request({
      baseURL: coreApiConfig.Url,
      method: 'POST',
      url: `${path}/${contextId}/message`,
      data: messageRequest
    });
    yield put(messageSaveSuccess());
  } catch (error) {
    yield put(messageFeedError(parseErrorInfo(error)));
  }
}

export function* watchInboxGroupsList() {
  yield takeLatest(GROUPS.LOAD, ({payload}: PayloadAction<ActivityGroupSearchRequest>) => InboxGroupsList(payload));
}

export function* watchInboxGroupsRefreshList() {
  yield takeLatest(GROUPS.REFRESH, ({payload}: PayloadAction<ActivityGroupSearchRequest>) => InboxGroupsList(payload));
}

export function* watchInboxGroupThreadsList() {
  yield takeLatest(GROUPS.THREADS_LOAD, ({payload}: PayloadAction<string>) => InboxGroupThreadsList(payload));
}

export function* watchInboxSummaryLoad() {
  yield takeLatest(NOTIFICATIONS.LOAD, () => InboxSummaryLoad());
}

export function* watchThreadSearch() {
  yield takeLatest(MESSAGE_FEED.LOAD, ({payload}: PayloadAction<ActivityEventSearchRequest>) => ThreadSearch(payload));
}

export function* watchThreadRefreshSearch() {
  yield takeLatest(MESSAGE_FEED.REFRESH, ({payload}: PayloadAction<ActivityEventSearchRequest>) => ThreadSearch(payload));
}

export function* watchThreadHistoryLoad() {
  yield takeLatest(MESSAGE_FEED.HISTORY_LOAD, ({payload}: PayloadAction<ActivityEventSearchRequest>) => ThreadHistoryLoad(payload));
}

export function* watchThreadMarkRead() {
  yield takeLatest(THREAD.READ, ({payload}: PayloadAction<string>) => ThreadMarkRead(payload));
}

export function* watchThreadLoad() {
  yield takeLatest(THREAD.LOAD, ({payload}: PayloadAction<string>) => ThreadLoad(payload));
}

export function* watchThreadRefresh() {
  yield takeLatest(THREAD.REFRESH, ({payload}: PayloadAction<string>) => ThreadLoad(payload));
}

export function* watchMessageSave() {
  yield takeLatest(MESSAGE.SAVE, ({payload}: PayloadAction<IMessageSubmitRequest>) => MessageSave(payload));
}

export default function* messageSaga() {
  yield all ([
    call(watchInboxGroupsList),
    call(watchInboxGroupsRefreshList),
    call(watchInboxGroupThreadsList),
    call(watchInboxSummaryLoad),
    call(watchThreadSearch),
    call(watchThreadRefreshSearch),
    call(watchThreadMarkRead),
    call(watchThreadLoad),
    call(watchThreadRefresh),
    call(watchThreadHistoryLoad),
    call(watchMessageSave),
  ])
}