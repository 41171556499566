import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    appMain: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      backgroundColor: theme.palette.background.default,
      paddingTop: 56,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 70,
      },
      '&.appMainFixedFooter': {
        '& .scrollAppSidebar': {
          height: 'calc(100vh - 260px) !important',
          [theme.breakpoints.up('xl')]: {
            height: 'calc(100vh - 300px) !important',
          },
        },
        '& $mainContainer': {
          paddingBottom: 0,
        },
      },
    },
    mainContent: {
      flex: 1,
      display: 'flex',
      transition: 'all 0.5s ease',
      border: '0 none',
      width: '100vw',
      [theme.breakpoints.up('md')]: {
        marginLeft: '19rem',
        width: 'calc(100vw - 19rem)',
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: '21rem',
        width: 'calc(100vw - 21rem)',
      },
    },
    mainContainer: {
      paddingBottom: 0,
      margin: 0,
      width: '100%',
      '& > .scrollbar-container': {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
      },
      [theme.breakpoints.up('md')]: {
        paddingBottom: 0,
      }
    },

    boxedLayout: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1260,
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: '0px 0px 4px 2px rgba(0,0,0,0.12)',
        '& .app-sidebar': {
          position: 'absolute',
        },
        '& .fixed-footer': {
          position: 'sticky',
          width: '100%',
        },
        '& $mainContent': {
          width: 'calc(100% - 19rem)',
          flex: 'auto',
        },
        '& $mainContainer': {
          width: '100%',
        },
        '& .app-bar': {
          position: 'absolute',
          boxShadow: '0px 0px 4px 2px rgba(0,0,0,0.12)',
          width: 'calc(100% - 19rem)',
        },
        '& .mini-sidebar-collapsed': {
          '& + .main-content .app-bar': {
            width: 'calc(100% - 4rem)',
          },
        },
        '& .grid-btn': {
          fontSize: 11,
        },
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: 1680,
        '& $mainContent': {
          width: 'calc(100% - 21.6rem)',
        },
        '& .app-bar': {
          width: 'calc(100% - 21rem)',
        },
        '& .mini-sidebar-collapsed': {
          '& + .main-content .app-bar': {
            width: 'calc(100% - 5rem)',
          },
        },
      },
    },
  };
});
export default useStyles;
