import {IBackgroundWorkProgressState} from './Reducer';
import {createSelector} from '@reduxjs/toolkit';


export const selectBackgroundWorkProgressState = (state: { backgroundWorkProgress: IBackgroundWorkProgressState }) => state.backgroundWorkProgress;

export const selectBackgroundWorkErrorList = createSelector(
  selectBackgroundWorkProgressState,
  (subState) => subState.backgroundWorkErrorList
);

export const selectBackgroundWorkId = createSelector(
  selectBackgroundWorkProgressState,
  (subState) => subState.backgroundWorkId
);

export const selectBackgroundWorkError = createSelector(
  selectBackgroundWorkProgressState,
  (subState) => subState.backgroundWorkError
);

export const selectBackgroundWorkType = createSelector(
  selectBackgroundWorkProgressState,
  (subState) => subState.backgroundWorkType
);

