import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {PERSON_CACHE} from './Actions';
import {PersonListItem} from '../../types/views/PersonListItem';

export interface IPeopleCacheState {
  personCacheUpdating: boolean | false;
  personCacheError: string | undefined;
  personPendingIdList: string[];
  personCache: PersonListItem[];
}

const initialState: IPeopleCacheState = {
  personCacheUpdating: false,
  personCacheError: undefined,
  personPendingIdList: [],
  personCache: [],
}

const peopleCacheReducer = createReducer(initialState, {
  [PERSON_CACHE.ADD]: (state, action: PayloadAction<string[]>) => {
    state.personPendingIdList = [...new Set([...state.personPendingIdList, ...action.payload])];
    state.personCacheError = undefined;
  },
  [PERSON_CACHE.UPDATE]: (state) => {
    state.personPendingIdList = [];
    state.personCacheUpdating = true;
    state.personCacheError = undefined;
  },
  [PERSON_CACHE.MERGE_ITEMS]: (state, action: PayloadAction<PersonListItem[]>) => {
    const currentPeopleCache = state.personCache;
    action.payload.map(item => {
      if (!currentPeopleCache.find(x => x.id === item.id)) {
        currentPeopleCache.push(item);
      }
    });
    state.personCache = currentPeopleCache;
    state.personCacheUpdating = false;
    state.personCacheError = undefined;
  },
  [PERSON_CACHE.REMOVE]: (state, action: PayloadAction<string>) => {
    state.personCache = state.personCache.filter(x => x.id !== action.payload);
    state.personCacheUpdating = false;
    state.personCacheError = undefined;
  },
});

export default peopleCacheReducer;