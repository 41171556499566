import {AccountSetting} from './AccountSetting';

export class Account implements IAccount {
    id?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    companyName?: string | undefined;
    abn?: string | undefined;
    isDisabled?: boolean;
    dateDisabled?: Date | undefined;
    disabledByPersonId?: string | undefined;
    isMultipleInstructors?: boolean;
    accountSetting?: AccountSetting | undefined;

    constructor(data?: IAccount) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'];
            this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
            this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
            this.createdByPersonId = _data['createdByPersonId'];
            this.updatedByPersonId = _data['updatedByPersonId'];
            this.companyName = _data['companyName'];
            this.abn = _data['abn'];
            this.isDisabled = _data['isDisabled'];
            this.dateDisabled = _data['dateDisabled'] ? new Date(_data['dateDisabled'].toString()) : <any>undefined;
            this.disabledByPersonId = _data['disabledByPersonId'];
            this.isMultipleInstructors = _data['isMultipleInstructors'];
            this.accountSetting = _data['accountSetting'] ? AccountSetting.fromJS(_data['accountSetting']) : <any>undefined;
        }
    }

    static fromJS(data: any): Account {
        data = typeof data === 'object' ? data : {};
        const result = new Account();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id;
        data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
        data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
        data['createdByPersonId'] = this.createdByPersonId;
        data['updatedByPersonId'] = this.updatedByPersonId;
        data['companyName'] = this.companyName;
        data['abn'] = this.abn;
        data['isDisabled'] = this.isDisabled;
        data['dateDisabled'] = this.dateDisabled ? this.dateDisabled.toISOString() : <any>undefined;
        data['disabledByPersonId'] = this.disabledByPersonId;
        data['isMultipleInstructors'] = this.isMultipleInstructors;
        data['accountSetting'] = this.accountSetting ? this.accountSetting.toJSON() : <any>undefined;
        return data;
    }
}

export interface IAccount {
    id?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    companyName?: string | undefined;
    abn?: string | undefined;
    isDisabled?: boolean;
    dateDisabled?: Date | undefined;
    disabledByPersonId?: string | undefined;
    isMultipleInstructors?: boolean;
    accountSetting?: AccountSetting | undefined;
}