

export interface InboxPaths {
  InboxView: string,
  InboxThreadView: string,
}

export const inboxPaths: InboxPaths = {
  InboxView: '/inbox/view',
  InboxThreadView: '/inbox/thread/:idParam',
};