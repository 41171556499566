import {createAction} from '@reduxjs/toolkit';
import {ICommonSaveActionType, ICommonSavePayload} from '../Common.actions';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {IFileMetadataEdit} from '../../types/requests/FileMetadataEdit';
import {IFileMetadataSearchRequest} from '../../types/requests/FileMetadataSearchRequest';
import {PagedEntityListOfFileMetadataSearchItem} from '../../types/views/PagedEntityListOfFileMetadataSearchItem';
import {FileMetadata} from '../../types/models/files/FileMetadata';

export interface IFileActionTypes extends ICommonSaveActionType {
  SEARCH: string;
  SEARCH_REFRESH: string;
  SEARCH_SUCCESS: string;
  SEARCH_ERROR: string;
}

export const FILE: IFileActionTypes = {
  REFRESH: 'files/refresh',
  LOAD: 'files/load',
  LOADED: 'files/loaded',
  LOAD_ERROR: 'files/loadError',
  SAVE: 'files/save',
  SAVED: 'files/saved',
  SAVE_ERROR: 'files/saveError',
  SEARCH: 'files/search',
  SEARCH_REFRESH: 'files/searchRefresh',
  SEARCH_SUCCESS: 'files/searchSuccess',
  SEARCH_ERROR: 'files/searchError',
}


export const fileLoad = createAction<string>(FILE.LOAD);
export const fileLoadSuccess = createAction<FileMetadata>(FILE.LOADED);
export const fileLoadError = createAction<string>(FILE.LOAD_ERROR);
export const fileSave = createAction<ICommonSavePayload<IFileMetadataEdit>>(FILE.SAVE);
export const fileSaveSuccess = createAction<FileMetadata>(FILE.SAVED);
export const fileSaveError = createAction<string>(FILE.SAVE_ERROR);
export const filesSearch = createAction<ISearchRequest<IFileMetadataSearchRequest>>(FILE.SEARCH);
export const filesSearchRefresh = createAction<ISearchRequest<IFileMetadataSearchRequest>>(FILE.SEARCH_REFRESH);
export const filesSearchSuccess = createAction<PagedEntityListOfFileMetadataSearchItem>(FILE.SEARCH_SUCCESS);
export const filesSearchError = createAction<string>(FILE.SEARCH_ERROR);

