import {createAction} from '@reduxjs/toolkit';
import {ICommonCacheActionType} from '../Common.actions';
import {PersonListItem} from '../../types/views/PersonListItem';


export const PERSON_CACHE: ICommonCacheActionType = {
  ADD: 'personCache/add',
  UPDATE: 'personCache/updated',
  MERGE_ITEMS: 'personCache/merge',
  ERROR: 'personCache/error',
  REMOVE: 'personCache/remove'
};

export const personCacheAdd = createAction<string[]>(PERSON_CACHE.ADD);
export const personCacheUpdate = createAction<string[]>(PERSON_CACHE.UPDATE);
export const personCacheMerge = createAction<PersonListItem[]>(PERSON_CACHE.MERGE_ITEMS);
export const personCacheError = createAction<string>(PERSON_CACHE.ERROR);
export const personCacheRemove = createAction<string>(PERSON_CACHE.REMOVE);
