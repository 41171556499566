import {ActivityRecipient} from './ActivityRecipient';
import { ActivityAccount } from './ActivityAccount';

export class ActivityThread implements IActivityThread {
  personId?: string;
  ownerId?: string | undefined;
  id?: string | undefined;
  groupId?: string;
  eventContext?: string | undefined;
  accountId?: string | undefined;
  offsetDateCreated?: Date;
  dateCreated?: Date;
  offsetDateUpdated?: Date;
  dateUpdated?: Date;
  isComplete?: boolean;
  offsetDateCompleted?: Date | undefined;
  dateCompleted?: Date | undefined;
  isRead?: boolean;
  offsetDateLastActivity?: Date | undefined;
  dateLastActivity?: Date | undefined;
  offsetDateLastEmailed?: Date | undefined;
  dateLastEmailed?: Date | undefined;
  offsetDateRead?: Date | undefined;
  dateRead?: Date | undefined;
  offsetDateMinDue?: Date | undefined;
  dateMinDue?: Date | undefined;
  unreadCount?: number;
  incompleteCount?: number;
  isArchived?: boolean;
  hasFiles?: boolean;
  offsetDateArchived?: Date | undefined;
  dateArchived?: Date | undefined;
  totalEvents?: number;
  totalMessages?: number;
  lastEventId?: string;
  lastEventRowKey?: string | undefined;
  lastMessage?: string | undefined;
  lastSubject?: string | undefined;
  recipientPeopleMetadata?: ActivityRecipient[] | undefined;
  recipientCompaniesMetadata?: ActivityAccount[] | undefined;
  recipientsDescription?: string | undefined;
  sinceLastActivity?: number;

  constructor(data?: IActivityThread) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.personId = _data['personId'];
      this.ownerId = _data['ownerId'];
      this.id = _data['id'];
      this.groupId = _data['groupId'];
      this.eventContext = _data['eventContext'];
      this.accountId = _data['accountId'];
      this.offsetDateCreated = _data['offsetDateCreated'] ? new Date(_data['offsetDateCreated'].toString()) : <any>undefined;
      this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
      this.offsetDateUpdated = _data['offsetDateUpdated'] ? new Date(_data['offsetDateUpdated'].toString()) : <any>undefined;
      this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
      this.isComplete = _data['isComplete'];
      this.offsetDateCompleted = _data['offsetDateCompleted'] ? new Date(_data['offsetDateCompleted'].toString()) : <any>undefined;
      this.dateCompleted = _data['dateCompleted'] ? new Date(_data['dateCompleted'].toString()) : <any>undefined;
      this.isRead = _data['isRead'];
      this.offsetDateLastActivity = _data['offsetDateLastActivity'] ? new Date(_data['offsetDateLastActivity'].toString()) : <any>undefined;
      this.dateLastActivity = _data['dateLastActivity'] ? new Date(_data['dateLastActivity'].toString()) : <any>undefined;
      this.offsetDateLastEmailed = _data['offsetDateLastEmailed'] ? new Date(_data['offsetDateLastEmailed'].toString()) : <any>undefined;
      this.dateLastEmailed = _data['dateLastEmailed'] ? new Date(_data['dateLastEmailed'].toString()) : <any>undefined;
      this.offsetDateRead = _data['offsetDateRead'] ? new Date(_data['offsetDateRead'].toString()) : <any>undefined;
      this.dateRead = _data['dateRead'] ? new Date(_data['dateRead'].toString()) : <any>undefined;
      this.offsetDateMinDue = _data['offsetDateMinDue'] ? new Date(_data['offsetDateMinDue'].toString()) : <any>undefined;
      this.dateMinDue = _data['dateMinDue'] ? new Date(_data['dateMinDue'].toString()) : <any>undefined;
      this.unreadCount = _data['unreadCount'];
      this.incompleteCount = _data['incompleteCount'];
      this.isArchived = _data['isArchived'];
      this.hasFiles = _data['hasFiles'];
      this.offsetDateArchived = _data['offsetDateArchived'] ? new Date(_data['offsetDateArchived'].toString()) : <any>undefined;
      this.dateArchived = _data['dateArchived'] ? new Date(_data['dateArchived'].toString()) : <any>undefined;
      this.totalEvents = _data['totalEvents'];
      this.totalMessages = _data['totalMessages'];
      this.lastEventId = _data['lastEventId'];
      this.lastEventRowKey = _data['lastEventRowKey'];
      this.lastMessage = _data['lastMessage'];
      this.lastSubject = _data['lastSubject'];
      if (Array.isArray(_data['recipientPeopleMetadata'])) {
        this.recipientPeopleMetadata = [] as any;
        for (const item of _data['recipientPeopleMetadata'])
          this.recipientPeopleMetadata!.push(ActivityRecipient.fromJS(item));
      }
      if (Array.isArray(_data['recipientCompaniesMetadata'])) {
        this.recipientCompaniesMetadata = [] as any;
        for (const item of _data['recipientCompaniesMetadata'])
          this.recipientCompaniesMetadata!.push(ActivityAccount.fromJS(item));
      }
      this.recipientsDescription = _data['recipientsDescription'];
      this.sinceLastActivity = _data['sinceLastActivity'];
    }
  }

  static fromJS(data: any): ActivityThread {
    data = typeof data === 'object' ? data : {};
    const result = new ActivityThread();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['personId'] = this.personId;
    data['ownerId'] = this.ownerId;
    data['id'] = this.id;
    data['groupId'] = this.groupId;
    data['eventContext'] = this.eventContext;
    data['accountId'] = this.accountId;
    data['offsetDateCreated'] = this.offsetDateCreated ? this.offsetDateCreated.toISOString() : <any>undefined;
    data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data['offsetDateUpdated'] = this.offsetDateUpdated ? this.offsetDateUpdated.toISOString() : <any>undefined;
    data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data['isComplete'] = this.isComplete;
    data['offsetDateCompleted'] = this.offsetDateCompleted ? this.offsetDateCompleted.toISOString() : <any>undefined;
    data['dateCompleted'] = this.dateCompleted ? this.dateCompleted.toISOString() : <any>undefined;
    data['isRead'] = this.isRead;
    data['offsetDateLastActivity'] = this.offsetDateLastActivity ? this.offsetDateLastActivity.toISOString() : <any>undefined;
    data['dateLastActivity'] = this.dateLastActivity ? this.dateLastActivity.toISOString() : <any>undefined;
    data['offsetDateLastEmailed'] = this.offsetDateLastEmailed ? this.offsetDateLastEmailed.toISOString() : <any>undefined;
    data['dateLastEmailed'] = this.dateLastEmailed ? this.dateLastEmailed.toISOString() : <any>undefined;
    data['offsetDateRead'] = this.offsetDateRead ? this.offsetDateRead.toISOString() : <any>undefined;
    data['dateRead'] = this.dateRead ? this.dateRead.toISOString() : <any>undefined;
    data['offsetDateMinDue'] = this.offsetDateMinDue ? this.offsetDateMinDue.toISOString() : <any>undefined;
    data['dateMinDue'] = this.dateMinDue ? this.dateMinDue.toISOString() : <any>undefined;
    data['unreadCount'] = this.unreadCount;
    data['incompleteCount'] = this.incompleteCount;
    data['isArchived'] = this.isArchived;
    data['hasFiles'] = this.hasFiles;
    data['offsetDateArchived'] = this.offsetDateArchived ? this.offsetDateArchived.toISOString() : <any>undefined;
    data['dateArchived'] = this.dateArchived ? this.dateArchived.toISOString() : <any>undefined;
    data['totalEvents'] = this.totalEvents;
    data['totalMessages'] = this.totalMessages;
    data['lastEventId'] = this.lastEventId;
    data['lastEventRowKey'] = this.lastEventRowKey;
    data['lastMessage'] = this.lastMessage;
    data['lastSubject'] = this.lastSubject;
    if (Array.isArray(this.recipientPeopleMetadata)) {
      data['recipientPeopleMetadata'] = [];
      for (const item of this.recipientPeopleMetadata)
        data['recipientPeopleMetadata'].push(item.toJSON());
    }
    if (Array.isArray(this.recipientCompaniesMetadata)) {
      data['recipientCompaniesMetadata'] = [];
      for (const item of this.recipientCompaniesMetadata)
        data['recipientCompaniesMetadata'].push(item.toJSON());
    }
    data['recipientsDescription'] = this.recipientsDescription;
    data['sinceLastActivity'] = this.sinceLastActivity;
    return data;
  }
}

export interface IActivityThread {
  personId?: string;
  ownerId?: string | undefined;
  id?: string | undefined;
  groupId?: string;
  eventContext?: string | undefined;
  accountId?: string | undefined;
  offsetDateCreated?: Date;
  dateCreated?: Date;
  offsetDateUpdated?: Date;
  dateUpdated?: Date;
  isComplete?: boolean;
  offsetDateCompleted?: Date | undefined;
  dateCompleted?: Date | undefined;
  isRead?: boolean;
  offsetDateLastActivity?: Date | undefined;
  dateLastActivity?: Date | undefined;
  offsetDateLastEmailed?: Date | undefined;
  dateLastEmailed?: Date | undefined;
  offsetDateRead?: Date | undefined;
  dateRead?: Date | undefined;
  offsetDateMinDue?: Date | undefined;
  dateMinDue?: Date | undefined;
  unreadCount?: number;
  incompleteCount?: number;
  isArchived?: boolean;
  hasFiles?: boolean;
  offsetDateArchived?: Date | undefined;
  dateArchived?: Date | undefined;
  totalEvents?: number;
  totalMessages?: number;
  lastEventId?: string;
  lastEventRowKey?: string | undefined;
  lastMessage?: string | undefined;
  lastSubject?: string | undefined;
  recipientPeopleMetadata?: ActivityRecipient[] | undefined;
  recipientCompaniesMetadata?: ActivityAccount[] | undefined;
  recipientsDescription?: string | undefined;
  sinceLastActivity?: number;
}