import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {DEVICE_CACHE} from './Actions';
import {DeviceSearchItem} from '../../types/views/DeviceSearchItem';

export interface IDevicesCacheState {
  deviceCacheUpdating: boolean | false;
  deviceCacheError: string | undefined;
  devicePendingIdList: string[];
  deviceCache: DeviceSearchItem[];
}

const initialState: IDevicesCacheState = {
  deviceCacheUpdating: false,
  deviceCacheError: undefined,
  devicePendingIdList: [],
  deviceCache: []
}

const devicesCacheReducer = createReducer(initialState, {
  [DEVICE_CACHE.ADD]: (state, action: PayloadAction<string[]>) => {
    state.devicePendingIdList = [...new Set([...state.devicePendingIdList, ...action.payload])];
    state.deviceCacheError = undefined;
  },
  [DEVICE_CACHE.UPDATE]: (state) => {
    state.devicePendingIdList = [];
    state.deviceCacheUpdating = true;
    state.deviceCacheError = undefined;
  },
  [DEVICE_CACHE.MERGE_ITEMS]: (state, action: PayloadAction<DeviceSearchItem[]>) => {
    const currentCache = state.deviceCache;
    action.payload.map(item => {
      if (!currentCache.find(x => x.id === item.id)) {
        currentCache.push(item);
      }
    });
    state.deviceCache = currentCache;
    state.deviceCacheUpdating = false;
    state.deviceCacheError = undefined;
  },
  [DEVICE_CACHE.REMOVE]: (state, action: PayloadAction<string>) => {
    state.deviceCache = state.deviceCache.filter(x => x.id !== action.payload);
    state.deviceCacheUpdating = false;
    state.deviceCacheError = undefined;
  },
});

export default devicesCacheReducer;