export class BackgroundWorkError implements IBackgroundWorkError {
  backgroundWorkId?: string;
  backgroundWorkBatchId?: string;
  dateCreated?: Date;
  failedEntityId?: string;
  entityType?: string | undefined;
  errorMessage?: string | undefined;
  exceptionType?: string | undefined;

  constructor(data?: IBackgroundWorkError) {
    if (data) {
      for (const property in data) {
        if (Object.prototype.hasOwnProperty.call(data, property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.backgroundWorkId = _data['backgroundWorkId'];
      this.backgroundWorkBatchId = _data['backgroundWorkBatchId'];
      this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
      this.failedEntityId = _data['failedEntityId'];
      this.entityType = _data['entityType'];
      this.errorMessage = _data['errorMessage'];
      this.exceptionType = _data['exceptionType'];
    }
  }

  static fromJS(data: any): BackgroundWorkError {
    data = typeof data === 'object' ? data : {};
    const result = new BackgroundWorkError();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['backgroundWorkId'] = this.backgroundWorkId;
    data['backgroundWorkBatchId'] = this.backgroundWorkBatchId;
    data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data['failedEntityId'] = this.failedEntityId;
    data['entityType'] = this.entityType;
    data['errorMessage'] = this.errorMessage;
    data['exceptionType'] = this.exceptionType;
    return data;
  }
}

export interface IBackgroundWorkError {
  backgroundWorkId?: string;
  backgroundWorkBatchId?: string;
  dateCreated?: Date;
  failedEntityId?: string;
  entityType?: string | undefined;
  errorMessage?: string | undefined;
  exceptionType?: string | undefined;
}
