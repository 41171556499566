import {BackgroundWorkStatus, BackgroundWorkType} from './Enums';

export class BackgroundWorkProgress implements IBackgroundWorkProgress {
  userId?: string;
  backgroundWorkType?: BackgroundWorkType;
  backgroundWorkStatus?: BackgroundWorkStatus;
  backgroundWorkId?: string;
  backgroundWorkDescription?: string | undefined;
  backgroundWorkParameters?: string | undefined;
  concurrentBatches?: number;
  backgroundWorkExpiresMinutes?: number;
  dateBackgroundWorkExpires?: Date;
  backgroundWorkExpires?: Date;
  dateCreated?: Date;
  dateCompleted?: Date;
  createdByUserId?: string;
  batchTotal?: number;
  batchProcessed?: number;
  batchSize?: number;
  itemsTotal?: number;
  itemsProcessed?: number;
  itemsFailed?: number;
  itemsSuccess?: number;

  constructor(data?: IBackgroundWorkProgress) {
    if (data) {
      for (const property in data) {
        if (Object.prototype.hasOwnProperty.call(data, property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.userId = _data['userId'];
      this.backgroundWorkType = _data['backgroundWorkType'];
      this.backgroundWorkStatus = _data['backgroundWorkStatus'];
      this.backgroundWorkId = _data['backgroundWorkId'];
      this.backgroundWorkDescription = _data['backgroundWorkDescription'];
      this.backgroundWorkParameters = _data['backgroundWorkParameters'];
      this.concurrentBatches = _data['concurrentBatches'];
      this.backgroundWorkExpiresMinutes = _data['backgroundWorkExpiresMinutes'];
      this.dateBackgroundWorkExpires = _data['dateBackgroundWorkExpires'] ? new Date(_data['dateBackgroundWorkExpires'].toString()) : <any>undefined;
      this.backgroundWorkExpires = _data['backgroundWorkExpires'] ? new Date(_data['backgroundWorkExpires'].toString()) : <any>undefined;
      this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
      this.dateCompleted = _data['dateCompleted'] ? new Date(_data['dateCompleted'].toString()) : <any>undefined;
      this.createdByUserId = _data['createdByUserId'];
      this.batchTotal = _data['batchTotal'];
      this.batchProcessed = _data['batchProcessed'];
      this.batchSize = _data['batchSize'];
      this.itemsTotal = _data['itemsTotal'];
      this.itemsProcessed = _data['itemsProcessed'];
      this.itemsFailed = _data['itemsFailed'];
      this.itemsSuccess = _data['itemsSuccess'];
    }
  }

  static fromJS(data: any): BackgroundWorkProgress {
    data = typeof data === 'object' ? data : {};
    const result = new BackgroundWorkProgress();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['userId'] = this.userId;
    data['backgroundWorkType'] = this.backgroundWorkType;
    data['backgroundWorkStatus'] = this.backgroundWorkStatus;
    data['backgroundWorkId'] = this.backgroundWorkId;
    data['backgroundWorkDescription'] = this.backgroundWorkDescription;
    data['backgroundWorkParameters'] = this.backgroundWorkParameters;
    data['concurrentBatches'] = this.concurrentBatches;
    data['backgroundWorkExpiresMinutes'] = this.backgroundWorkExpiresMinutes;
    data['dateBackgroundWorkExpires'] = this.dateBackgroundWorkExpires ? this.dateBackgroundWorkExpires.toISOString() : <any>undefined;
    data['backgroundWorkExpires'] = this.backgroundWorkExpires ? this.backgroundWorkExpires.toISOString() : <any>undefined;
    data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data['dateCompleted'] = this.dateCompleted ? this.dateCompleted.toISOString() : <any>undefined;
    data['createdByUserId'] = this.createdByUserId;
    data['batchTotal'] = this.batchTotal;
    data['batchProcessed'] = this.batchProcessed;
    data['batchSize'] = this.batchSize;
    data['itemsTotal'] = this.itemsTotal;
    data['itemsProcessed'] = this.itemsProcessed;
    data['itemsFailed'] = this.itemsFailed;
    data['itemsSuccess'] = this.itemsSuccess;
    return data;
  }
}

export interface IBackgroundWorkProgress {
  userId?: string;
  backgroundWorkType?: BackgroundWorkType;
  backgroundWorkStatus?: BackgroundWorkStatus;
  backgroundWorkId?: string;
  backgroundWorkDescription?: string | undefined;
  backgroundWorkParameters?: string | undefined;
  concurrentBatches?: number;
  backgroundWorkExpiresMinutes?: number;
  dateBackgroundWorkExpires?: Date;
  backgroundWorkExpires?: Date;
  dateCreated?: Date;
  dateCompleted?: Date;
  createdByUserId?: string;
  batchTotal?: number;
  batchProcessed?: number;
  batchSize?: number;
  itemsTotal?: number;
  itemsProcessed?: number;
  itemsFailed?: number;
  itemsSuccess?: number;
}
