import {AppRouteData, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import React from 'react';
import {adminUserPaths} from './paths';

const adminSectionBreadcrumbs = [new BreadcrumbItemProps('Admin Users', adminUserPaths.AdminUserSearch)];

const EditPage = React.lazy(() => import('./AdminUserEditPage'));
const DetailPage = React.lazy(() => import('./AdminUserDetails'));
const SearchPage = React.lazy(() => import('./AdminUserSearch'));

const adminUserRouteConfig = [
    { path: adminUserPaths.AdminUserSearch, element: <SearchPage />, handle: AppRouteData.create('Search', undefined, adminSectionBreadcrumbs) } ,
    { path: adminUserPaths.AdminUserDetails, element: <DetailPage />, handle: AppRouteData.create('Details', undefined, adminSectionBreadcrumbs) } ,
    { path: adminUserPaths.AdminUserCreate, element: <EditPage />, handle: AppRouteData.create('Create', undefined, adminSectionBreadcrumbs) } ,
    { path: adminUserPaths.AdminUserEdit, element: <EditPage />, handle: AppRouteData.create('Edit', undefined, adminSectionBreadcrumbs) } ,
];

export default adminUserRouteConfig;