
export enum EventContext {
  InternalAccount = 'InternalAccount',
  InternalInstructor = 'InternalInstructor',
  InternalDevice = 'InternalDevice',
  InternalParticipant = 'InternalParticipant',
  InternalSession = 'InternalSession',
  ExternalAccount = 'ExternalAccount',
  ExternalInstructor = 'ExternalInstructor',
  ExternalDevice = 'ExternalDevice',
  ExternalParticipant = 'ExternalParticipant',
  ExternalSession = 'ExternalSession'
}
