import {RouteTabParameter} from '../../types/common/RouteTabParameter';

export interface IAccountPaths {
    AccountEdit: string,
    AccountCreate: string,
    AccountProfile: string,
    AccountSearch: string,
}

export const accountPaths: IAccountPaths = {
    AccountEdit: '/accounts/edit/:idParam',
    AccountCreate: '/accounts/create',
    AccountProfile: '/accounts/view/:idParam',
    AccountSearch: '/accounts/search',
};

export interface IAccountProfileTabs {
    activity: RouteTabParameter;
    sessions: RouteTabParameter;
    instructors: RouteTabParameter;
    details: RouteTabParameter;
    participants: RouteTabParameter;
}

export const accountProfileTabs: IAccountProfileTabs = {
    activity: new RouteTabParameter(0, 'Activity', 'activity'),
    details: new RouteTabParameter(1, 'Details', 'details'),
    instructors: new RouteTabParameter(2, 'Instructors', 'instructors'),
    sessions: new RouteTabParameter(3, 'Sessions', 'sessions'),
    participants: new RouteTabParameter(4, 'Participants', 'participants'),
}

export const profileTabs = [
    accountProfileTabs.activity,
    accountProfileTabs.details,
    accountProfileTabs.instructors,
    accountProfileTabs.sessions,
    accountProfileTabs.participants
];

export const getAccountProfileTabs = (query: string | undefined | null) => profileTabs.find(x => x.query === query);

