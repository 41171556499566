import {ICommonSavePayload} from '../Common.actions';
import {AccountEdit} from '../../types/requests/AccountEdit';
import authenticatedApi from '../../@template/services/auth/authApi/authenticatedApi';
import {coreApiConfig} from '../../config';
import {AxiosResponse} from 'axios';
import {Account} from '../../types/models/Account';
import {all, call, put, takeEvery} from '@redux-saga/core/effects';
import {parseErrorInfo, parseErrorToErrorClass} from '../../@template/helpers/errors';
import {
    accountSaveSuccess,
    accountSaveError,
    accountLoadSuccess,
    ACCOUNTS,
    ACCOUNT_SEARCH,
    accountSearchSuccess,
    accountSearchError,
    accountLoadError,
    ACCOUNT_INSTRUCTOR_SEARCH,
    accountInstructorSearchSuccess,
    accountInstructorSearchError,
    ACCOUNT_SESSION_SEARCH,
    accountSessionsSearchSuccess,
    accountSessionsError, accountParticipantsSearchError, accountParticipantsSearchSuccess, ACCOUNT_PARTICIPANT_SEARCH
} from './Actions';
import {PayloadAction} from '@reduxjs/toolkit';
import {SearchRequest} from '../../types/common/SearchRequest';
import {AccountSearchRequest} from '../../types/requests/AccountSearchRequest';
import {PagedEntityListOfAccountSearchItem} from '../../types/views/PagedEntityListOfAccountSearchItem';
import {InstructorUserSearchRequest} from '../../types/requests/InstructorUserSearchRequest';
import {PagedEntityListOfInstructorUserSearchItem} from '../../types/views/PagedEntityListOfInstructorUserSearchItem';
import {SessionSearchRequest} from '../../types/requests/SessionSearchRequest';
import {PagedEntityListOfSessionSearchItem} from '../../types/views/PagedEntityListOfSessionSearchItem';
import {AccountParticipantSearchRequest} from '../../types/requests/AccountParticipantSearchRequest';
import {
    PagedEntityListOfAccountParticipantSearchItem
} from '../../types/views/PagedEntityListOfAccountParticipantSearchItem';

export function* SaveAccount({ id, submission }: ICommonSavePayload<AccountEdit>) {
    console.log('SAGA - accountSaga - SaveAccount');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: id ? 'PUT' : 'POST',
            url: id ? `${coreApiConfig.Paths.account}/${id}` : coreApiConfig.Paths.account,
            data: submission
        });
        const account: Account = Account.fromJS(res.data);
        yield put(accountSaveSuccess(account));
    } catch (err) {
        yield put(accountSaveError(parseErrorToErrorClass(err)));
    }
}


export function* LoadAccount(id: string) {
    console.log('SAGA - accountSaga - LoadAccount');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'GET',
            url: `${coreApiConfig.Paths.account}/${id}`,
        });
        const account: Account = Account.fromJS(res.data);
        yield put(accountLoadSuccess(account));
    } catch (err) {
        yield put(accountLoadError(parseErrorInfo(err)));
    }
}

export function* SearchAccounts({ page, pageSize, searchParams, sortBy, sortDirection }: SearchRequest<AccountSearchRequest>) {
    console.log('SAGA - accountSaga - SearchAccounts');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'PUT',
            url: `${coreApiConfig.Paths.account}/search?page=${page}&page_size=${pageSize}&sort=${sortBy}&order=${sortDirection}`,
            data: searchParams
        });
        const results: PagedEntityListOfAccountSearchItem = PagedEntityListOfAccountSearchItem.fromJS(res.data);
        yield put(accountSearchSuccess(results));
    } catch (err) {
        yield put(accountSearchError(parseErrorInfo(err)));
    }
}

export function* SearchAccountUsers({ page, pageSize, searchParams, sortBy, sortDirection }: SearchRequest<AccountSearchRequest>) {
    console.log('SAGA - accountSaga - SearchAccountUsers');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'PUT',
            url: `${coreApiConfig.Paths.instructorUser}/search?page=${page}&page_size=${pageSize}&sort=${sortBy}&order=${sortDirection}`,
            data: searchParams
        });
        const results: PagedEntityListOfInstructorUserSearchItem = PagedEntityListOfInstructorUserSearchItem.fromJS(res.data);
        yield put(accountInstructorSearchSuccess(results));
    } catch (err) {
        yield put(accountInstructorSearchError(parseErrorInfo(err)));
    }
}

export function* SearchAccountSessions({ page, pageSize, searchParams, sortBy, sortDirection }: SearchRequest<SessionSearchRequest>) {
    console.log('SAGA - accountSaga - SearchAccountSessions');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'PUT',
            url: `${coreApiConfig.Paths.session}/search?page=${page}&page_size=${pageSize}&sort=${sortBy}&order=${sortDirection}`,
            data: searchParams
        });
        const results: PagedEntityListOfSessionSearchItem = PagedEntityListOfSessionSearchItem.fromJS(res.data);
        yield put(accountSessionsSearchSuccess(results));
    } catch (err) {
        yield put(accountSessionsError(parseErrorInfo(err)));
    }
}

export function* SearchAccountParticipants({ page, pageSize, searchParams, sortBy, sortDirection }: SearchRequest<AccountParticipantSearchRequest>) {
    console.log('SAGA - accountSaga - SearchAccountParticipants');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'PUT',
            url: `${coreApiConfig.Paths.accountParticipant}/search?page=${page}&page_size=${pageSize}&sort=${sortBy}&order=${sortDirection}`,
            data: searchParams
        });
        const results: PagedEntityListOfAccountParticipantSearchItem = PagedEntityListOfAccountParticipantSearchItem.fromJS(res.data);
        yield put(accountParticipantsSearchSuccess(results));
    } catch (err) {
        yield put(accountParticipantsSearchError(parseErrorInfo(err)));
    }
}

export function* watchSaveAccount(){
    yield takeEvery(ACCOUNTS.SAVE, ({ payload } : PayloadAction<ICommonSavePayload<AccountEdit>>) => SaveAccount(payload));
}

export function* watchLoadAccount(){
    yield takeEvery(ACCOUNTS.LOAD, ({ payload } : PayloadAction<string>) => LoadAccount(payload));
}

export function* watchRefreshAccount(){
    yield takeEvery(ACCOUNTS.REFRESH, ({ payload } : PayloadAction<string>) => LoadAccount(payload));
}

export function* watchSearchAccounts(){
    yield takeEvery(ACCOUNT_SEARCH.LOAD, ({ payload } : PayloadAction<SearchRequest<AccountSearchRequest>>) => SearchAccounts(payload));
}

export function* watchSearchAccountUsers(){
    yield takeEvery(ACCOUNT_INSTRUCTOR_SEARCH.LOAD, ({ payload } : PayloadAction<SearchRequest<InstructorUserSearchRequest>>) => SearchAccountUsers(payload));
}

export function* watchSearchAccountUsersRefresh(){
    yield takeEvery(ACCOUNT_INSTRUCTOR_SEARCH.REFRESH, ({ payload } : PayloadAction<SearchRequest<InstructorUserSearchRequest>>) => SearchAccountUsers(payload));
}

export function* watchSearchAccountSessions(){
    yield takeEvery(ACCOUNT_SESSION_SEARCH.LOAD, ({ payload } : PayloadAction<SearchRequest<SessionSearchRequest>>) => SearchAccountSessions(payload));
}

export function* watchSearchAccountSessionsRefresh(){
    yield takeEvery(ACCOUNT_SESSION_SEARCH.REFRESH, ({ payload } : PayloadAction<SearchRequest<SessionSearchRequest>>) => SearchAccountSessions(payload));
}

export function* watchSearchAccountParticipants(){
    yield takeEvery(ACCOUNT_PARTICIPANT_SEARCH.LOAD, ({ payload } : PayloadAction<SearchRequest<AccountParticipantSearchRequest>>) => SearchAccountParticipants(payload));
}

export function* watchSearchAccountParticipantsRefresh(){
    yield takeEvery(ACCOUNT_PARTICIPANT_SEARCH.REFRESH, ({ payload } : PayloadAction<SearchRequest<AccountParticipantSearchRequest>>) => SearchAccountParticipants(payload));
}

export default function* accountSaga() {
    yield all ([
        call(watchSaveAccount),
        call(watchRefreshAccount),
        call(watchLoadAccount),
        call(watchSearchAccounts),
        call(watchSearchAccountUsers),
        call(watchSearchAccountUsersRefresh),
        call(watchSearchAccountSessions),
        call(watchSearchAccountSessionsRefresh),
        call(watchSearchAccountParticipants),
        call(watchSearchAccountParticipantsRefresh)
    ])
}