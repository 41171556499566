import {createAction} from '@reduxjs/toolkit';
import {ICommonCacheActionType} from '../Common.actions';
import {AccountParticipantSearchItem} from '../../types/views/AccountParticipantSearchItem';


export const ACCOUNT_PARTICIPANT_CACHE: ICommonCacheActionType = {
  ADD: 'accountParticipantCache/add',
  UPDATE: 'accountParticipantCache/updated',
  MERGE_ITEMS: 'accountParticipantCache/merge',
  ERROR: 'accountParticipantCache/error',
  REMOVE: 'accountParticipantCache/remove'
};

export const accountParticipantCacheAdd = createAction<string[]>(ACCOUNT_PARTICIPANT_CACHE.ADD);
export const accountParticipantCacheUpdate = createAction<string[]>(ACCOUNT_PARTICIPANT_CACHE.UPDATE);
export const accountParticipantCacheMerge = createAction<AccountParticipantSearchItem[]>(ACCOUNT_PARTICIPANT_CACHE.MERGE_ITEMS);
export const accountParticipantCacheError = createAction<string>(ACCOUNT_PARTICIPANT_CACHE.ERROR);
export const accountParticipantCacheRemove = createAction<string>(ACCOUNT_PARTICIPANT_CACHE.REMOVE);
