import {SessionParticipantReview} from './SessionParticipantReview';

export class SessionParticipant implements ISessionParticipant {
    id?: string;
    accountId?: string;
    sessionId?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    accountParticipantId?: string;
    linkedByPersonId?: string | undefined;
    linkedSource?: string | undefined;
    isAuthenticated?: boolean;
    userReference?: string | undefined;
    sessionParticipantReview?: SessionParticipantReview | undefined;

    constructor(data?: ISessionParticipant) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'];
            this.accountId = _data['accountId'];
            this.sessionId = _data['sessionId'];
            this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
            this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
            this.createdByPersonId = _data['createdByPersonId'];
            this.updatedByPersonId = _data['updatedByPersonId'];
            this.accountParticipantId = _data['accountParticipantId'];
            this.linkedByPersonId = _data['linkedByPersonId'];
            this.linkedSource = _data['linkedSource'];
            this.isAuthenticated = _data['isAuthenticated'];
            this.userReference = _data['userReference'];
            this.sessionParticipantReview = _data['sessionParticipantReview'] ? SessionParticipantReview.fromJS(_data['sessionParticipantReview']) : <any>undefined;
        }
    }

    static fromJS(data: any): SessionParticipant {
        data = typeof data === 'object' ? data : {};
        const result = new SessionParticipant();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id;
        data['accountId'] = this.accountId;
        data['sessionId'] = this.sessionId;
        data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
        data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
        data['createdByPersonId'] = this.createdByPersonId;
        data['updatedByPersonId'] = this.updatedByPersonId;
        data['accountParticipantId'] = this.accountParticipantId;
        data['linkedByPersonId'] = this.linkedByPersonId;
        data['linkedSource'] = this.linkedSource;
        data['isAuthenticated'] = this.isAuthenticated;
        data['userReference'] = this.userReference;
        data['sessionParticipantReview'] = this.sessionParticipantReview ? this.sessionParticipantReview.toJSON() : <any>undefined;
        return data;
    }
}

export interface ISessionParticipant {
    id?: string;
    accountId?: string;
    sessionId?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    accountParticipantId?: string;
    linkedByPersonId?: string | undefined;
    linkedSource?: string | undefined;
    isAuthenticated?: boolean;
    userReference?: string | undefined;
    sessionParticipantReview?: SessionParticipantReview | undefined;
}