import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {Account} from '../../types/models/Account';
import {
    ACCOUNT_INSTRUCTOR_SEARCH,
    ACCOUNT_PARTICIPANT_SEARCH,
    ACCOUNT_SEARCH,
    ACCOUNT_SESSION_SEARCH,
    ACCOUNTS
} from './Actions';
import {IError} from '../../@template/helpers/errors';
import {SearchRequest} from '../../types/common/SearchRequest';
import {AccountSearchRequest, defaultForAccountSearchRequest} from '../../types/requests/AccountSearchRequest';
import {DefaultSortByOptions} from '../../types/common/SortByOptions/DefaultSortByOptions';
import {SortOrderOptions} from '../../types/common/SortOrderOptions';
import {PagedEntityListOfAccountSearchItem} from '../../types/views/PagedEntityListOfAccountSearchItem';
import {PagedEntityListOfInstructorUserSearchItem} from '../../types/views/PagedEntityListOfInstructorUserSearchItem';
import {InstructorUserSearchRequest} from '../../types/requests/InstructorUserSearchRequest';
import {SessionSearchRequest} from '../../types/requests/SessionSearchRequest';
import {PagedEntityListOfSessionSearchItem} from '../../types/views/PagedEntityListOfSessionSearchItem';
import {
    PagedEntityListOfAccountParticipantSearchItem
} from '../../types/views/PagedEntityListOfAccountParticipantSearchItem';
import {AccountParticipantSearchRequest} from '../../types/requests/AccountParticipantSearchRequest';

export interface IAccountState {
    account: Account | undefined;
    accountLoading: boolean | false;
    accountLoadError: string | undefined;
    accountSaving: boolean | false;
    accountSaveError: IError | undefined;
    accountSearchParams: AccountSearchRequest;
    accountSearchSortOption: DefaultSortByOptions;
    accountSearchSortOrder: SortOrderOptions;
    accountsResult: PagedEntityListOfAccountSearchItem | undefined;
    accountsSearching: boolean;
    accountSearchError: string | undefined;
    accountUsersResult: PagedEntityListOfInstructorUserSearchItem | undefined;
    accountUsersSearching: boolean;
    accountUsersError: string | undefined;
    accountSessionsResult: PagedEntityListOfSessionSearchItem | undefined,
    accountSessionsSearching: boolean;
    accountSessionsError: string | undefined;
    accountParticipantsResult: PagedEntityListOfAccountParticipantSearchItem | undefined,
    accountParticipantsSearching: boolean;
    accountParticipantsError: string | undefined;
}

const initialState: IAccountState = {
    account: undefined,
    accountLoading: false,
    accountLoadError: undefined,
    accountSaving: false,
    accountSaveError: undefined,
    accountSearchParams: defaultForAccountSearchRequest(),
    accountSearchSortOption: DefaultSortByOptions.Relevance,
    accountSearchSortOrder: SortOrderOptions.Desc,
    accountsResult: undefined,
    accountsSearching: false,
    accountSearchError: undefined,
    accountUsersResult: undefined,
    accountUsersSearching: false,
    accountUsersError: undefined,
    accountSessionsResult: undefined,
    accountSessionsSearching: false,
    accountSessionsError: undefined,
    accountParticipantsResult: undefined,
    accountParticipantsSearching: false,
    accountParticipantsError: undefined
}

const accountReducer = createReducer(initialState, {
    [ACCOUNTS.LOAD]: (state) => {
        state.account = undefined;
        state.accountLoading = true;
        state.accountLoadError = undefined;
        state.accountSaveError = undefined;
    },
    [ACCOUNTS.REFRESH]: (state) => {
        state.accountLoading = false;
        state.accountLoadError = undefined;
        state.accountSaveError = undefined;
    },
    [ACCOUNTS.LOADED]: (state, action: PayloadAction<Account>) => {
        state.accountLoading = false;
        state.accountLoadError = undefined;
        state.account = action.payload;
    },
    [ACCOUNTS.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
        state.accountLoading = false;
        state.accountLoadError = action.payload;
        state.account = undefined;
    },
    [ACCOUNTS.SAVE]: (state) => {
        state.accountSaving = true;
        state.accountSaveError = undefined;
    },
    [ACCOUNTS.SAVED]: (state, action: PayloadAction<Account>) => {
        state.accountSaving = false;
        state.accountSaveError = undefined;
        state.account = action.payload;
    },
    [ACCOUNTS.SAVE_ERROR]: (state, action: PayloadAction<IError>) => {
        state.accountSaving = false;
        state.accountSaveError = action.payload;
    },
    [ACCOUNT_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<AccountSearchRequest>>) => {
        state.accountSearchParams = action.payload.searchParams;
        state.accountSearchSortOption = action.payload.sortBy??(DefaultSortByOptions.Relevance);
        state.accountSearchSortOrder = action.payload.sortDirection??(SortOrderOptions.Desc);
        state.accountsSearching = true;
        state.accountSearchError = undefined;
    },
    [ACCOUNT_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
        state.accountsSearching = false;
        state.accountSearchError = action.payload;
    },
    [ACCOUNT_SEARCH.LOADED]: (state, action: PayloadAction<PagedEntityListOfAccountSearchItem>) => {
        state.accountsSearching = false;
        state.accountSearchError = undefined;
        state.accountsResult = action.payload;
    },
    [ACCOUNT_INSTRUCTOR_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<InstructorUserSearchRequest>>) => {
        state.accountUsersResult = undefined;
        state.accountUsersSearching = true;
        state.accountUsersError = undefined;
    },
    [ACCOUNT_INSTRUCTOR_SEARCH.REFRESH]: (state, action: PayloadAction<SearchRequest<InstructorUserSearchRequest>>) => {
        state.accountUsersResult = undefined;
        state.accountUsersSearching = false;
        state.accountUsersError = undefined;
    },
    [ACCOUNT_INSTRUCTOR_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
        state.accountUsersSearching = false;
        state.accountUsersError = action.payload;
    },
    [ACCOUNT_INSTRUCTOR_SEARCH.LOADED]: (state, action: PayloadAction<PagedEntityListOfInstructorUserSearchItem>) => {
        state.accountUsersSearching = false;
        state.accountUsersError = undefined;
        state.accountUsersResult = action.payload;
    },
    [ACCOUNT_SESSION_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<SessionSearchRequest>>) => {
        state.accountSessionsResult = undefined;
        state.accountSessionsSearching = true;
        state.accountSessionsError = undefined;
    },
    [ACCOUNT_SESSION_SEARCH.REFRESH]: (state, action: PayloadAction<SearchRequest<SessionSearchRequest>>) => {
        state.accountSessionsResult = undefined;
        state.accountSessionsSearching = false;
        state.accountSessionsError = undefined;
    },
    [ACCOUNT_SESSION_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
        state.accountSessionsSearching = false;
        state.accountSessionsError = action.payload;
    },
    [ACCOUNT_SESSION_SEARCH.LOADED]: (state, action: PayloadAction<PagedEntityListOfSessionSearchItem>) => {
        state.accountSessionsSearching = false;
        state.accountSessionsError = undefined;
        state.accountSessionsResult = action.payload;
    },
    [ACCOUNT_PARTICIPANT_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<AccountParticipantSearchRequest>>) => {
        state.accountParticipantsResult = undefined;
        state.accountParticipantsSearching = true;
        state.accountParticipantsError = undefined;
    },
    [ACCOUNT_PARTICIPANT_SEARCH.REFRESH]: (state, action: PayloadAction<SearchRequest<AccountParticipantSearchRequest>>) => {
        state.accountParticipantsResult = undefined;
        state.accountParticipantsSearching = true;
        state.accountParticipantsError = undefined;
    },
    [ACCOUNT_PARTICIPANT_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
        state.accountParticipantsSearching = false;
        state.accountParticipantsError = action.payload;
    },
    [ACCOUNT_PARTICIPANT_SEARCH.LOADED]: (state, action: PayloadAction<PagedEntityListOfAccountParticipantSearchItem>) => {
        state.accountParticipantsSearching = false;
        state.accountParticipantsError = undefined;
        state.accountParticipantsResult = action.payload;
    },
});

export default accountReducer;