import {AppRouteData, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {instructorPaths} from '../instructors/paths';
import React from 'react';

const instructorSectionBreadcrumbs = [new BreadcrumbItemProps('Instructors', instructorPaths.InstructorSearch)];

const EditPage = React.lazy(() => import('./InstructorEditPage'));
const DetailPage = React.lazy(() => import('./InstructorProfile'));
const SearchPage = React.lazy(() => import('./InstructorSearch'));

const instructorRouteConfig = [
    { path: instructorPaths.InstructorSearch, element: <SearchPage />, handle: AppRouteData.create('Search', undefined, instructorSectionBreadcrumbs) } ,
    { path: instructorPaths.InstructorProfile, element: <DetailPage />, handle: AppRouteData.create('Details', undefined, instructorSectionBreadcrumbs) } ,
    { path: instructorPaths.InstructorCreate, element: <EditPage />, handle: AppRouteData.create('Create', undefined, instructorSectionBreadcrumbs) } ,
    { path: instructorPaths.InstructorEdit, element: <EditPage />, handle: AppRouteData.create('Edit', undefined, instructorSectionBreadcrumbs) } ,
];

export default instructorRouteConfig;