export class AccountParticipantSearchItem implements IAccountParticipantSearchItem {
    id?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    accountId?: string;
    participantPersonId?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    jobTitle?: string | undefined;
    jobDepartment?: string | undefined;
    userReference?: string | undefined;
    customTextField1?: string | undefined;
    fullName?: string | undefined;

    constructor(data?: IAccountParticipantSearchItem) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'];
            this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
            this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
            this.createdByPersonId = _data['createdByPersonId'];
            this.updatedByPersonId = _data['updatedByPersonId'];
            this.accountId = _data['accountId'];
            this.participantPersonId = _data['participantPersonId'];
            this.firstName = _data['firstName'];
            this.lastName = _data['lastName'];
            this.email = _data['email'];
            this.jobTitle = _data['jobTitle'];
            this.jobDepartment = _data['jobDepartment'];
            this.userReference = _data['userReference'];
            this.customTextField1 = _data['customTextField1'];
            this.fullName = _data['fullName'];
        }
    }

    static fromJS(data: any): AccountParticipantSearchItem {
        data = typeof data === 'object' ? data : {};
        const result = new AccountParticipantSearchItem();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id;
        data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
        data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
        data['createdByPersonId'] = this.createdByPersonId;
        data['updatedByPersonId'] = this.updatedByPersonId;
        data['accountId'] = this.accountId;
        data['participantPersonId'] = this.participantPersonId;
        data['firstName'] = this.firstName;
        data['lastName'] = this.lastName;
        data['email'] = this.email;
        data['jobTitle'] = this.jobTitle;
        data['jobDepartment'] = this.jobDepartment;
        data['userReference'] = this.userReference;
        data['customTextField1'] = this.customTextField1;
        data['fullName'] = this.fullName;
        return data;
    }
}

export interface IAccountParticipantSearchItem {
    id?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    accountId?: string;
    participantPersonId?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    jobTitle?: string | undefined;
    jobDepartment?: string | undefined;
    userReference?: string | undefined;
    customTextField1?: string | undefined;
    fullName?: string | undefined;
}