import {GridSize} from '@mui/material';

export interface IGridLayout {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
}

export class GridLayout implements IGridLayout {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;

  constructor(data?: IGridLayout) {
    if (data) {
      for (const property in data) {
        if (Object.prototype.hasOwnProperty.call(data, property))
          (this as any)[property] = (data  as any)[property];
      }
    }
  }
}