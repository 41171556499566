import React from 'react';
import {AppRouteData, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {participantPaths} from './paths';
import { accountPaths } from '../accounts/paths';

const participantSectionBreadcrumbs = [new BreadcrumbItemProps('Accounts', accountPaths.AccountSearch)];

const EditPage = React.lazy(() => import('./AccountParticipantEdit'));
const DetailPage = React.lazy(() => import('./AccountParticipantProfile'));
const SearchPage = React.lazy(() => import('./AccountParticipantSearch'));

const participantRouteConfig = [
    { path: participantPaths.ParticipantProfile, element: <DetailPage />, handle: AppRouteData.create('Details', undefined, participantSectionBreadcrumbs) } ,
    { path: participantPaths.ParticipantCreate, element: <EditPage />, handle: AppRouteData.create('Create', undefined, participantSectionBreadcrumbs) } ,
    { path: participantPaths.ParticipantEdit, element: <EditPage />, handle: AppRouteData.create('Edit', undefined, participantSectionBreadcrumbs) } ,
    { path: participantPaths.ParticipantSearch, element: <SearchPage />, handle: AppRouteData.create('Edit', undefined, participantSectionBreadcrumbs) } ,
];

export default participantRouteConfig;