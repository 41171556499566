import {ICommonSaveActionType, ICommonSavePayload, ICommonSearchActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {AccountEdit} from '../../types/requests/AccountEdit';
import {Account} from '../../types/models/Account';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {IError} from '../../@template/helpers/errors';
import {AccountSearchRequest} from '../../types/requests/AccountSearchRequest';
import {PagedEntityListOfAccountSearchItem} from '../../types/views/PagedEntityListOfAccountSearchItem';
import {InstructorUserSearchRequest} from '../../types/requests/InstructorUserSearchRequest';
import {PagedEntityListOfInstructorUserSearchItem} from '../../types/views/PagedEntityListOfInstructorUserSearchItem';
import {SessionSearchRequest} from '../../types/requests/SessionSearchRequest';
import {PagedEntityListOfSessionSearchItem} from '../../types/views/PagedEntityListOfSessionSearchItem';
import {AccountParticipantSearchRequest} from '../../types/requests/AccountParticipantSearchRequest';
import {
    PagedEntityListOfAccountParticipantSearchItem
} from '../../types/views/PagedEntityListOfAccountParticipantSearchItem';


export const ACCOUNTS: ICommonSaveActionType = {
    SAVE: 'account/save',
    SAVED: 'account/saveSuccess',
    SAVE_ERROR: 'account/saveError',
    LOAD: 'account/load',
    REFRESH: 'account/refresh',
    LOAD_ERROR: 'account/loadError',
    LOADED: 'account/loadSuccess',
}

export const ACCOUNT_SEARCH: ICommonSearchActionType = {
    CHANGE_VIEW: 'accountSearch/changeView',
    ERROR: 'accountSearch/error',
    LOAD: 'accountSearch/load',
    LOADED: 'accountSearch/loaded',
    REFRESH: 'accountSearch/refresh'
}

export const ACCOUNT_INSTRUCTOR_SEARCH: ICommonSearchActionType = {
    CHANGE_VIEW: 'accountInstructorSearch/changeView',
    ERROR: 'accountInstructorSearch/error',
    LOAD: 'accountInstructorSearch/load',
    LOADED: 'accountInstructorSearch/loaded',
    REFRESH: 'accountInstructorSearch/refresh'
}

export const ACCOUNT_SESSION_SEARCH: ICommonSearchActionType = {
    CHANGE_VIEW: 'accountSessionSearch/changeView',
    ERROR: 'accountSessionSearch/error',
    LOAD: 'accountSessionSearch/load',
    LOADED: 'accountSessionSearch/loaded',
    REFRESH: 'accountSessionSearch/refresh'
}

export const ACCOUNT_PARTICIPANT_SEARCH: ICommonSearchActionType = {
    CHANGE_VIEW: 'accountParticipantSearch/changeView',
    ERROR: 'accountParticipantSearch/error',
    LOAD: 'accountParticipantSearch/load',
    LOADED: 'accountParticipantSearch/loaded',
    REFRESH: 'accountParticipantSearch/refresh'
}


export const accountSave = createAction<ICommonSavePayload<AccountEdit>>(ACCOUNTS.SAVE);
export const accountSaveSuccess = createAction<Account>(ACCOUNTS.SAVED);
export const accountSaveError = createAction<IError>(ACCOUNTS.SAVE_ERROR);
export const accountLoad = createAction<string>(ACCOUNTS.LOAD);
export const accountRefresh = createAction<string>(ACCOUNTS.REFRESH);
export const accountLoadSuccess = createAction<Account>(ACCOUNTS.LOADED);
export const accountLoadError = createAction<string>(ACCOUNTS.LOAD_ERROR);
export const accountSearch = createAction<ISearchRequest<AccountSearchRequest>>(ACCOUNT_SEARCH.LOAD);
export const accountSearchSuccess = createAction<PagedEntityListOfAccountSearchItem>(ACCOUNT_SEARCH.LOADED);
export const accountSearchError = createAction<string>(ACCOUNT_SEARCH.ERROR);

export const accountInstructorSearch = createAction<ISearchRequest<InstructorUserSearchRequest>>(ACCOUNT_INSTRUCTOR_SEARCH.LOAD);
export const accountInstructorRefresh = createAction<ISearchRequest<InstructorUserSearchRequest>>(ACCOUNT_INSTRUCTOR_SEARCH.REFRESH);
export const accountInstructorSearchSuccess = createAction<PagedEntityListOfInstructorUserSearchItem>(ACCOUNT_INSTRUCTOR_SEARCH.LOADED);
export const accountInstructorSearchError = createAction<string>(ACCOUNT_INSTRUCTOR_SEARCH.ERROR);

export const accountSessionsSearch = createAction<ISearchRequest<SessionSearchRequest>>(ACCOUNT_SESSION_SEARCH.LOAD);
export const accountSessionsRefresh = createAction<ISearchRequest<SessionSearchRequest>>(ACCOUNT_SESSION_SEARCH.REFRESH);
export const accountSessionsSearchSuccess = createAction<PagedEntityListOfSessionSearchItem>(ACCOUNT_SESSION_SEARCH.LOADED);
export const accountSessionsError = createAction<string>(ACCOUNT_SESSION_SEARCH.ERROR);

export const accountParticipantsSearch = createAction<ISearchRequest<AccountParticipantSearchRequest>>(ACCOUNT_PARTICIPANT_SEARCH.LOAD);
export const accountParticipantsRefresh = createAction<ISearchRequest<AccountParticipantSearchRequest>>(ACCOUNT_PARTICIPANT_SEARCH.REFRESH);
export const accountParticipantsSearchSuccess = createAction<PagedEntityListOfAccountParticipantSearchItem>(ACCOUNT_PARTICIPANT_SEARCH.LOADED);
export const accountParticipantsSearchError = createAction<string>(ACCOUNT_PARTICIPANT_SEARCH.ERROR);

