import authenticatedApi from '../services/auth/authApi/authenticatedApi';
import {filesApiConfig} from '../../config';

export const getImageUrlFromId = (imageId: string, profile: string) => {
  return `${filesApiConfig.Paths.image}/${profile}/${imageId}`;
}

export const fetchImageAsBase64 = (url: string) => {
  return new Promise<string | undefined>((resolve, reject) => {
    authenticatedApi.request({
      baseURL: filesApiConfig.Url,
      method: 'GET',
      responseType: 'blob',
      timeout: 180000,
      url: url,
    }).then((response) => {
      blobToBase64(response.data).then((base64: string | null) => {
        if (base64) resolve(base64);
        resolve(undefined);
      })
    }).catch(err => {
      reject(err);
    });
  })
}

const blobToBase64 = (blob: Blob) => {
  return new Promise<string | null>((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result as string);
    };
  });
}