import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {ACCOUNT_CACHE} from './Actions';
import {AccountSearchItem} from '../../types/views/AccountSearchItem';

export interface IAccountsCacheState {
  accountCacheUpdating: boolean | false;
  accountCacheError: string | undefined;
  accountPendingIdList: string[];
  accountCache: AccountSearchItem[];
}

const initialState: IAccountsCacheState = {
  accountCacheUpdating: false,
  accountCacheError: undefined,
  accountPendingIdList: [],
  accountCache: []
}

const accountsCacheReducer = createReducer(initialState, {
  [ACCOUNT_CACHE.ADD]: (state, action: PayloadAction<string[]>) => {
    state.accountPendingIdList = [...new Set([...state.accountPendingIdList, ...action.payload])];
    state.accountCacheError = undefined;
  },
  [ACCOUNT_CACHE.UPDATE]: (state) => {
    state.accountPendingIdList = [];
    state.accountCacheUpdating = true;
    state.accountCacheError = undefined;
  },
  [ACCOUNT_CACHE.MERGE_ITEMS]: (state, action: PayloadAction<AccountSearchItem[]>) => {
    const currentCache = state.accountCache;
    action.payload.map(item => {
      if (!currentCache.find(x => x.id === item.id)) {
        currentCache.push(item);
      }
    });
    state.accountCache = currentCache;
    state.accountCacheUpdating = false;
    state.accountCacheError = undefined;
  },
  [ACCOUNT_CACHE.REMOVE]: (state, action: PayloadAction<string>) => {
    state.accountCache = state.accountCache.filter(x => x.id !== action.payload);
    state.accountCacheUpdating = false;
    state.accountCacheError = undefined;
  },
});

export default accountsCacheReducer;