import {FileMetadataBase, IFileMetadataBase} from './FileMetadataBase';

export class SessionFileMetadata extends FileMetadataBase implements ISessionFileMetadata {
    accountId?: string;
    sessionId?: string;
    filePath?: string | undefined;

    constructor(data?: ISessionFileMetadata) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            this.accountId = _data['accountId'];
            this.sessionId = _data['sessionId'];
            this.filePath = _data['filePath'];
        }
    }

    static fromJS(data: any): SessionFileMetadata {
        data = typeof data === 'object' ? data : {};
        const result = new SessionFileMetadata();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['accountId'] = this.accountId;
        data['sessionId'] = this.sessionId;
        data['filePath'] = this.filePath;
        super.toJSON(data);
        return data;
    }
}

export interface ISessionFileMetadata extends IFileMetadataBase {
    accountId?: string;
    sessionId?: string;
    filePath?: string | undefined;
}