import React, {useContext} from 'react';

import AppContext from '../../utility/AppContext';
import Layouts from './Layouts';
import useStyles from '../../../shared/jss/common/common.style';
import AppContextPropsType from '../../../types/AppContextPropsType';
import AuthRoutes from '../../utility/AuthRoutes';



const OperatorLayout: React.FC = () => {
  useStyles();
  const {navStyle} = useContext<AppContextPropsType>(AppContext);
  const AppLayout = Layouts[navStyle];

  // return <AppLayout />;
  return <AuthRoutes>
    <AppLayout />
  </AuthRoutes>;
};

export default React.memo(OperatorLayout);
