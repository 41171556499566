import {all} from '@redux-saga/core/effects';
import authSaga from './auth/Sagas';
import settingsSaga from './settings/Sagas';
import messageSaga from './messaging/Sagas';
import filesSaga from './files/Sagas';
import accountSaga from './account/Sagas';
import deviceSaga from './device/Sagas';
import deviceCacheSaga from './deviceCache/Sagas';
import adminUserSaga from './adminUser/Sagas';
import accountCacheSaga from './accountCache/Sagas';
import personCacheSaga from './personCache/Sagas';
import instructorUserSaga from './instructorUser/Sagas';
import sessionSaga from './session/Sagas';
import accountParticipantsSaga from './accountParticipant/Sagas';
import accountParticipantsCacheSaga from './accountParticipantCache/Sagas';


export default function* rootSaga() {
  yield all([
    authSaga(),
    accountSaga(),
    accountCacheSaga(),
    accountParticipantsSaga(),
    accountParticipantsCacheSaga(),
    adminUserSaga(),
    deviceSaga(),
    deviceCacheSaga(),
    filesSaga(),
    instructorUserSaga(),
    messageSaga(),
    personCacheSaga(),
    settingsSaga(),
    sessionSaga(),
  ]);
}
