import {ReferenceField} from './ReferenceField';
import {ActivityTracking} from './ActivityTracking';
import {ActivityFile} from './ActivityFile';

export class ActivityEventItem implements IActivityEventItem {
  ownerId?: string | undefined;
  id?: string;
  activityRowKey?: string | undefined;
  activityId?: string;
  accountId?: string | undefined;
  personId?: string | undefined;
  internalInbox?: boolean;
  threadId?: string | undefined;
  offsetDateCreated?: Date;
  dateCreated?: Date;
  offsetDateUpdated?: Date;
  dateUpdated?: Date;
  offsetEventDate?: Date | undefined;
  eventDate?: Date | undefined;
  eventContext?: string | undefined;
  eventCategory?: string | undefined;
  eventCategoryRef?: string | undefined;
  eventSubCategory?: string | undefined;
  eventSubCategoryRef?: string | undefined;
  actionReference?: string | undefined;
  referenceFields?: ReferenceField[] | undefined;
  referenceContent?: string[] | undefined;
  message?: string | undefined;
  isSystem?: boolean;
  preventNotifySender?: boolean;
  authorPersonId?: string;
  authorAccountId?: string | undefined;
  authorInternal?: boolean;
  authorTeamId?: string | undefined;
  isPending?: boolean;
  isDelivered?: boolean;
  isComplete?: boolean;
  offsetDateCompleted?: Date | undefined;
  dateCompleted?: Date | undefined;
  offsetDateDue?: Date | undefined;
  dateDue?: Date | undefined;
  priority?: number | undefined;
  actionScheduleId?: string | undefined;
  hasSchedule?: boolean;
  completedPersonId?: string | undefined;
  completedStatus?: string | undefined;
  completedByActivityId?: string | undefined;
  ownerRecipients?: string[] | undefined;
  outgoingTrackItem?: ActivityTracking | undefined;
  incomingTrackItem?: ActivityTracking | undefined;
  hasFiles?: boolean;
  files?: ActivityFile[] | undefined;

  constructor(data?: IActivityEventItem) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.ownerId = _data['ownerId'];
      this.id = _data['id'];
      this.activityRowKey = _data['activityRowKey'];
      this.activityId = _data['activityId'];
      this.accountId = _data['accountId'];
      this.personId = _data['personId'];
      this.internalInbox = _data['internalInbox'];
      this.threadId = _data['threadId'];
      this.offsetDateCreated = _data['offsetDateCreated'] ? new Date(_data['offsetDateCreated'].toString()) : <any>undefined;
      this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
      this.offsetDateUpdated = _data['offsetDateUpdated'] ? new Date(_data['offsetDateUpdated'].toString()) : <any>undefined;
      this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
      this.offsetEventDate = _data['offsetEventDate'] ? new Date(_data['offsetEventDate'].toString()) : <any>undefined;
      this.eventDate = _data['eventDate'] ? new Date(_data['eventDate'].toString()) : <any>undefined;
      this.eventContext = _data['eventContext'];
      this.eventCategory = _data['eventCategory'];
      this.eventCategoryRef = _data['eventCategoryRef'];
      this.eventSubCategory = _data['eventSubCategory'];
      this.eventSubCategoryRef = _data['eventSubCategoryRef'];
      this.actionReference = _data['actionReference'];
      if (Array.isArray(_data['referenceFields'])) {
        this.referenceFields = [] as any;
        for (const item of _data['referenceFields'])
          this.referenceFields!.push(ReferenceField.fromJS(item));
      }
      if (Array.isArray(_data['referenceContent'])) {
        this.referenceContent = [] as any;
        for (const item of _data['referenceContent'])
          this.referenceContent!.push(item);
      }
      this.message = _data['message'];
      this.isSystem = _data['isSystem'];
      this.preventNotifySender = _data['preventNotifySender'];
      this.authorPersonId = _data['authorPersonId'];
      this.authorAccountId = _data['authorAccountId'];
      this.authorInternal = _data['authorInternal'];
      this.authorTeamId = _data['authorTeamId'];
      this.isPending = _data['isPending'];
      this.isDelivered = _data['isDelivered'];
      this.isComplete = _data['isComplete'];
      this.offsetDateCompleted = _data['offsetDateCompleted'] ? new Date(_data['offsetDateCompleted'].toString()) : <any>undefined;
      this.dateCompleted = _data['dateCompleted'] ? new Date(_data['dateCompleted'].toString()) : <any>undefined;
      this.offsetDateDue = _data['offsetDateDue'] ? new Date(_data['offsetDateDue'].toString()) : <any>undefined;
      this.dateDue = _data['dateDue'] ? new Date(_data['dateDue'].toString()) : <any>undefined;
      this.priority = _data['priority'];
      this.actionScheduleId = _data['actionScheduleId'];
      this.hasSchedule = _data['hasSchedule'];
      this.completedPersonId = _data['completedPersonId'];
      this.completedStatus = _data['completedStatus'];
      this.completedByActivityId = _data['completedByActivityId'];
      if (Array.isArray(_data['ownerRecipients'])) {
        this.ownerRecipients = [] as any;
        for (const item of _data['ownerRecipients'])
          this.ownerRecipients!.push(item);
      }
      this.outgoingTrackItem = _data['outgoingTrackItem'] ? ActivityTracking.fromJS(_data['outgoingTrackItem']) : <any>undefined;
      this.incomingTrackItem = _data['incomingTrackItem'] ? ActivityTracking.fromJS(_data['incomingTrackItem']) : <any>undefined;
      this.hasFiles = _data['hasFiles'];
      if (Array.isArray(_data['files'])) {
        this.files = [] as any;
        for (const item of _data['files'])
          this.files!.push(ActivityFile.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ActivityEventItem {
    data = typeof data === 'object' ? data : {};
    const result = new ActivityEventItem();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['ownerId'] = this.ownerId;
    data['id'] = this.id;
    data['activityRowKey'] = this.activityRowKey;
    data['activityId'] = this.activityId;
    data['accountId'] = this.accountId;
    data['personId'] = this.personId;
    data['internalInbox'] = this.internalInbox;
    data['threadId'] = this.threadId;
    data['offsetDateCreated'] = this.offsetDateCreated ? this.offsetDateCreated.toISOString() : <any>undefined;
    data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data['offsetDateUpdated'] = this.offsetDateUpdated ? this.offsetDateUpdated.toISOString() : <any>undefined;
    data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data['offsetEventDate'] = this.offsetEventDate ? this.offsetEventDate.toISOString() : <any>undefined;
    data['eventDate'] = this.eventDate ? this.eventDate.toISOString() : <any>undefined;
    data['eventContext'] = this.eventContext;
    data['eventCategory'] = this.eventCategory;
    data['eventCategoryRef'] = this.eventCategoryRef;
    data['eventSubCategory'] = this.eventSubCategory;
    data['eventSubCategoryRef'] = this.eventSubCategoryRef;
    data['actionReference'] = this.actionReference;
    if (Array.isArray(this.referenceFields)) {
      data['referenceFields'] = [];
      for (const item of this.referenceFields)
        data['referenceFields'].push(item.toJSON());
    }
    if (Array.isArray(this.referenceContent)) {
      data['referenceContent'] = [];
      for (const item of this.referenceContent)
        data['referenceContent'].push(item);
    }
    data['message'] = this.message;
    data['isSystem'] = this.isSystem;
    data['preventNotifySender'] = this.preventNotifySender;
    data['authorPersonId'] = this.authorPersonId;
    data['authorAccountId'] = this.authorAccountId;
    data['authorInternal'] = this.authorInternal;
    data['authorTeamId'] = this.authorTeamId;
    data['isPending'] = this.isPending;
    data['isDelivered'] = this.isDelivered;
    data['isComplete'] = this.isComplete;
    data['offsetDateCompleted'] = this.offsetDateCompleted ? this.offsetDateCompleted.toISOString() : <any>undefined;
    data['dateCompleted'] = this.dateCompleted ? this.dateCompleted.toISOString() : <any>undefined;
    data['offsetDateDue'] = this.offsetDateDue ? this.offsetDateDue.toISOString() : <any>undefined;
    data['dateDue'] = this.dateDue ? this.dateDue.toISOString() : <any>undefined;
    data['priority'] = this.priority;
    data['actionScheduleId'] = this.actionScheduleId;
    data['hasSchedule'] = this.hasSchedule;
    data['completedPersonId'] = this.completedPersonId;
    data['completedStatus'] = this.completedStatus;
    data['completedByActivityId'] = this.completedByActivityId;
    if (Array.isArray(this.ownerRecipients)) {
      data['ownerRecipients'] = [];
      for (const item of this.ownerRecipients)
        data['ownerRecipients'].push(item);
    }
    data['outgoingTrackItem'] = this.outgoingTrackItem ? this.outgoingTrackItem.toJSON() : <any>undefined;
    data['incomingTrackItem'] = this.incomingTrackItem ? this.incomingTrackItem.toJSON() : <any>undefined;
    data['hasFiles'] = this.hasFiles;
    if (Array.isArray(this.files)) {
      data['files'] = [];
      for (const item of this.files)
        data['files'].push(item.toJSON());
    }
    return data;
  }
}

export interface IActivityEventItem {
  ownerId?: string | undefined;
  id?: string;
  activityRowKey?: string | undefined;
  activityId?: string;
  accountId?: string | undefined;
  personId?: string | undefined;
  internalInbox?: boolean;
  threadId?: string | undefined;
  offsetDateCreated?: Date;
  dateCreated?: Date;
  offsetDateUpdated?: Date;
  dateUpdated?: Date;
  offsetEventDate?: Date | undefined;
  eventDate?: Date | undefined;
  eventContext?: string | undefined;
  eventCategory?: string | undefined;
  eventCategoryRef?: string | undefined;
  eventSubCategory?: string | undefined;
  eventSubCategoryRef?: string | undefined;
  actionReference?: string | undefined;
  referenceFields?: ReferenceField[] | undefined;
  referenceContent?: string[] | undefined;
  message?: string | undefined;
  isSystem?: boolean;
  preventNotifySender?: boolean;
  authorPersonId?: string;
  authorAccountId?: string | undefined;
  authorInternal?: boolean;
  authorTeamId?: string | undefined;
  isPending?: boolean;
  isDelivered?: boolean;
  isComplete?: boolean;
  offsetDateCompleted?: Date | undefined;
  dateCompleted?: Date | undefined;
  offsetDateDue?: Date | undefined;
  dateDue?: Date | undefined;
  priority?: number | undefined;
  actionScheduleId?: string | undefined;
  hasSchedule?: boolean;
  completedPersonId?: string | undefined;
  completedStatus?: string | undefined;
  completedByActivityId?: string | undefined;
  ownerRecipients?: string[] | undefined;
  outgoingTrackItem?: ActivityTracking | undefined;
  incomingTrackItem?: ActivityTracking | undefined;
  hasFiles?: boolean;
  files?: ActivityFile[] | undefined;
}
