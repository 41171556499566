import {ICommonSavePayload} from '../Common.actions';
import {AdminUserEdit} from '../../types/requests/AdminUserEdit';
import {AxiosResponse} from 'axios';
import authenticatedApi from '../../@template/services/auth/authApi/authenticatedApi';
import {coreApiConfig} from '../../config';
import {AdminUserView} from '../../types/views/AdminUserView';
import {all, call, put, takeEvery} from '@redux-saga/core/effects';
import {
    ADMIN_USER_SEARCH,
    adminUserLoadError,
    adminUserLoadSuccess, ADMIN_USERS,
    adminUserSaveError,
    adminUserSaveSuccess, adminUserSearchError,
    adminUserSearchSuccess
} from './Actions';
import {parseErrorInfo, parseErrorToErrorClass} from '../../@template/helpers/errors';
import {SearchRequest} from '../../types/common/SearchRequest';
import {AdminUserSearchRequest} from '../../types/requests/AdminUserSearchRequest';
import {PagedEntityListOfAdminUserSearchItem} from '../../types/views/PagedEntityListOfAdminUserSearchItem';
import {PayloadAction} from '@reduxjs/toolkit';

export function* SaveAdminUser({ id, submission }: ICommonSavePayload<AdminUserEdit>) {
    console.log('SAGA - adminUserSaga - SaveAdminUser');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: id ? 'PUT' : 'POST',
            url: id ? `${coreApiConfig.Paths.adminUser}/${id}` : coreApiConfig.Paths.adminUser,
            data: submission
        });
        const adminUser: AdminUserView = AdminUserView.fromJS(res.data);
        yield put(adminUserSaveSuccess(adminUser));
    } catch (err) {
        yield put(adminUserSaveError(parseErrorToErrorClass(err)));
    }
}


export function* LoadAdminUser(id: string) {
    console.log('SAGA - adminUserSaga - LoadAdminUser');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'GET',
            url: `${coreApiConfig.Paths.adminUser}/${id}`,
        });
        const adminUser: AdminUserView = AdminUserView.fromJS(res.data);
        yield put(adminUserLoadSuccess(adminUser));
    } catch (err) {
        yield put(adminUserLoadError(parseErrorInfo(err)));
    }
}

export function* SearchAdminUsers({ page, pageSize, searchParams, sortBy, sortDirection }: SearchRequest<AdminUserSearchRequest>) {
    console.log('SAGA - adminUserSaga - SearchAdminUsers');
    try {
        const res: AxiosResponse = yield authenticatedApi.request({
            baseURL: coreApiConfig.Url,
            method: 'PUT',
            url: `${coreApiConfig.Paths.adminUser}/search?page=${page}&page_size=${pageSize}&sort=${sortBy}&order=${sortDirection}`,
            data: searchParams
        });
        const results: PagedEntityListOfAdminUserSearchItem = PagedEntityListOfAdminUserSearchItem.fromJS(res.data);
        yield put(adminUserSearchSuccess(results));
    } catch (err) {
        yield put(adminUserSearchError(parseErrorInfo(err)));
    }
}

export function* watchSaveAdminUser(){
    yield takeEvery(ADMIN_USERS.SAVE, ({ payload } : PayloadAction<ICommonSavePayload<AdminUserEdit>>) => SaveAdminUser(payload));
}

export function* watchLoadAdminUser(){
    yield takeEvery(ADMIN_USERS.LOAD, ({ payload } : PayloadAction<string>) => LoadAdminUser(payload));
}

export function* watchRefreshAdminUser(){
    yield takeEvery(ADMIN_USERS.REFRESH, ({ payload } : PayloadAction<string>) => LoadAdminUser(payload));
}

export function* watchSearchAdminUsers(){
    yield takeEvery(ADMIN_USER_SEARCH.LOAD, ({ payload } : PayloadAction<SearchRequest<AdminUserSearchRequest>>) => SearchAdminUsers(payload));
}

export default function* adminUserSaga() {
    yield all ([
        call(watchSaveAdminUser),
        call(watchRefreshAdminUser),
        call(watchLoadAdminUser),
        call(watchSearchAdminUsers),
    ])
}