import {PhoneNumber} from '../models/PhoneNumber';
import {AdminUserDetailView} from './AdminUserDetailView';

export class AdminUserView implements IAdminUserView {
    id?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    personNumber?: number | undefined;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    firstName?: string | undefined;
    knownAsName?: string | undefined;
    lastName?: string | undefined;
    middleName?: string | undefined;
    dateOfBirth?: Date | undefined;
    genderId?: number | undefined;
    email?: string | undefined;
    isEmailVerified?: boolean | undefined;
    phoneNumbers?: PhoneNumber[] | undefined;
    linkedInUrl?: string | undefined;
    isContact?: boolean;
    isUser?: boolean;
    isDeleted?: boolean;
    dateDeleted?: Date | undefined;
    deletedByUserId?: string | undefined;
    isUnsubscribed?: boolean;
    dateUnsubscribed?: Date | undefined;
    unsubscribedSetByPersonId?: string | undefined;
    fullName?: string | undefined;
    jobTitle?: string | undefined;
    jobDepartment?: string | undefined;
    userReference?: string | undefined;
    emailSignature?: string | undefined;
    userDetail?: AdminUserDetailView | undefined;

    constructor(data?: IAdminUserView) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'];
            this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
            this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
            this.personNumber = _data['personNumber'];
            this.createdByPersonId = _data['createdByPersonId'];
            this.updatedByPersonId = _data['updatedByPersonId'];
            this.firstName = _data['firstName'];
            this.knownAsName = _data['knownAsName'];
            this.lastName = _data['lastName'];
            this.middleName = _data['middleName'];
            this.dateOfBirth = _data['dateOfBirth'] ? new Date(_data['dateOfBirth'].toString()) : <any>undefined;
            this.genderId = _data['genderId'];
            this.email = _data['email'];
            this.isEmailVerified = _data['isEmailVerified'];
            if (Array.isArray(_data['phoneNumbers'])) {
                this.phoneNumbers = [] as any;
                for (const item of _data['phoneNumbers'])
                    this.phoneNumbers!.push(PhoneNumber.fromJS(item));
            }
            this.linkedInUrl = _data['linkedInUrl'];
            this.isContact = _data['isContact'];
            this.isUser = _data['isUser'];
            this.isDeleted = _data['isDeleted'];
            this.dateDeleted = _data['dateDeleted'] ? new Date(_data['dateDeleted'].toString()) : <any>undefined;
            this.deletedByUserId = _data['deletedByUserId'];
            this.isUnsubscribed = _data['isUnsubscribed'];
            this.dateUnsubscribed = _data['dateUnsubscribed'] ? new Date(_data['dateUnsubscribed'].toString()) : <any>undefined;
            this.unsubscribedSetByPersonId = _data['unsubscribedSetByPersonId'];
            this.fullName = _data['fullName'];
            this.jobTitle = _data['jobTitle'];
            this.jobDepartment = _data['jobDepartment'];
            this.userReference = _data['userReference'];
            this.emailSignature = _data['emailSignature'];
            this.userDetail = _data['userDetail'] ? AdminUserDetailView.fromJS(_data['userDetail']) : <any>undefined;
        }
    }

    static fromJS(data: any): AdminUserView {
        data = typeof data === 'object' ? data : {};
        const result = new AdminUserView();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id;
        data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
        data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
        data['personNumber'] = this.personNumber;
        data['createdByPersonId'] = this.createdByPersonId;
        data['updatedByPersonId'] = this.updatedByPersonId;
        data['firstName'] = this.firstName;
        data['knownAsName'] = this.knownAsName;
        data['lastName'] = this.lastName;
        data['middleName'] = this.middleName;
        data['dateOfBirth'] = this.dateOfBirth ? this.dateOfBirth.toISOString() : <any>undefined;
        data['genderId'] = this.genderId;
        data['email'] = this.email;
        data['isEmailVerified'] = this.isEmailVerified;
        if (Array.isArray(this.phoneNumbers)) {
            data['phoneNumbers'] = [];
            for (const item of this.phoneNumbers)
                data['phoneNumbers'].push(item.toJSON());
        }
        data['linkedInUrl'] = this.linkedInUrl;
        data['isContact'] = this.isContact;
        data['isUser'] = this.isUser;
        data['isDeleted'] = this.isDeleted;
        data['dateDeleted'] = this.dateDeleted ? this.dateDeleted.toISOString() : <any>undefined;
        data['deletedByUserId'] = this.deletedByUserId;
        data['isUnsubscribed'] = this.isUnsubscribed;
        data['dateUnsubscribed'] = this.dateUnsubscribed ? this.dateUnsubscribed.toISOString() : <any>undefined;
        data['unsubscribedSetByPersonId'] = this.unsubscribedSetByPersonId;
        data['fullName'] = this.fullName;
        data['jobTitle'] = this.jobTitle;
        data['jobDepartment'] = this.jobDepartment;
        data['userReference'] = this.userReference;
        data['emailSignature'] = this.emailSignature;
        data['userDetail'] = this.userDetail ? this.userDetail.toJSON() : <any>undefined;
        return data;
    }
}

export interface IAdminUserView {
    id?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    personNumber?: number | undefined;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    firstName?: string | undefined;
    knownAsName?: string | undefined;
    lastName?: string | undefined;
    middleName?: string | undefined;
    dateOfBirth?: Date | undefined;
    genderId?: number | undefined;
    email?: string | undefined;
    isEmailVerified?: boolean | undefined;
    phoneNumbers?: PhoneNumber[] | undefined;
    linkedInUrl?: string | undefined;
    isContact?: boolean;
    isUser?: boolean;
    isDeleted?: boolean;
    dateDeleted?: Date | undefined;
    deletedByUserId?: string | undefined;
    isUnsubscribed?: boolean;
    dateUnsubscribed?: Date | undefined;
    unsubscribedSetByPersonId?: string | undefined;
    fullName?: string | undefined;
    jobTitle?: string | undefined;
    jobDepartment?: string | undefined;
    userReference?: string | undefined;
    emailSignature?: string | undefined;
    userDetail?: AdminUserDetailView | undefined;
}