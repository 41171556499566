import React, {useEffect, useState} from 'react';
import {AppState} from '../../../redux/store';
import {PersonListItem} from '../../../types/views/PersonListItem';
import {useAppDispatch} from '../../../@template/utility/AppHooks';
import {useNavigate} from 'react-router-dom';
import Person from '@mui/icons-material/Person';
import {useSelector} from 'react-redux';
import {personCacheAdd} from '../../../redux/personCache/Actions';
import { selectPersonColor} from '../../../@template/helpers/profileColors';
import { Tooltip} from '@mui/material';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

interface IPersonIconProps {
  personId?: string | undefined,
  size?: number | undefined,
  overrideColor?: string | undefined,
  onClick?(): void | undefined,
}

const getCacheItem = (id: string | undefined) => (state: AppState) => {
  if (!id) return undefined;
  return state.peopleCache.personCache.find((x: PersonListItem) => x.id === id);
}

const PersonAvatar = ({ personId, onClick, size = 20, overrideColor }: IPersonIconProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [ avatarPersonId ] = useState(personId);

  const listItem = useSelector(getCacheItem(avatarPersonId));

  useEffect(() => {
    if (!listItem && avatarPersonId) {
      dispatch(personCacheAdd([avatarPersonId]));
    }
  }, [ avatarPersonId ]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };
  if (listItem) {
    let { firstName, lastName} = listItem;
    if (!firstName) {
      firstName = '?';
    }
    if (!lastName){
      lastName = '?';
    }
    const initials = `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
    const backgroundColor = overrideColor ? overrideColor : selectPersonColor(initials);
    const fontColor = '#efefef';

    return (
      <Tooltip placement="bottom" title={`${firstName} ${lastName}`}>
        <>
          {listItem?.picture && <Box onClick={(e) => handleClick(e)}><Avatar src={listItem.picture} sx={{ width: size, height: size}} /></Box>}
          {!listItem?.picture && <Box onClick={(e) => handleClick(e)}><Avatar sx={{ backgroundColor: backgroundColor, color: fontColor, fontSize: `${size * 0.5}px`, width: size, height: size }}>
            {initials}
          </Avatar></Box>}
        </>
      </Tooltip>
    );
  }
  return <Avatar onClick={(e) => handleClick(e)} sx={{ bgcolor: '#dfdfdf', width: size, height: size }}><Person /></Avatar>;
};

export default PersonAvatar;