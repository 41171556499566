import {all, call, takeEvery, put, select} from '@redux-saga/core/effects';
import {
  ACCOUNT_CACHE,
  accountCacheError,
  accountCacheMerge,
  accountCacheUpdate,
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {PayloadAction} from '@reduxjs/toolkit';
import {AppState} from '../store';
import {IAccountsCacheState} from './Reducer';
import {coreApiConfig} from '../../config';
import {parseErrorInfo} from '../../@template/helpers/errors';
import {AxiosResponse} from 'axios';
import {AccountSearchItem} from '../../types/views/AccountSearchItem';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const accountCacheState = (state: AppState) => state.accountCache;

export function* LoadAccountCacheItems(idList: string[]) {
  console.log('SAGA - accountCacheSaga - LoadAccountCacheItems', idList);
  try {
    const res: AxiosResponse = yield authApi.request({
      baseURL: coreApiConfig.Url,
      method: 'PUT',
      url: '/account/list',
      data: idList
    });
    const accounts: AccountSearchItem[] = res.data.map((x: any) => AccountSearchItem.fromJS(x));
    yield put(accountCacheMerge(accounts));
  } catch (err) {
    console.log('Cache load error', err);
    yield put(accountCacheError(parseErrorInfo(err)));
  }
}

export function* AddAccountCacheItems() {
  console.log('SAGA - accountCacheSaga - AddAccountCacheItems');
  yield call(delay, 200);
  const currentAccountCache: IAccountsCacheState = yield select(accountCacheState);
  const currentPendingList = currentAccountCache.accountPendingIdList;
  if (currentPendingList && currentPendingList.length > 0) {
    console.log('SAGA - accountCacheSaga - AddAccountCacheItems LOad', currentPendingList);
    yield put(accountCacheUpdate([...currentPendingList]));
  }
}

export function* watchLoadAccountCacheItems(){
  yield takeEvery(ACCOUNT_CACHE.UPDATE, ({ payload }: PayloadAction<string[]>) => LoadAccountCacheItems(payload));
}

export function* watchAddAccountCacheItems(){
  yield takeEvery(ACCOUNT_CACHE.ADD, ({ payload }: PayloadAction<string[]>) => AddAccountCacheItems());
}

export default function* accountCacheSaga() {
  yield all ([
    call(watchLoadAccountCacheItems),
    call(watchAddAccountCacheItems)
  ])
}