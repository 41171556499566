export class AdminAdminUserDetail implements IAdminAdminUserDetail {
    isDisabled?: boolean;
    dateDisabled?: Date | undefined;
    disabledByUserId?: string | undefined;
    disabledReason?: string | undefined;
    jobTitle?: string | undefined;
    jobDepartment?: string | undefined;
    userReference?: string | undefined;
    emailSignature?: string | undefined;

    constructor(data?: IAdminAdminUserDetail) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.isDisabled = _data['isDisabled'];
            this.dateDisabled = _data['dateDisabled'] ? new Date(_data['dateDisabled'].toString()) : <any>undefined;
            this.disabledByUserId = _data['disabledByUserId'];
            this.disabledReason = _data['disabledReason'];
            this.jobTitle = _data['jobTitle'];
            this.jobDepartment = _data['jobDepartment'];
            this.userReference = _data['userReference'];
            this.emailSignature = _data['emailSignature'];
        }
    }

    static fromJS(data: any): AdminAdminUserDetail {
        data = typeof data === 'object' ? data : {};
        const result = new AdminAdminUserDetail();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['isDisabled'] = this.isDisabled;
        data['dateDisabled'] = this.dateDisabled ? this.dateDisabled.toISOString() : <any>undefined;
        data['disabledByUserId'] = this.disabledByUserId;
        data['disabledReason'] = this.disabledReason;
        data['jobTitle'] = this.jobTitle;
        data['jobDepartment'] = this.jobDepartment;
        data['userReference'] = this.userReference;
        data['emailSignature'] = this.emailSignature;
        return data;
    }
}

export interface IAdminAdminUserDetail {
    isDisabled?: boolean;
    dateDisabled?: Date | undefined;
    disabledByUserId?: string | undefined;
    disabledReason?: string | undefined;
    jobTitle?: string | undefined;
    jobDepartment?: string | undefined;
    userReference?: string | undefined;
    emailSignature?: string | undefined;
}