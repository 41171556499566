import {all, call, takeEvery, put, select} from '@redux-saga/core/effects';
import {AUTH, authSuccess, authRefresh, authError} from './Actions';
import authApi, {setAuthToken} from '../../@template/services/auth/authApi/authenticatedApi';
import {AuthUser} from '../../types/models/AuthUser';
import {selectCurrentUser} from './Selectors';
import {localitiesLoad, settingsLoad} from '../settings/Actions';
import {parseErrorInfo} from '../../@template/helpers/errors';
import {AxiosResponse} from 'axios';

export function* CheckAuthenticatedUser() {
  const currentUser: AuthUser | undefined = yield select(selectCurrentUser);
  if (!currentUser) {
    yield put(authRefresh());
  } else {
    yield put(authSuccess(currentUser));
  }
}

export function* LoginAuthenticatedUser() {
  try{
    const res: AxiosResponse = yield authApi.get('/auth');
    const authUser: AuthUser = new AuthUser(res.data);
    yield put(settingsLoad());
    yield put(localitiesLoad());
    yield put(authSuccess(authUser));
  } catch (e) {
    yield put(authError(parseErrorInfo(e)))
  }
}

/*
This is redundant due to single sign on. Leaving in place
to simplify refactoring if required.
 */
export function* RefreshAuthenticatedUser() {
  try{
    const res: AxiosResponse = yield authApi.get('/auth');
    const authUser: AuthUser = new AuthUser(res.data);
    yield put(settingsLoad());
    yield put(localitiesLoad());
    yield put(authSuccess(authUser));
  } catch (e) {
    yield put(authError(parseErrorInfo(e)))
  }
}

export function* Logout() {
  yield call(setAuthToken,null, null, null);
  yield put({ type: 'root/reset' });
}


export function* watchLoginAuthenticatedUser(){
  yield takeEvery(AUTH.LOGIN, LoginAuthenticatedUser);
}

export function* watchRefreshAuthenticatedUser(){
  yield takeEvery(AUTH.REFRESH, RefreshAuthenticatedUser);
}

export function* watchCheckAuthenticatedUser() {
  yield takeEvery(AUTH.CHECK, CheckAuthenticatedUser);
}

export function* watchLogout() {
  yield takeEvery(AUTH.LOGOUT, Logout)
}

export default function* authSaga() {
  yield all ([
    call(watchCheckAuthenticatedUser),
    call(watchLoginAuthenticatedUser),
    call(watchRefreshAuthenticatedUser),
    call(watchLogout),
  ])
}