export class Device implements IDevice {
    id?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    deviceType?: string | undefined;
    notes?: string | undefined;
    isDisabled?: boolean;
    dateDisabled?: Date | undefined;
    disabledByPersonId?: string | undefined;
    dateLastLogin?: Date;
    dateLastPing?: Date;
    deviceKey?: string | undefined;
    deviceSecret?: string | undefined;
    serialNumber?: string | undefined;

    constructor(data?: IDevice) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'];
            this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
            this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
            this.createdByPersonId = _data['createdByPersonId'];
            this.updatedByPersonId = _data['updatedByPersonId'];
            this.deviceType = _data['deviceType'];
            this.notes = _data['notes'];
            this.isDisabled = _data['isDisabled'];
            this.dateDisabled = _data['dateDisabled'] ? new Date(_data['dateDisabled'].toString()) : <any>undefined;
            this.disabledByPersonId = _data['disabledByPersonId'];
            this.dateLastLogin = _data['dateLastLogin'] ? new Date(_data['dateLastLogin'].toString()) : <any>undefined;
            this.dateLastPing = _data['dateLastPing'] ? new Date(_data['dateLastPing'].toString()) : <any>undefined;
            this.deviceKey = _data['deviceKey'];
            this.deviceSecret = _data['deviceSecret'];
            this.serialNumber = _data['serialNumber'];
        }
    }

    static fromJS(data: any): Device {
        data = typeof data === 'object' ? data : {};
        const result = new Device();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id;
        data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
        data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
        data['createdByPersonId'] = this.createdByPersonId;
        data['updatedByPersonId'] = this.updatedByPersonId;
        data['deviceType'] = this.deviceType;
        data['notes'] = this.notes;
        data['isDisabled'] = this.isDisabled;
        data['dateDisabled'] = this.dateDisabled ? this.dateDisabled.toISOString() : <any>undefined;
        data['disabledByPersonId'] = this.disabledByPersonId;
        data['dateLastLogin'] = this.dateLastLogin ? this.dateLastLogin.toISOString() : <any>undefined;
        data['dateLastPing'] = this.dateLastPing ? this.dateLastPing.toISOString() : <any>undefined;
        data['deviceKey'] = this.deviceKey;
        data['deviceSecret'] = this.deviceSecret;
        data['serialNumber'] = this.serialNumber;
        return data;
    }
}

export interface IDevice {
    id?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    createdByPersonId?: string | undefined;
    updatedByPersonId?: string | undefined;
    deviceType?: string | undefined;
    notes?: string | undefined;
    isDisabled?: boolean;
    dateDisabled?: Date | undefined;
    disabledByPersonId?: string | undefined;
    dateLastLogin?: Date;
    dateLastPing?: Date;
    deviceKey?: string | undefined;
    deviceSecret?: string | undefined;
    serialNumber?: string | undefined;
}