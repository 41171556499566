import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {UI} from './Actions';

export interface IUIState {
  navCollapsed: boolean;
  initialPath: string | undefined;
  error: string | undefined;
  loading: boolean;
  isAppDrawerOpen: boolean;
  updatingContent: boolean;
  message: string | undefined;
  expandedCategories: string[];
}

const initialState: IUIState = {
  navCollapsed: false,
  initialPath: '/',
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: '',
  expandedCategories: []
};

const uiReducer = createReducer(initialState, {
    [UI.ROUTE_CHANGE]: (state) => {
      state.navCollapsed = false;
    },
    [UI.TOGGLE_NAV_COLLAPSED]: (state) => {
      state.navCollapsed = !state.navCollapsed;
    },
    [UI.SET_INITIAL_PATH]: (state, action: PayloadAction<string>) => {
      state.initialPath = action.payload;
    },
    [UI.SHOW_MESSAGE]: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    [UI.HIDE_MESSAGE]: (state) =>  {
      state.message = undefined;
      state.error = undefined;
    },
    [UI.TOGGLE_APP_DRAWER]: (state) => {
      state.isAppDrawerOpen = !state.isAppDrawerOpen;
    },
    [UI.EXPANDED_CATEGORIES]: (state, action: PayloadAction<string[]>) => {
      state.expandedCategories = action.payload;
    }
});

export default uiReducer;
