import {ActivityRecipient} from './ActivityRecipient';
import { ActivityAccount } from './ActivityAccount';

export class ActivityGroup implements IActivityGroup {
  personId?: string;
  ownerId?: string | undefined;
  recipientGroup?: string | undefined;
  id?: string;
  accountId?: string | undefined;
  offsetDateCreated?: Date;
  dateCreated?: Date;
  offsetDateUpdated?: Date;
  dateUpdated?: Date;
  offsetDateLastActivity?: Date;
  dateLastActivity?: Date;
  lastEventId?: string;
  lastEventRowKey?: string | undefined;
  isRead?: boolean;
  isComplete?: boolean;
  unread?: number;
  incomplete?: number;
  hasFiles?: boolean;
  lastThreadId?: string | undefined;
  recipientPeopleMetadata?: ActivityRecipient[] | undefined;
  recipientCompaniesMetadata?: ActivityAccount[] | undefined;
  lastContent?: string | undefined;
  lastSubject?: string | undefined;
  isArchived?: boolean;
  offsetDateArchived?: Date | undefined;
  dateArchived?: Date | undefined;

  constructor(data?: IActivityGroup) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.personId = _data['personId'];
      this.ownerId = _data['ownerId'];
      this.recipientGroup = _data['recipientGroup'];
      this.id = _data['id'];
      this.accountId = _data['accountId'];
      this.offsetDateCreated = _data['offsetDateCreated'] ? new Date(_data['offsetDateCreated'].toString()) : <any>undefined;
      this.dateCreated = _data['dateCreated'] ? new Date(_data['dateCreated'].toString()) : <any>undefined;
      this.offsetDateUpdated = _data['offsetDateUpdated'] ? new Date(_data['offsetDateUpdated'].toString()) : <any>undefined;
      this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
      this.offsetDateLastActivity = _data['offsetDateLastActivity'] ? new Date(_data['offsetDateLastActivity'].toString()) : <any>undefined;
      this.dateLastActivity = _data['dateLastActivity'] ? new Date(_data['dateLastActivity'].toString()) : <any>undefined;
      this.lastEventId = _data['lastEventId'];
      this.lastEventRowKey = _data['lastEventRowKey'];
      this.isRead = _data['isRead'];
      this.isComplete = _data['isComplete'];
      this.unread = _data['unread'];
      this.incomplete = _data['incomplete'];
      this.hasFiles = _data['hasFiles'];
      this.lastThreadId = _data['lastThreadId'];
      if (Array.isArray(_data['recipientPeopleMetadata'])) {
        this.recipientPeopleMetadata = [] as any;
        for (const item of _data['recipientPeopleMetadata'])
          this.recipientPeopleMetadata!.push(ActivityRecipient.fromJS(item));
      }
      if (Array.isArray(_data['recipientCompaniesMetadata'])) {
        this.recipientCompaniesMetadata = [] as any;
        for (const item of _data['recipientCompaniesMetadata'])
          this.recipientCompaniesMetadata!.push(ActivityAccount.fromJS(item));
      }
      this.lastContent = _data['lastContent'];
      this.lastSubject = _data['lastSubject'];
      this.isArchived = _data['isArchived'];
      this.offsetDateArchived = _data['offsetDateArchived'] ? new Date(_data['offsetDateArchived'].toString()) : <any>undefined;
      this.dateArchived = _data['dateArchived'] ? new Date(_data['dateArchived'].toString()) : <any>undefined;
    }
  }

  static fromJS(data: any): ActivityGroup {
    data = typeof data === 'object' ? data : {};
    const result = new ActivityGroup();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['personId'] = this.personId;
    data['ownerId'] = this.ownerId;
    data['recipientGroup'] = this.recipientGroup;
    data['id'] = this.id;
    data['accountId'] = this.accountId;
    data['offsetDateCreated'] = this.offsetDateCreated ? this.offsetDateCreated.toISOString() : <any>undefined;
    data['dateCreated'] = this.dateCreated ? this.dateCreated.toISOString() : <any>undefined;
    data['offsetDateUpdated'] = this.offsetDateUpdated ? this.offsetDateUpdated.toISOString() : <any>undefined;
    data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data['offsetDateLastActivity'] = this.offsetDateLastActivity ? this.offsetDateLastActivity.toISOString() : <any>undefined;
    data['dateLastActivity'] = this.dateLastActivity ? this.dateLastActivity.toISOString() : <any>undefined;
    data['lastEventId'] = this.lastEventId;
    data['lastEventRowKey'] = this.lastEventRowKey;
    data['isRead'] = this.isRead;
    data['isComplete'] = this.isComplete;
    data['unread'] = this.unread;
    data['incomplete'] = this.incomplete;
    data['hasFiles'] = this.hasFiles;
    data['lastThreadId'] = this.lastThreadId;
    if (Array.isArray(this.recipientPeopleMetadata)) {
      data['recipientPeopleMetadata'] = [];
      for (const item of this.recipientPeopleMetadata)
        data['recipientPeopleMetadata'].push(item.toJSON());
    }
    if (Array.isArray(this.recipientCompaniesMetadata)) {
      data['recipientCompaniesMetadata'] = [];
      for (const item of this.recipientCompaniesMetadata)
        data['recipientCompaniesMetadata'].push(item.toJSON());
    }
    data['lastContent'] = this.lastContent;
    data['lastSubject'] = this.lastSubject;
    data['isArchived'] = this.isArchived;
    data['offsetDateArchived'] = this.offsetDateArchived ? this.offsetDateArchived.toISOString() : <any>undefined;
    data['dateArchived'] = this.dateArchived ? this.dateArchived.toISOString() : <any>undefined;
    return data;
  }
}

export interface IActivityGroup {
  personId?: string;
  ownerId?: string | undefined;
  recipientGroup?: string | undefined;
  id?: string;
  accountId?: string | undefined;
  offsetDateCreated?: Date;
  dateCreated?: Date;
  offsetDateUpdated?: Date;
  dateUpdated?: Date;
  offsetDateLastActivity?: Date;
  dateLastActivity?: Date;
  lastEventId?: string;
  lastEventRowKey?: string | undefined;
  isRead?: boolean;
  isComplete?: boolean;
  unread?: number;
  incomplete?: number;
  hasFiles?: boolean;
  lastThreadId?: string | undefined;
  recipientPeopleMetadata?: ActivityRecipient[] | undefined;
  recipientCompaniesMetadata?: ActivityAccount[] | undefined;
  lastContent?: string | undefined;
  lastSubject?: string | undefined;
  isArchived?: boolean;
  offsetDateArchived?: Date | undefined;
  dateArchived?: Date | undefined;
}