import {Device} from '../../types/models/Device';
import {IError} from '../../@template/helpers/errors';
import {DeviceSearchRequest, defaultForDeviceSearchRequest} from '../../types/requests/DeviceSearchRequest';
import {DefaultSortByOptions} from '../../types/common/SortByOptions/DefaultSortByOptions';
import {SortOrderOptions} from '../../types/common/SortOrderOptions';
import {PagedEntityListOfDeviceSearchItem} from '../../types/views/PagedEntityListOfDeviceSearchItem';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {DEVICE_SEARCH, DEVICES} from './Actions';
import {SearchRequest} from '../../types/common/SearchRequest';

export interface IDeviceState {
    device: Device | undefined;
    deviceLoading: boolean | false;
    deviceLoadError: string | undefined;
    deviceSaving: boolean | false;
    deviceSaveError: IError | undefined;
    deviceSearchParams: DeviceSearchRequest;
    deviceSearchSortOption: DefaultSortByOptions;
    deviceSearchSortOrder: SortOrderOptions;
    devicesResult: PagedEntityListOfDeviceSearchItem | undefined;
    devicesSearching: boolean;
    deviceSearchError: string | undefined;
    deviceDevicesResult: PagedEntityListOfDeviceSearchItem | undefined;
    deviceDevicesSearching: boolean;
    deviceDevicesError: string | undefined;
}

const initialState: IDeviceState = {
    device: undefined,
    deviceLoading: false,
    deviceLoadError: undefined,
    deviceSaving: false,
    deviceSaveError: undefined,
    deviceSearchParams: defaultForDeviceSearchRequest(),
    deviceSearchSortOption: DefaultSortByOptions.Relevance,
    deviceSearchSortOrder: SortOrderOptions.Desc,
    devicesResult: undefined,
    devicesSearching: false,
    deviceSearchError: undefined,
    deviceDevicesResult: undefined,
    deviceDevicesSearching: false,
    deviceDevicesError: undefined,
}

const deviceReducer = createReducer(initialState, {
    [DEVICES.LOAD]: (state) => {
        state.device = undefined;
        state.deviceLoading = true;
        state.deviceLoadError = undefined;
        state.deviceSaveError = undefined;
    },
    [DEVICES.REFRESH]: (state) => {
        state.deviceLoading = false;
        state.deviceLoadError = undefined;
        state.deviceSaveError = undefined;
    },
    [DEVICES.LOADED]: (state, action: PayloadAction<Device>) => {
        state.deviceLoading = false;
        state.deviceLoadError = undefined;
        state.device = action.payload;
    },
    [DEVICES.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
        state.deviceLoading = false;
        state.deviceLoadError = action.payload;
        state.device = undefined;
    },
    [DEVICES.SAVE]: (state) => {
        state.deviceSaving = true;
        state.deviceSaveError = undefined;
    },
    [DEVICES.SAVED]: (state, action: PayloadAction<Device>) => {
        state.deviceSaving = false;
        state.deviceSaveError = undefined;
        state.device = action.payload;
    },
    [DEVICES.SAVE_ERROR]: (state, action: PayloadAction<IError>) => {
        state.deviceSaving = false;
        state.deviceSaveError = action.payload;
    },
    [DEVICE_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<DeviceSearchRequest>>) => {
        state.deviceSearchParams = action.payload.searchParams;
        state.deviceSearchSortOption = action.payload.sortBy??(DefaultSortByOptions.Relevance);
        state.deviceSearchSortOrder = action.payload.sortDirection??(SortOrderOptions.Desc);
        state.devicesSearching = true;
        state.deviceSearchError = undefined;
    },
    [DEVICE_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
        state.devicesSearching = false;
        state.deviceSearchError = action.payload;
    },
    [DEVICE_SEARCH.LOADED]: (state, action: PayloadAction<PagedEntityListOfDeviceSearchItem>) => {
        state.devicesSearching = false;
        state.deviceSearchError = undefined;
        state.devicesResult = action.payload;
    },
});

export default deviceReducer;