import {AdminUserView} from '../../types/views/AdminUserView';
import {IError} from '../../@template/helpers/errors';
import {defaultForAdminUserSearchRequest, AdminUserSearchRequest} from '../../types/requests/AdminUserSearchRequest';
import {DefaultSortByOptions} from '../../types/common/SortByOptions/DefaultSortByOptions';
import {SortOrderOptions} from '../../types/common/SortOrderOptions';
import {PagedEntityListOfAdminUserSearchItem} from '../../types/views/PagedEntityListOfAdminUserSearchItem';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {ADMIN_USER_SEARCH, ADMIN_USERS} from './Actions';
import {SearchRequest} from '../../types/common/SearchRequest';


export interface IAdminUserState {
    adminUser: AdminUserView | undefined;
    adminUserLoading: boolean | false;
    adminUserLoadError: string | undefined;
    adminUserSaving: boolean | false;
    adminUserSaveError: IError | undefined;
    adminUserSearchParams: AdminUserSearchRequest;
    adminUserSearchSortOption: DefaultSortByOptions;
    adminUserSearchSortOrder: SortOrderOptions;
    adminUsersResult: PagedEntityListOfAdminUserSearchItem | undefined;
    adminUsersSearching: boolean;
    adminUserSearchError: string | undefined;
    adminUserAdminUsersResult: PagedEntityListOfAdminUserSearchItem | undefined;
    adminUserAdminUsersSearching: boolean;
    adminUserAdminUsersError: string | undefined;
}

const initialState: IAdminUserState = {
    adminUser: undefined,
    adminUserLoading: false,
    adminUserLoadError: undefined,
    adminUserSaving: false,
    adminUserSaveError: undefined,
    adminUserSearchParams: defaultForAdminUserSearchRequest(),
    adminUserSearchSortOption: DefaultSortByOptions.Relevance,
    adminUserSearchSortOrder: SortOrderOptions.Desc,
    adminUsersResult: undefined,
    adminUsersSearching: false,
    adminUserSearchError: undefined,
    adminUserAdminUsersResult: undefined,
    adminUserAdminUsersSearching: false,
    adminUserAdminUsersError: undefined
}

const adminUserReducer = createReducer(initialState, {
    [ADMIN_USERS.LOAD]: (state) => {
        state.adminUser = undefined;
        state.adminUserLoading = true;
        state.adminUserLoadError = undefined;
        state.adminUserSaveError = undefined;
    },
    [ADMIN_USERS.REFRESH]: (state) => {
        state.adminUserLoading = false;
        state.adminUserLoadError = undefined;
        state.adminUserSaveError = undefined;
    },
    [ADMIN_USERS.LOADED]: (state, action: PayloadAction<AdminUserView>) => {
        state.adminUserLoading = false;
        state.adminUserLoadError = undefined;
        state.adminUser = action.payload;
    },
    [ADMIN_USERS.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
        state.adminUserLoading = false;
        state.adminUserLoadError = action.payload;
        state.adminUser = undefined;
    },
    [ADMIN_USERS.SAVE]: (state) => {
        state.adminUserSaving = true;
        state.adminUserSaveError = undefined;
    },
    [ADMIN_USERS.SAVED]: (state, action: PayloadAction<AdminUserView>) => {
        state.adminUserSaving = false;
        state.adminUserSaveError = undefined;
        state.adminUser = action.payload;
    },
    [ADMIN_USERS.SAVE_ERROR]: (state, action: PayloadAction<IError>) => {
        state.adminUserSaving = false;
        state.adminUserSaveError = action.payload;
    },
    [ADMIN_USER_SEARCH.LOAD]: (state, action: PayloadAction<SearchRequest<AdminUserSearchRequest>>) => {
        state.adminUserSearchParams = action.payload.searchParams;
        state.adminUserSearchSortOption = action.payload.sortBy??(DefaultSortByOptions.Relevance);
        state.adminUserSearchSortOrder = action.payload.sortDirection??(SortOrderOptions.Desc);
        state.adminUsersSearching = true;
        state.adminUserSearchError = undefined;
    },
    [ADMIN_USER_SEARCH.ERROR]: (state, action: PayloadAction<string>) => {
        state.adminUsersSearching = false;
        state.adminUserSearchError = action.payload;
    },
    [ADMIN_USER_SEARCH.LOADED]: (state, action: PayloadAction<PagedEntityListOfAdminUserSearchItem>) => {
        state.adminUsersSearching = false;
        state.adminUserSearchError = undefined;
        state.adminUsersResult = action.payload;
    },
});

export default adminUserReducer;