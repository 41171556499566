import {all, call, takeEvery, put} from '@redux-saga/core/effects';
import {
  FILE,
  fileLoadError,
  fileLoadSuccess,
  fileSaveError,
  fileSaveSuccess,
  filesSearchError,
  filesSearchSuccess
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {ICommonSavePayload} from '../Common.actions';
import {PayloadAction} from '@reduxjs/toolkit';
import {SearchRequest} from '../../types/common/SearchRequest';
import { FileMetadataSearchRequest } from 'types/requests/FileMetadataSearchRequest';
import {FileMetadata, mapToFileMetadata} from '../../types/models/files/FileMetadata';
import {IFileMetadataEdit} from '../../types/requests/FileMetadataEdit';
import {filesApiConfig} from '../../config';
import {PagedEntityListOfFileMetadataSearchItem} from '../../types/views/PagedEntityListOfFileMetadataSearchItem';
import {parseErrorInfo} from '../../@template/helpers/errors';
import {AxiosResponse} from 'axios';


export function* LoadFile(id: string) {
  console.log('SAGA - userSaga - LoadFile');
  try {
    const res: AxiosResponse = yield authApi.get(`/files/${id}`);
    if (res.data) {
      const file: FileMetadata = mapToFileMetadata(res.data);
      yield put(fileLoadSuccess(file));
    }
  } catch (err) {
    yield put(fileLoadError(parseErrorInfo(err)));
  }
}

export function* SaveFile({ id, submission }: ICommonSavePayload<IFileMetadataEdit>) {
  console.log('SAGA - companySaga - SaveFile');
  try {
    const res: AxiosResponse = yield authApi.request({
      baseURL: filesApiConfig.Url,
      method: id ? 'PUT' : 'POST',
      url: id ? `/files/${id}` : '/file',
      data: submission
    });
    const file: FileMetadata = mapToFileMetadata(res.data);
    yield put(fileSaveSuccess(file));
  } catch (err) {
    yield put(fileSaveError(parseErrorInfo(err)));
  }
}

export function* SearchFiles({ page, searchParams }: SearchRequest<FileMetadataSearchRequest>) {
  console.log('SAGA - fileSaga - SearchFiles');
  try {
    const res: AxiosResponse = yield authApi.request({
      baseURL: filesApiConfig.Url,
      method: 'PUT',
      url: `/files/search?page=${page}`,
      data: searchParams
    });
    const results: PagedEntityListOfFileMetadataSearchItem = PagedEntityListOfFileMetadataSearchItem.fromJS(res.data);
    yield put(filesSearchSuccess(results));
  } catch (err) {
    yield put(filesSearchError(parseErrorInfo(err)));
  }
}

export function* watchLoadFile(){
  yield takeEvery(FILE.LOAD,({ payload }: PayloadAction<any>) => LoadFile(payload));
}

export function* watchSaveFiles(){
  yield takeEvery(FILE.SAVE, ({ payload }: PayloadAction<any>) => SaveFile(payload));
}

export function* watchSearchFiles(){
  yield takeEvery(FILE.SEARCH, ({ payload } : PayloadAction<SearchRequest<FileMetadataSearchRequest>>) => SearchFiles(payload));
}

export function* watchSearchFilesRefresh(){
  yield takeEvery(FILE.SEARCH_REFRESH, ({ payload } : PayloadAction<SearchRequest<FileMetadataSearchRequest>>) => SearchFiles(payload));
}

export default function* filesSaga() {
  yield all ([
    call(watchLoadFile),
    call(watchSaveFiles),
    call(watchSearchFiles),
    call(watchSearchFilesRefresh)
  ])
}
