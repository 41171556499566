import {TempFileMetadata} from './TempFileMetadata';
import {SystemFileMetadata} from './SystemFileMetadata';
import {MessageFileMetadata} from './MessageFileMetadata';
import {PersonFileMetadata} from './PersonFileMetadata';
import {SessionFileMetadata} from './SessionFileMetadata';
import {MetadataType} from '../Enums';

export type FileMetadata = TempFileMetadata | SystemFileMetadata | MessageFileMetadata | PersonFileMetadata | SessionFileMetadata;

export const mapToFileMetadata = (data: any | undefined) => {
  switch (data['metadataType']) {
    case MetadataType.SystemFile:
      return SystemFileMetadata.fromJS(data);
    case MetadataType.MessageFile:
      return MessageFileMetadata.fromJS(data);
    case MetadataType.PersonFile:
      return PersonFileMetadata.fromJS(data);
    case MetadataType.SessionFile:
      return SessionFileMetadata.fromJS(data);
    default:
      return TempFileMetadata.fromJS(data);
  }
}