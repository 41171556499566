import {createAction} from '@reduxjs/toolkit';
import {ICommonCacheActionType} from '../Common.actions';
import {DeviceSearchItem} from '../../types/views/DeviceSearchItem';


export const DEVICE_CACHE: ICommonCacheActionType = {
  ADD: 'deviceCache/add',
  UPDATE: 'deviceCache/updated',
  MERGE_ITEMS: 'deviceCache/merge',
  ERROR: 'deviceCache/error',
  REMOVE: 'deviceCache/remove'
};

export const deviceCacheAdd = createAction<string[]>(DEVICE_CACHE.ADD);
export const deviceCacheUpdate = createAction<string[]>(DEVICE_CACHE.UPDATE);
export const deviceCacheMerge = createAction<DeviceSearchItem[]>(DEVICE_CACHE.MERGE_ITEMS);
export const deviceCacheError = createAction<string>(DEVICE_CACHE.ERROR);
export const deviceCacheRemove = createAction<string>(DEVICE_CACHE.REMOVE);
