import {Account} from '../../types/models/Account';
import {IError} from '../../@template/helpers/errors';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {ACCOUNTS_PARTICIPANT} from './Actions';

export interface IAccountParticipantState {
    accountParticipant: Account | undefined;
    accountParticipantLoading: boolean | false;
    accountParticipantLoadError: string | undefined;
    accountParticipantSaving: boolean | false;
    accountParticipantSaveError: IError | undefined;
}

const initialState: IAccountParticipantState = {
    accountParticipant: undefined,
    accountParticipantLoading: false,
    accountParticipantLoadError: undefined,
    accountParticipantSaving: false,
    accountParticipantSaveError: undefined,
}

const accountReducer = createReducer(initialState, {
    [ACCOUNTS_PARTICIPANT.LOAD]: (state) => {
        state.accountParticipant = undefined;
        state.accountParticipantLoading = true;
        state.accountParticipantLoadError = undefined;
        state.accountParticipantSaveError = undefined;
    },
    [ACCOUNTS_PARTICIPANT.REFRESH]: (state) => {
        state.accountParticipantLoading = false;
        state.accountParticipantLoadError = undefined;
        state.accountParticipantSaveError = undefined;
    },
    [ACCOUNTS_PARTICIPANT.LOADED]: (state, action: PayloadAction<Account>) => {
        state.accountParticipantLoading = false;
        state.accountParticipantLoadError = undefined;
        state.accountParticipant = action.payload;
    },
    [ACCOUNTS_PARTICIPANT.LOAD_ERROR]: (state, action: PayloadAction<string>) => {
        state.accountParticipantLoading = false;
        state.accountParticipantLoadError = action.payload;
        state.accountParticipant = undefined;
    },
    [ACCOUNTS_PARTICIPANT.SAVE]: (state) => {
        state.accountParticipantSaving = true;
        state.accountParticipantSaveError = undefined;
    },
    [ACCOUNTS_PARTICIPANT.SAVED]: (state, action: PayloadAction<Account>) => {
        state.accountParticipantSaving = false;
        state.accountParticipantSaveError = undefined;
        state.accountParticipant = action.payload;
    },
    [ACCOUNTS_PARTICIPANT.SAVE_ERROR]: (state, action: PayloadAction<IError>) => {
        state.accountParticipantSaving = false;
        state.accountParticipantSaveError = action.payload;
    },
});

export default accountReducer;