import React from 'react';
import {AppRouteData, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import {accountPaths} from './paths';


const accountSectionBreadcrumbs = [new BreadcrumbItemProps('Accounts', accountPaths.AccountSearch)];

const EditPage = React.lazy(() => import('./AccountEdit'));
const DetailPage = React.lazy(() => import('./AccountProfile'));
const SearchPage = React.lazy(() => import('./AccountSearch'));

const accountRouteConfig = [
    { path: accountPaths.AccountSearch, element: <SearchPage />, handle: AppRouteData.create('Search', undefined, accountSectionBreadcrumbs) } ,
    { path: accountPaths.AccountProfile, element: <DetailPage />, handle: AppRouteData.create('Details', undefined, accountSectionBreadcrumbs) } ,
    { path: accountPaths.AccountCreate, element: <EditPage />, handle: AppRouteData.create('Create', undefined, accountSectionBreadcrumbs) } ,
    { path: accountPaths.AccountEdit, element: <EditPage />, handle: AppRouteData.create('Edit', undefined, accountSectionBreadcrumbs) } ,
];

export default accountRouteConfig;