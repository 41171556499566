import {dashboardPaths} from './dashboard/paths';
import {adminUserPaths} from './adminUsers/paths';
import {devicePaths} from './devices/paths';
import {accountPaths} from './accounts/paths';
import {developerPaths} from './developer/paths';
import {instructorPaths} from './instructors/paths';


export interface NavItemProps {
    id: string;
    label: string;
    title: string;
    icon?: string;
    exact?: boolean;
    url?: string;
    type?: string;
    count?: number;
    color?: string;
    auth?: string[];
    children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
    {
        id: 'home',
        title: 'Dashboard',
        label: 'Dashboard',
        type: 'item',
        icon: 'dashboard',
        url: dashboardPaths.LandingPage,
    },
    {
        id: 'accounts',
        title: 'Organisation Accounts',
        label: 'Organisation Accounts',
        type: 'collapse',
        icon: 'badge',
        children: [
            {
                id: 'accounts-manage',
                title: 'Manage Accounts',
                label: 'Manage Accounts',
                type: 'item',
                url: accountPaths.AccountSearch,
            },
            {
                id: 'accounts-add',
                title: 'Add Account',
                label: 'Add Account',
                type: 'item',
                url: accountPaths.AccountCreate,
            }
        ],
    },
    {
        id: 'instructor-users',
        title: 'Instructor Accounts',
        label: 'Instructor Accounts',
        type: 'collapse',
        icon: 'local_police',
        children: [
            {
                id: 'instructor-user-manage',
                title: 'Manage accounts',
                label: 'Manage accounts',
                type: 'item',
                url: instructorPaths.InstructorSearch,
            }
        ],
    },
    {
        id: 'devices',
        title: 'Devices',
        label: 'Devices',
        type: 'collapse',
        icon: 'devices',
        children: [
            {
                id: 'devices-manage',
                title: 'Manage Devices',
                label: 'Manage Devices',
                type: 'item',
                url: devicePaths.DeviceSearch,
            },
            {
                id: 'devices-add',
                title: 'Add Device',
                label: 'Add Device',
                type: 'item',
                url: devicePaths.DeviceCreate,
            }
        ],
    },
    {
        id: 'admin-users',
        title: 'Admin Users',
        label: 'Admin Users',
        type: 'collapse',
        icon: 'people',
        children: [
            {
                id: 'admin-user-manage',
                title: 'Manage users',
                label: 'Manage users',
                type: 'item',
                url: adminUserPaths.AdminUserSearch,
            },
            {
                id: 'admin-user-create',
                title: 'Add Admin User',
                label: 'Add Admin User',
                type: 'item',
                url: adminUserPaths.AdminUserCreate,
            },
        ],
    },
    {
        id: 'admin',
        title: 'Developer',
        label: 'Developer',
        type: 'collapse',
        icon: 'admin_panel_settings',
        children: [
            {
                id: 'dev-reindex',
                title: 'Dev Reindex',
                label: 'Dev Reindex',
                type: 'item',
                url: developerPaths.DevReindex,
            }
        ],
    },
];
export default routesConfig;
