import {ActivityThread} from '../models/ActivityThread';
import {PersonCountEvent} from './PersonCountEvent';

export class InboxThreadSummary implements IInboxThreadSummary {
  unreadThreadList?: ActivityThread[] | undefined;
  incompleteThreadList?: ActivityThread[] | undefined;
  summaries?: PersonCountEvent[] | undefined;

  constructor(data?: IInboxThreadSummary) {
    if (data) {
      for (const property in data) {
        if (Object.prototype.hasOwnProperty.call(data, property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      if (Array.isArray(_data['unreadThreadList'])) {
        this.unreadThreadList = [] as any;
        for (const item of _data['unreadThreadList'])
          this.unreadThreadList!.push(ActivityThread.fromJS(item));
      }
      if (Array.isArray(_data['incompleteThreadList'])) {
        this.incompleteThreadList = [] as any;
        for (const item of _data['incompleteThreadList'])
          this.incompleteThreadList!.push(ActivityThread.fromJS(item));
      }
      if (Array.isArray(_data['summaries'])) {
        this.summaries = [] as any;
        for (const item of _data['summaries'])
          this.summaries!.push(PersonCountEvent.fromJS(item));
      }
    }
  }

  static fromJS(data: any): InboxThreadSummary {
    data = typeof data === 'object' ? data : {};
    const result = new InboxThreadSummary();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.unreadThreadList)) {
      data['unreadThreadList'] = [];
      for (const item of this.unreadThreadList)
        data['unreadThreadList'].push(item.toJSON());
    }
    if (Array.isArray(this.incompleteThreadList)) {
      data['incompleteThreadList'] = [];
      for (const item of this.incompleteThreadList)
        data['incompleteThreadList'].push(item.toJSON());
    }
    if (Array.isArray(this.summaries)) {
      data['summaries'] = [];
      for (const item of this.summaries)
        data['summaries'].push(item.toJSON());
    }
    return data;
  }
}

export interface IInboxThreadSummary {
  unreadThreadList?: ActivityThread[] | undefined;
  incompleteThreadList?: ActivityThread[] | undefined;
  summaries?: PersonCountEvent[] | undefined;
}
