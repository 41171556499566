export class ReferenceField implements IReferenceField {
  referenceName?: string | undefined;
  referenceValue?: string | undefined;

  constructor(data?: IReferenceField) {
    if (data) {
      for (const property in data) {
        if (Object.prototype.hasOwnProperty.call(data, property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.referenceName = _data['referenceName'];
      this.referenceValue = _data['referenceValue'];
    }
  }

  static fromJS(data: any): ReferenceField {
    data = typeof data === 'object' ? data : {};
    const result = new ReferenceField();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['referenceName'] = this.referenceName;
    data['referenceValue'] = this.referenceValue;
    return data;
  }
}

export interface IReferenceField {
  referenceName?: string | undefined;
  referenceValue?: string | undefined;
}