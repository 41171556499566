import {PhoneType} from './Enums';

export class PhoneNumber implements IPhoneNumber {
  phoneType?: PhoneType;
  number?: string | undefined;

  constructor(data?: IPhoneNumber) {
    if (data) {
      for (const property in data) {
        if (Object.prototype.hasOwnProperty.call(data, property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.phoneType = _data['phoneType'];
      this.number = _data['number'];
    }
  }

  static fromJS(data: any): PhoneNumber {
    data = typeof data === 'object' ? data : {};
    const result = new PhoneNumber();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['phoneType'] = this.phoneType;
    data['number'] = this.number;
    return data;
  }
}

export interface IPhoneNumber {
  phoneType?: PhoneType;
  number?: string | undefined;
}
