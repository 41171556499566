export class SessionParticipantReviewFile implements ISessionParticipantReviewFile {
    fileId?: string;
    assetType?: string | undefined;

    constructor(data?: ISessionParticipantReviewFile) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.fileId = _data['fileId'];
            this.assetType = _data['assetType'];
        }
    }

    static fromJS(data: any): SessionParticipantReviewFile {
        data = typeof data === 'object' ? data : {};
        const result = new SessionParticipantReviewFile();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['fileId'] = this.fileId;
        data['assetType'] = this.assetType;
        return data;
    }
}

export interface ISessionParticipantReviewFile {
    fileId?: string;
    assetType?: string | undefined;
}