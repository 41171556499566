import {ActionCreatorWithoutPayload, ActionCreatorWithPayload, createAction} from '@reduxjs/toolkit';

export interface IUIActionTypes {
  SET_INITIAL_PATH: string;
  ROUTE_CHANGE: string;
  TOGGLE_NAV_COLLAPSED: string;
  TOGGLE_APP_DRAWER: string;
  SHOW_MESSAGE: string;
  HIDE_MESSAGE: string;
  EXPANDED_CATEGORIES: string;
}

export const UI: IUIActionTypes = {
  SET_INITIAL_PATH: 'ui/setInitialPath',
  ROUTE_CHANGE: 'ui/routeChange',
  TOGGLE_NAV_COLLAPSED: 'ui/toggleNavCollapsed',
  TOGGLE_APP_DRAWER: 'ui/toggleAppDrawer',
  SHOW_MESSAGE: 'ui/showMessage',
  HIDE_MESSAGE: 'ui/hideMessage',
  EXPANDED_CATEGORIES: 'ui/expandedCategories',
}

export const showMessage: ActionCreatorWithPayload<string> = createAction(UI.SHOW_MESSAGE);
export const hideMessage: ActionCreatorWithoutPayload = createAction<void>(UI.HIDE_MESSAGE);
export const onToggleAppDrawer: ActionCreatorWithoutPayload = createAction<void>(UI.TOGGLE_APP_DRAWER);
export const onRouteChanged: ActionCreatorWithoutPayload = createAction<void>(UI.ROUTE_CHANGE);
export const toggleNavCollapsed: ActionCreatorWithoutPayload = createAction<void>(UI.TOGGLE_NAV_COLLAPSED);
export const setExpandedCategories: ActionCreatorWithPayload<string[]> = createAction(UI.EXPANDED_CATEGORIES);
export const setInitialPath: ActionCreatorWithPayload<string | undefined> = createAction<string | undefined>(UI.SET_INITIAL_PATH);
