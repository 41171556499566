export class InstructorUserDetailView implements IInstructorUserDetailView {
    dateLastLogin?: Date | undefined;
    mobileDeviceId?: string | undefined;
    notificationId?: string | undefined;

    constructor(data?: IInstructorUserDetailView) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.dateLastLogin = _data['dateLastLogin'] ? new Date(_data['dateLastLogin'].toString()) : <any>undefined;
            this.mobileDeviceId = _data['mobileDeviceId'];
            this.notificationId = _data['notificationId'];
        }
    }

    static fromJS(data: any): InstructorUserDetailView {
        data = typeof data === 'object' ? data : {};
        const result = new InstructorUserDetailView();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['dateLastLogin'] = this.dateLastLogin ? this.dateLastLogin.toISOString() : <any>undefined;
        data['mobileDeviceId'] = this.mobileDeviceId;
        data['notificationId'] = this.notificationId;
        return data;
    }
}

export interface IInstructorUserDetailView {
    dateLastLogin?: Date | undefined;
    mobileDeviceId?: string | undefined;
    notificationId?: string | undefined;
}