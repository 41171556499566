import {RouteTabParameter} from '../../types/common/RouteTabParameter';


export interface IDevicePaths {
    DeviceEdit: string,
    DeviceCreate: string,
    DeviceProfile: string,
    DeviceSearch: string,
}

export const devicePaths: IDevicePaths = {
    DeviceEdit: '/devices/edit/:idParam',
    DeviceCreate: '/devices/create',
    DeviceProfile: '/devices/view/:idParam',
    DeviceSearch: '/devices/search',
};

export interface IDeviceProfileTabs {
    activity: RouteTabParameter;
    details: RouteTabParameter;
}

export const deviceProfileTabs: IDeviceProfileTabs = {
    activity: new RouteTabParameter(0, 'Activity', 'activity'),
    details: new RouteTabParameter(1, 'Details', 'details')
}

export const profileTabs = [
    deviceProfileTabs.activity,
    deviceProfileTabs.details
];

export const getDeviceProfileTabs = (query: string | undefined | null) => profileTabs.find(x => x.query === query);