import {ICommonSaveActionType, ICommonSavePayload, ICommonSearchActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {InstructorUserEdit} from '../../types/requests/InstructorUserEdit';
import {InstructorUserView} from '../../types/views/InstructorUserView';
import {IError} from '../../@template/helpers/errors';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {InstructorUserSearchRequest} from '../../types/requests/InstructorUserSearchRequest';
import {SessionSearchRequest} from '../../types/requests/SessionSearchRequest';
import {PagedEntityListOfSessionSearchItem} from '../../types/views/PagedEntityListOfSessionSearchItem';
import {
    PagedEntityListOfAdminInstructorUserSearchItem
} from '../../types/views/PagedEntityListOfAdminInstructorUserSearchItem';


export const INSTRUCTOR_USERS: ICommonSaveActionType = {
    SAVE: 'instructorUser/save',
    SAVED: 'instructorUser/saveSuccess',
    SAVE_ERROR: 'instructorUser/saveError',
    LOAD: 'instructorUser/load',
    REFRESH: 'instructorUser/refresh',
    LOAD_ERROR: 'instructorUser/loadError',
    LOADED: 'instructorUser/loadSuccess',
}

export const INSTRUCTOR_USER_SEARCH: ICommonSearchActionType = {
    CHANGE_VIEW: 'instructorUserSearch/changeView',
    ERROR: 'instructorUserSearch/error',
    LOAD: 'instructorUserSearch/load',
    LOADED: 'instructorUserSearch/loaded',
    REFRESH: 'instructorUserSearch/refresh'
}

export const INSTRUCTOR_USER_SESSION_SEARCH: ICommonSearchActionType = {
    CHANGE_VIEW: 'instructorUserSessionSearch/changeView',
    ERROR: 'instructorUserSessionSearch/error',
    LOAD: 'instructorUserSessionSearch/load',
    LOADED: 'instructorUserSessionSearch/loaded',
    REFRESH: 'instructorUserSessionSearch/refresh'
}

export interface IInstructorLoadProps {
    id: string;
    accountId: string;
}

export const instructorUserSave = createAction<ICommonSavePayload<InstructorUserEdit>>(INSTRUCTOR_USERS.SAVE);
export const instructorUserSaveSuccess = createAction<InstructorUserView>(INSTRUCTOR_USERS.SAVED);
export const instructorUserSaveError = createAction<IError>(INSTRUCTOR_USERS.SAVE_ERROR);

export const instructorUserLoad = createAction<IInstructorLoadProps>(INSTRUCTOR_USERS.LOAD);
export const instructorUserRefresh = createAction<IInstructorLoadProps>(INSTRUCTOR_USERS.REFRESH);

export const instructorUserLoadSuccess = createAction<InstructorUserView>(INSTRUCTOR_USERS.LOADED);
export const instructorUserLoadError = createAction<string>(INSTRUCTOR_USERS.LOAD_ERROR);

export const instructorUserSearch = createAction<ISearchRequest<InstructorUserSearchRequest>>(INSTRUCTOR_USER_SEARCH.LOAD);
export const instructorUserSearchSuccess = createAction<PagedEntityListOfAdminInstructorUserSearchItem>(INSTRUCTOR_USER_SEARCH.LOADED);
export const instructorUserSearchError = createAction<string>(INSTRUCTOR_USER_SEARCH.ERROR);

export const instructorUserSessionsSearch = createAction<ISearchRequest<SessionSearchRequest>>(INSTRUCTOR_USER_SESSION_SEARCH.LOAD);
export const instructorUserSessionsRefresh = createAction<ISearchRequest<SessionSearchRequest>>(INSTRUCTOR_USER_SESSION_SEARCH.REFRESH);
export const instructorUserSessionsSearchSuccess = createAction<PagedEntityListOfSessionSearchItem>(INSTRUCTOR_USER_SESSION_SEARCH.LOADED);
export const instructorUserSessionsError = createAction<string>(INSTRUCTOR_USER_SESSION_SEARCH.ERROR);