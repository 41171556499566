import {createAction} from '@reduxjs/toolkit';

export interface IBackgroundWorkProgressActionTypes {
  SAVE: string;
  SAVE_FILE: string;
  CLEAR: string;
  ERROR: string;
  SUCCESS: string;
}

export const BACKGROUND_WORK_PROGRESS: IBackgroundWorkProgressActionTypes = {
  CLEAR: 'jobProgress/clear',
  ERROR: 'jobProgress/error',
  SAVE: 'jobProgress/save',
  SAVE_FILE: 'jobProgress/saveFile',
  SUCCESS: 'jobProgress/success',
}

export const backgroundWorkProgressSave = createAction<string>(BACKGROUND_WORK_PROGRESS.SAVE);
export const backgroundWorkProgressFileSave = createAction<string>(BACKGROUND_WORK_PROGRESS.SAVE_FILE);
export const backgroundWorkProgressClear = createAction(BACKGROUND_WORK_PROGRESS.CLEAR);
export const backgroundWorkProgressSuccess = createAction<string[]>(BACKGROUND_WORK_PROGRESS.SUCCESS);
export const backgroundWorkProgressError = createAction<string>(BACKGROUND_WORK_PROGRESS.ERROR);
