import {alphaRE} from './regexPatterns';

const color1Letters = /[AFKPUZ]$/;
const color2Letters = /[BGLQV]$/;
const color3Letters = /[CHMRW]$/;
const color4Letters = /[DINSX]$/;
const color5Letters = /[EJOTY]$/;

const color1 = '#F4827C';
const color2 = '#545D89';
const color3 = '#F49D6E';
const color4 = '#87687C';
const color5 = '#8D8E8E';

const compColor1 = '#0A8FDC';
const compColor2 = '#1A936F';
const compColor3 = '#88D498';
const compColor4 = '#475B5A';
const compColor5 = '#52D1DC';

const selectAccountColor = (initial: string) => {
  let firstLetter = 'A';
  if (initial && initial.match(alphaRE)) {
    firstLetter = initial.toUpperCase().substr(0, 1);
  }
  if (color1Letters.test(firstLetter)) return compColor1;
  if (color2Letters.test(firstLetter)) return compColor2;
  if (color3Letters.test(firstLetter)) return compColor3;
  if (color4Letters.test(firstLetter)) return compColor4;
  if (color5Letters.test(firstLetter)) return compColor5;
};

const selectPersonColor = (initial: string) => {
  let firstLetter = 'A';
  if (initial && initial.match(alphaRE)) {
    firstLetter = initial.toUpperCase().substr(0, 1);
  }
  if (color1Letters.test(firstLetter)) return color1;
  if (color2Letters.test(firstLetter)) return color2;
  if (color3Letters.test(firstLetter)) return color3;
  if (color4Letters.test(firstLetter)) return color4;
  if (color5Letters.test(firstLetter)) return color5;
};

export {
  selectAccountColor,
  selectPersonColor
};