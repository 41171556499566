import {ICommonSaveActionType, ICommonSavePayload, ICommonSearchActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {DeviceEdit} from '../../types/requests/DeviceEdit';
import {Device} from '../../types/models/Device';
import {IError} from '../../@template/helpers/errors';
import {ISearchRequest} from '../../types/common/SearchRequest';
import {DeviceSearchRequest} from '../../types/requests/DeviceSearchRequest';
import {PagedEntityListOfDeviceSearchItem} from '../../types/views/PagedEntityListOfDeviceSearchItem';

export const DEVICES: ICommonSaveActionType = {
    SAVE: 'device/save',
    SAVED: 'device/saveSuccess',
    SAVE_ERROR: 'device/saveError',
    LOAD: 'device/load',
    REFRESH: 'device/refresh',
    LOAD_ERROR: 'device/loadError',
    LOADED: 'device/loadSuccess',
}

export const DEVICE_SEARCH: ICommonSearchActionType = {
    CHANGE_VIEW: 'deviceSearch/changeView',
    ERROR: 'deviceSearch/error',
    LOAD: 'deviceSearch/load',
    LOADED: 'deviceSearch/loaded',
    REFRESH: 'deviceSearch/refresh'
}

export const deviceSave = createAction<ICommonSavePayload<DeviceEdit>>(DEVICES.SAVE);
export const deviceSaveSuccess = createAction<Device>(DEVICES.SAVED);
export const deviceSaveError = createAction<IError>(DEVICES.SAVE_ERROR);
export const deviceLoad = createAction<string>(DEVICES.LOAD);
export const deviceRefresh = createAction<string>(DEVICES.REFRESH);
export const deviceLoadSuccess = createAction<Device>(DEVICES.LOADED);
export const deviceLoadError = createAction<string>(DEVICES.LOAD_ERROR);
export const deviceSearch = createAction<ISearchRequest<DeviceSearchRequest>>(DEVICE_SEARCH.LOAD);
export const deviceSearchSuccess = createAction<PagedEntityListOfDeviceSearchItem>(DEVICE_SEARCH.LOADED);
export const deviceSearchError = createAction<string>(DEVICE_SEARCH.ERROR);