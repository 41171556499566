import React from 'react';
import {useNavigate, useMatches} from 'react-router-dom';
import {NavLink} from '../../index';
import {AppRouteData, BreadcrumbItemProps} from '../../../types/AppRoutesPropType';
import makeStyles from '@mui/styles/makeStyles';
import {Theme} from '@mui/material';
import {Fonts} from '../../../shared/constants/AppEnums';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import ArrowIcon from '@mui/icons-material/ArrowForwardIos';
import {dashboardPaths} from '../../../modules/dashboard/paths';


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '0.6rem',
    marginBottom: 5,
    marginLeft: 15,
    marginRight: 15,
    marginTop: 15,
    paddingLeft: 5,
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 5,
    // backgroundColor: '#FBFCFF',
    // borderColor: '#efefef',
    // borderStyle: 'solid',
    // borderWidth: '1px',
    // borderRadius: '2px',

  },
  breadcrumbItem: {
    color: theme.palette.text.secondary,
    fontSize: '0.8rem',
    alignItems: 'center',
    fontFamily: '"Rajdhani", "Roboto", "Helvetica", "Arial", sans-serif',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.secondary,
      fontWeight: Fonts.BOLD,
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none'
      }
    },
  },
  breadcrumbIcon: {
    width: 10,
    height: 10,
    margin: '0px 4px 0px 5px',
    paddingTop: '2px',
    color: theme.palette.text.secondary,
  },
  breadcrumbHomeIcon: {
    width: 18,
    height: 18,
    color: theme.palette.text.secondary,
  },
}));


const BreadCrumb = () => {

  const classes = useStyles();

  const navigate = useNavigate();
  const matches = useMatches();
  const currentRoute = AppRouteData.fromJS(matches[matches.length-1].handle);

  const handleHomeClicked = () => {
    navigate(dashboardPaths.LandingPage);
  }

  return (
    <Box className={classes.container}>
      <Box>
        <IconButton
          edge='start'
          color='inherit'
          aria-label='open drawer'
          onClick={() => handleHomeClicked()}
        >
          <HomeIcon className={classes.breadcrumbHomeIcon} />
        </IconButton>
      </Box>
      {currentRoute.breadcrumbs?.map((el: BreadcrumbItemProps, idx: number) => {
        if (el.path) {
          return <Box component="div" className={classes.breadcrumbItem} key={`appHeader-bc-${idx}`}>
            <ArrowIcon className={classes.breadcrumbIcon} /> <NavLink to={el.path}>{el.name}</NavLink>
          </Box>}
        else {
          return <Box component="div" className={classes.breadcrumbItem} key={`appHeader-bc-${idx}`}>
            <ArrowIcon className={classes.breadcrumbIcon} /> {el.name}
          </Box>
        }
      })}
      {currentRoute.title && <Box component="div" className={classes.breadcrumbItem} key={'appHeader-bc-current'}>
        <ArrowIcon className={classes.breadcrumbIcon} /> {currentRoute.title}
      </Box>}
  </Box>);
}

export default BreadCrumb;