import React, {useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DialogCloseButton from '../../@template/core/DialogCloseButton';
import {useSelector} from 'react-redux';
import {thirdCol, twoThirdCol} from '../../@template/utility/FormLayout/layoutConfig';
import GridContainer from '../../@template/core/GridContainer';
import SemiCircleProgress from '../../@template/core/SemiCircleProgress';
import useRecursiveTimeout from '../../@template/utility/AppPolling';
import {coreApiConfig} from '../../config';
import authenticatedApi from '../../@template/services/auth/authApi/authenticatedApi';
import {Loader, MessageView} from '../../@template';
import {useAppDispatch} from '../../@template/utility/AppHooks';
import {hasItems} from '../../@template/helpers/arrays';
import {BackgroundWorkProgress} from '../../types/models/BackgroundWorkProgress';
import {BackgroundWorkError} from '../../types/models/BackgroundWorkError';
import PersonLink from '../people/PersonProfileLink';
import {
  selectBackgroundWorkError,
  selectBackgroundWorkErrorList,
  selectBackgroundWorkId,
  selectBackgroundWorkType,
} from '../../redux/backgroundWork/Selectors';
import {backgroundWorkProgressClear} from '../../redux/backgroundWork/Actions';
import {BackgroundWorkStatus} from '../../types/models/Enums';
import AccountProfileLink from '../accounts/AccountProfileLink';

const timeoutInterval = 2000;

const ProgressLoaderDialog = () => {
  const [backgroundWorkProgress, setBackgroundWorkProgress] = useState<BackgroundWorkProgress | undefined>(undefined);
  const [backgroundWorkTimeout, setJobTimeout] = useState<boolean>(false);
  const [backgroundWorkEndTime, setJobEndTime] = useState<Date | undefined>(undefined);
  const [fileDownloading, setFileDownloading] = useState<boolean>(false);
  const [error, setError] = useState<string| undefined>(undefined);
  const [showCloseDialog, setShowCloseDialog] = useState<boolean>(false);

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [errorLoading, setErrorLoading] = useState<boolean>(false);
  const [errorList, setErrorList] = useState<BackgroundWorkError[]>([]);

  const dispatch = useAppDispatch();

  const backgroundWorkErrorList = useSelector(selectBackgroundWorkErrorList);
  const backgroundWorkError = useSelector(selectBackgroundWorkError);
  const backgroundWorkId = useSelector(selectBackgroundWorkId);
  const backgroundWorkType = useSelector(selectBackgroundWorkType);

  let percent = 0;
  if(backgroundWorkProgress && backgroundWorkProgress?.itemsProcessed && backgroundWorkProgress.itemsProcessed > 0 && backgroundWorkProgress?.itemsTotal && backgroundWorkProgress.itemsTotal > 0){
    percent = Math.round(100 * backgroundWorkProgress.itemsProcessed / backgroundWorkProgress.itemsTotal);
  }

  const backgroundWorkRunning = !backgroundWorkProgress?.itemsTotal || !backgroundWorkProgress?.itemsProcessed || backgroundWorkProgress?.backgroundWorkStatus === BackgroundWorkStatus.InProgress;

  const loadJobUpdate = () => {
    const url = `${coreApiConfig.Paths.backgroundWork}/${backgroundWorkId}`;
    authenticatedApi.request({
      baseURL: coreApiConfig.Url,
      method: 'GET',
      url: url
    }).then(response => {
      setBackgroundWorkProgress(BackgroundWorkProgress.fromJS(response.data));
    }).catch(err => {
      setError(err);
      console.warn(err);
    }).finally(() => {
      if(backgroundWorkEndTime && backgroundWorkEndTime === new Date()) {
        handleJobTimeout();
      }
    });
  }

  const handleJobTimeout = () => {
    setJobTimeout(true);
    const url = `${coreApiConfig.Paths.backgroundWork}/${backgroundWorkId}/timeout`;
    authenticatedApi.request({
      baseURL: coreApiConfig.Url,
      method: 'PUT',
      url: url
    }).then(response => {
      console.log(response);
    }).catch(err => {
      console.log(err);
    });
  }

  const handleFileDownload = () => {
    setFileDownloading(true);
    // const url = `${coreApiConfig.filesPath}/file-builder/${backgroundWorkId}`;
    // authenticatedApi.request({
    //   baseURL: coreApiConfig.filesUrl,
    //   method: 'POST',
    //   url: url
    // }).then(response => {
    //   const currentDate = format(new Date(), 'yyyy-MM-Do hhmmss aaa');
    //   const fileName = ``;
    //   fileDownload(response.data, fileName);
    // }).catch(err => {
    //   console.log(err);
    // }).finally(() => {
    //   setFileDownloading(false);
    // });
  }

  const retrieveErrors = () => {
    setErrorLoading(true);
    const url = `${coreApiConfig.Paths.backgroundWork}/${backgroundWorkId}/errors`;
    authenticatedApi.request({
      baseURL: coreApiConfig.Url,
      method: 'GET',
      url: url
    }).then(response => {
      console.log(response);
      setErrorList(response.data.map((x: any) => BackgroundWorkError.fromJS(x)));
    }).catch(err => {
      setError(err);
      console.warn(err);
    }).finally(() => {
      setErrorLoading(false);
    });
  }

  useRecursiveTimeout(() =>
    new Promise<void>(r => {
      if (backgroundWorkRunning) loadJobUpdate();
      r();
    }), timeoutInterval);

  const showError = () => {
    return <MessageView variant="error" message="An error occurred initiating the backgroundWork progress." />;
  };

  const finishJob = () => {
    setShowCloseDialog(false);
    setJobEndTime(undefined);
    setJobTimeout(false);
    dispatch(backgroundWorkProgressClear());
  }

  const closeButton = () => {
    if(backgroundWorkRunning){
      return(<Button
        variant="contained"
        color="secondary"
        onClick={() => setShowCloseDialog(true)}
      >
        Cancel
      </Button>);
    } else {
      return(<Button
        variant="contained"
        color="secondary"
        onClick={finishJob}
      >
        Close
      </Button>);
    }
  }

  const openErrorModal = () => {
    setShowErrorModal(true);
    if(!hasItems(errorList)) {
      retrieveErrors();
    }
  }

  const renderError = (error: BackgroundWorkError) => {
    if(error.failedEntityId)
    {
      if (error?.entityType === 'Person') {
        return (<React.Fragment key={error.failedEntityId}>
          <PersonLink personId={error.failedEntityId} linkProfile={false} />
          <p>{error.errorMessage}</p>
        </React.Fragment>);
      }
      if (error?.entityType === 'Company') {
        return (<React.Fragment key={error.failedEntityId}>
          <AccountProfileLink accountId={error.failedEntityId} linkProfile={false} />
          <p>{error.errorMessage}</p>
        </React.Fragment>);
      }
    }
    return '';
  }

  if(showErrorModal) {
    return(
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      >
        <DialogTitle>
          Errors From Background Work
          <DialogCloseButton onClick={finishJob} />
        </DialogTitle>
        <DialogContent>
          {errorLoading && <Loader />}
          {!errorLoading && hasItems(errorList) && errorList.map(renderError)}
          {!errorLoading && !hasItems(errorList) && <Box component='h4'>There was a problem retrieving the errors</Box>}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowErrorModal(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (<>
    <Dialog open={showCloseDialog}>
      <DialogContent>
        {!(backgroundWorkType === 'FILE') && 'Closing this message will not stop execution of this backgroundWork'}
        {backgroundWorkType === 'FILE' && 'Closing this will prevent your ability to download the file'}
      </DialogContent>
      <DialogActions>
        <Button sx={{ display: 'inline' }} variant="contained" color="secondary" size="small" onClick={() => setShowCloseDialog(false)}>No</Button>
        <Button sx={{ display: 'inline' }} variant="contained" color="primary" size="small" onClick={finishJob}>Yes</Button>
      </DialogActions>
    </Dialog>
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={!!backgroundWorkId}
      onClose={finishJob}
    >
      {error && showError()}
      <DialogTitle>
        Sending Background Work
        <DialogCloseButton onClick={finishJob} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <GridContainer spacing={1}>
          <Grid item {...thirdCol}>
            <SemiCircleProgress
              strokeWidth={5}
              percentage={percent}
              showPercentValue={true}
            />
          </Grid>
          <Grid item {...twoThirdCol}>
            {backgroundWorkTimeout && <Box component="h4">This background work has exceeded the time allocated to it, but it could still be running in the background.</Box>}
            {!backgroundWorkTimeout && <>
              {backgroundWorkRunning && !backgroundWorkProgress?.backgroundWorkDescription && <Box component="p">Queuing Background Work</Box>}
              {backgroundWorkRunning && !!backgroundWorkProgress?.backgroundWorkDescription && <Box component="p">{backgroundWorkProgress?.backgroundWorkDescription}</Box>}
              {!backgroundWorkRunning && !backgroundWorkProgress?.itemsFailed && backgroundWorkProgress?.backgroundWorkDescription && <p>{backgroundWorkProgress?.itemsProcessed} of {backgroundWorkProgress?.itemsTotal} succeeded in {backgroundWorkProgress?.backgroundWorkDescription}</p>}
              {!backgroundWorkRunning && !backgroundWorkProgress?.itemsFailed && backgroundWorkProgress?.itemsTotal && backgroundWorkProgress?.itemsTotal > 0 && !backgroundWorkProgress?.backgroundWorkDescription && <p>{backgroundWorkProgress?.itemsProcessed} of {backgroundWorkProgress?.itemsTotal} item/s processed</p>}
              {!backgroundWorkRunning && !backgroundWorkProgress?.itemsFailed && backgroundWorkProgress?.itemsTotal == 0 && !backgroundWorkProgress?.backgroundWorkDescription && <p>No data found matching this search</p>}
              {!backgroundWorkRunning && !backgroundWorkProgress?.itemsFailed && backgroundWorkType === 'FILE' && <LoadingButton
                color="primary"
                variant="contained"
                size="medium"
                loading={fileDownloading}
                onClick={handleFileDownload}>
                Download File
              </LoadingButton>}
              {!backgroundWorkRunning && !!backgroundWorkProgress?.itemsFailed && <React.Fragment>
                <Box>{backgroundWorkProgress?.itemsSuccess} of {backgroundWorkProgress?.itemsProcessed} succeeded.</Box>
                <Box>{backgroundWorkProgress?.itemsFailed} of {backgroundWorkProgress?.itemsProcessed} failed. <a onClick={openErrorModal}><u style={{ cursor: 'pointer' }}>Click here to view Errors.</u></a></Box>
                <Box>Please contact support with any issues.</Box>
              </React.Fragment>}
            </>}
          </Grid>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          color="primary"
          variant="contained"
          size="medium"
          loading={backgroundWorkRunning}
          onClick={finishJob}
        >Background Work Completed</LoadingButton>
        {closeButton()}
      </DialogActions>
    </Dialog>
  </>);
}

export default ProgressLoaderDialog;
