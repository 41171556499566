import React from 'react';
import {dashboardPaths} from './paths';
import {AppPermissions} from '../../shared/constants/AppEnums';
import {AppRouteData, BreadcrumbItemProps} from '../../types/AppRoutesPropType';

const dashboardSectionBreadcrumbs = [new BreadcrumbItemProps('Home', dashboardPaths.LandingPage)];

const LandingPage = React.lazy(() => import('./Home/LandingPage'));

const dashboardAuth = [AppPermissions.PlatformAdmin];

const dashboardRoutesConfig = [
    { index: true, path: dashboardPaths.LandingPage, element: <LandingPage />, handle: AppRouteData.create('Dashboard', dashboardAuth, dashboardSectionBreadcrumbs) } ,
];

export default dashboardRoutesConfig;
