export const defaultFileSearchRequest = () => {
  const request = new FileMetadataSearchRequest();
  request.searchText = '';
  request.metadataType = undefined;
  request.mimeType = undefined;
  request.fileSize = 0;
  request.contentType = undefined;
  request.dateAddedFrom = undefined;
  request.dateAddedTo = undefined;
  request.isPrivate = false;
  request.isDeleted = false;
  request.tags = [];
  return request;
};

export class FileMetadataSearchRequest implements IFileMetadataSearchRequest {
    searchText?: string | undefined;
    id?: string | undefined;
    metadataType?: string | undefined;
    fileExtensionType?: string | undefined;
    mimeType?: string | undefined;
    fileSize?: number;
    contentType?: string | undefined;
    abstract?: string | undefined;
    categoryId?: string | undefined;
    personId?: string | undefined;
    accountId?: string | undefined;
    deviceId?: string | undefined;
    threadId?: string | undefined;
    eventId?: string | undefined;
    userId?: string | undefined;
    dateAddedFrom?: Date | undefined;
    dateAddedTo?: Date | undefined;
    isPrivate?: boolean;
    createdByPersonId?: string | undefined;
    updatedByUserId?: string | undefined;
    isDeleted?: boolean;
    deletedByUserId?: string | undefined;
    dateDeleted?: Date | undefined;
    tags?: string[] | undefined;

    constructor(data?: IFileMetadataSearchRequest) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.searchText = _data['searchText'];
            this.id = _data['id'];
            this.metadataType = _data['metadataType'];
            this.fileExtensionType = _data['fileExtensionType'];
            this.mimeType = _data['mimeType'];
            this.fileSize = _data['fileSize'];
            this.contentType = _data['contentType'];
            this.abstract = _data['abstract'];
            this.categoryId = _data['categoryId'];
            this.personId = _data['personId'];
            this.accountId = _data['accountId'];
            this.deviceId = _data['deviceId'];
            this.threadId = _data['threadId'];
            this.eventId = _data['eventId'];
            this.userId = _data['userId'];
            this.dateAddedFrom = _data['dateAddedFrom'] ? new Date(_data['dateAddedFrom'].toString()) : <any>undefined;
            this.dateAddedTo = _data['dateAddedTo'] ? new Date(_data['dateAddedTo'].toString()) : <any>undefined;
            this.isPrivate = _data['isPrivate'];
            this.createdByPersonId = _data['createdByPersonId'];
            this.updatedByUserId = _data['updatedByUserId'];
            this.isDeleted = _data['isDeleted'];
            this.deletedByUserId = _data['deletedByUserId'];
            this.dateDeleted = _data['dateDeleted'] ? new Date(_data['dateDeleted'].toString()) : <any>undefined;
            if (Array.isArray(_data['tags'])) {
                this.tags = [] as any;
                for (const item of _data['tags'])
                    this.tags!.push(item);
            }
        }
    }

    static fromJS(data: any): FileMetadataSearchRequest {
        data = typeof data === 'object' ? data : {};
        const result = new FileMetadataSearchRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['searchText'] = this.searchText;
        data['id'] = this.id;
        data['metadataType'] = this.metadataType;
        data['fileExtensionType'] = this.fileExtensionType;
        data['mimeType'] = this.mimeType;
        data['fileSize'] = this.fileSize;
        data['contentType'] = this.contentType;
        data['abstract'] = this.abstract;
        data['categoryId'] = this.categoryId;
        data['personId'] = this.personId;
        data['accountId'] = this.accountId;
        data['deviceId'] = this.deviceId;
        data['threadId'] = this.threadId;
        data['eventId'] = this.eventId;
        data['userId'] = this.userId;
        data['dateAddedFrom'] = this.dateAddedFrom ? this.dateAddedFrom.toISOString() : <any>undefined;
        data['dateAddedTo'] = this.dateAddedTo ? this.dateAddedTo.toISOString() : <any>undefined;
        data['isPrivate'] = this.isPrivate;
        data['createdByPersonId'] = this.createdByPersonId;
        data['updatedByUserId'] = this.updatedByUserId;
        data['isDeleted'] = this.isDeleted;
        data['deletedByUserId'] = this.deletedByUserId;
        data['dateDeleted'] = this.dateDeleted ? this.dateDeleted.toISOString() : <any>undefined;
        if (Array.isArray(this.tags)) {
            data['tags'] = [];
            for (const item of this.tags)
                data['tags'].push(item);
        }
        return data;
    }
}

export interface IFileMetadataSearchRequest {
    searchText?: string | undefined;
    id?: string | undefined;
    metadataType?: string | undefined;
    fileExtensionType?: string | undefined;
    mimeType?: string | undefined;
    fileSize?: number;
    contentType?: string | undefined;
    abstract?: string | undefined;
    categoryId?: string | undefined;
    personId?: string | undefined;
    accountId?: string | undefined;
    deviceId?: string | undefined;
    threadId?: string | undefined;
    eventId?: string | undefined;
    userId?: string | undefined;
    dateAddedFrom?: Date | undefined;
    dateAddedTo?: Date | undefined;
    isPrivate?: boolean;
    createdByPersonId?: string | undefined;
    updatedByUserId?: string | undefined;
    isDeleted?: boolean;
    deletedByUserId?: string | undefined;
    dateDeleted?: Date | undefined;
    tags?: string[] | undefined;
}