import {all, call, takeEvery, put, select} from '@redux-saga/core/effects';
import {
  PERSON_CACHE,
  personCacheError,
  personCacheMerge,
  personCacheUpdate,
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {PayloadAction} from '@reduxjs/toolkit';
import {PersonListItem} from '../../types/views/PersonListItem';
import {AppState} from '../store';
import {IPeopleCacheState} from './Reducer';
import {coreApiConfig} from '../../config';
import {parseErrorInfo} from '../../@template/helpers/errors';
import {AxiosResponse} from 'axios';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const personCacheState = (state: AppState) => state.peopleCache;

export function* LoadPersonCacheItems(idList: string[]) {
  console.log('SAGA - personCacheSaga - LoadPersonCacheItems');
  try {
    const res: AxiosResponse = yield authApi.request({
      baseURL: coreApiConfig.Url,
      method: 'PUT',
      url: '/person/list',
      data: idList
    });
    const people: PersonListItem[] = res.data.map((x: any) => PersonListItem.fromJS(x));
    yield put(personCacheMerge(people));
  } catch (err) {
    yield put(personCacheError(parseErrorInfo(err)));
  }
}

export function* AddPersonCacheItems() {
  console.log('SAGA - personCacheSaga - AddPersonCacheItems');
  yield call(delay, 200);
  const currentPersonCache: IPeopleCacheState = yield select(personCacheState);
  const currentPendingList = currentPersonCache.personPendingIdList;
  if (currentPendingList && currentPendingList.length > 0) {
    yield put(personCacheUpdate([...currentPendingList]));
  }
}

export function* watchLoadPersonCacheItems(){
  yield takeEvery(PERSON_CACHE.UPDATE, ({ payload }: PayloadAction<string[]>) => LoadPersonCacheItems(payload));
}

export function* watchAddPersonCacheItems(){
  yield takeEvery(PERSON_CACHE.ADD, ({ payload }: PayloadAction<string[]>) => AddPersonCacheItems());
}

export default function* personCacheSaga() {
  yield all ([
    call(watchLoadPersonCacheItems),
    call(watchAddPersonCacheItems)
  ])
}