import {ActivityGroupSearchRequest} from '../../types/requests/ActivityGroupSearchRequest';
import {ActivityGroup} from '../../types/models/AcitivityGroup';
import {ActivityThread} from '../../types/models/ActivityThread';
import {IInboxThreadSummary, InboxThreadSummary} from '../../types/views/InboxThreadSummary';
import {ActivityEventSearchRequest} from '../../types/requests/ActivityEventSearchRequest';
import {ActivityEventItem} from '../../types/models/ActivityEventItem';
import {createDraftSafeSelector, createReducer, PayloadAction} from '@reduxjs/toolkit';
import {GROUPS, MESSAGE, MESSAGE_FEED, THREAD, NOTIFICATIONS} from './Actions';
import {hasItems} from '../../@template/helpers/arrays';


export interface IMessagingState {
  groupsSearch: ActivityGroupSearchRequest | undefined;
  groupsList: ActivityGroup[] | undefined;
  groupsError: string | undefined;
  groupsLoading: boolean;
  groupsRefreshing: boolean;
  groupsThreadsList: ActivityThread[] | undefined;
  groupsThreadsError: string | undefined;
  groupsThreadsLoading: boolean;
  taskThreadsList: ActivityThread[] | undefined;
  taskThreadsError: string | undefined;
  taskThreadsLoading: boolean;
  taskThreadListRefreshing: boolean;
  notificationsSummary: IInboxThreadSummary | undefined;
  notificationsError: string | undefined;
  notificationsLoading: boolean;
  notificationsRefreshing: boolean;
  messageSearch: ActivityEventSearchRequest | undefined;
  messageList: ActivityEventItem[] | undefined;
  messageListStart: Date | undefined;
  messageListIsFullHistory: boolean;
  messageListError: string | undefined;
  messageListLoading: boolean;
  messageListRefreshing: boolean;
  messageListHistoryLoading: boolean;
  messageSaving: boolean;
  messageError: string | undefined;
  thread: ActivityThread | undefined;
  threadError: string | undefined;
  threadLoading: boolean;
}

const initialState: IMessagingState = {
  groupsSearch: undefined,
  groupsList: undefined,
  groupsError: undefined,
  groupsLoading: false,
  groupsRefreshing: false,
  groupsThreadsList: undefined,
  groupsThreadsError: undefined,
  groupsThreadsLoading: false,
  taskThreadsList: [],
  taskThreadsError: undefined,
  taskThreadsLoading: false,
  taskThreadListRefreshing: false,
  notificationsSummary: undefined,
  notificationsError: undefined,
  notificationsLoading: false,
  notificationsRefreshing: false,
  messageSearch: undefined,
  messageList: undefined,
  messageListStart: undefined,
  messageListIsFullHistory: false,
  messageListError: undefined,
  messageListLoading: false,
  messageListRefreshing: false,
  messageListHistoryLoading: false,
  messageSaving: false,
  messageError: undefined,
  thread: undefined,
  threadError: undefined,
  threadLoading: false,
};

const selectMessaging = (state: IMessagingState) => state
const selectMessageList = createDraftSafeSelector(
  selectMessaging,
  (state) => state.messageList??[]
)
const messagingReducer = createReducer(initialState, {
  [GROUPS.LOAD]: (state, action: PayloadAction<ActivityGroupSearchRequest>) => {
    state.groupsSearch = action.payload;
    state.groupsList = [];
    state.groupsRefreshing = false;
    state.groupsLoading = true;
    state.groupsError = undefined;
  },
  [GROUPS.REFRESH]: (state, action: PayloadAction<ActivityGroupSearchRequest>) => {
    state.groupsSearch = action.payload;
    state.groupsRefreshing = true;
    state.groupsLoading = false;
    state.groupsError = undefined;
  },
  [GROUPS.ERROR]: (state, action: PayloadAction<string>) => {
    state.groupsRefreshing = false;
    state.groupsLoading = false;
    state.groupsError = action.payload;
  },
  [GROUPS.LOADED]: (state, action: PayloadAction<ActivityGroup[]>) => {
    state.groupsList = action.payload;
    state.groupsRefreshing = false;
    state.groupsLoading = false;
    state.groupsError = undefined;
  },
  [GROUPS.THREADS_LOAD]: (state) => {
    state.groupsThreadsList = [];
    state.groupsThreadsError = undefined;
    state.groupsThreadsLoading = true;
  },
  [GROUPS.THREADS_LOADED]: (state, action: PayloadAction<ActivityThread[]>) => {
    state.groupsThreadsList = action.payload;
    state.groupsThreadsError = undefined;
    state.groupsThreadsLoading = false;
  },
  [GROUPS.THREADS_ERROR]: (state, action: PayloadAction<string>) => {
    state.groupsThreadsError = action.payload;
    state.groupsThreadsLoading = true;
  },
  [THREAD.LOAD]: (state) => {
    state.thread = undefined;
    state.threadError = undefined;
    state.threadLoading = false;
  },
  [THREAD.ERROR]: (state, action: PayloadAction<string>) => {
    state.threadError = action.payload;
    state.threadLoading = false;
  },
  [THREAD.LOADED]: (state, action: PayloadAction<ActivityThread>) => {
    state.thread = action.payload;
    state.threadError = undefined;
    state.threadLoading = false;
  },
  [MESSAGE.SAVE]: (state) => {
    state.messageSaving = true;
    state.messageError = undefined;
  },
  [MESSAGE.SAVED]: (state) => {
    state.messageSaving = false;
    state.messageError = undefined;
  },
  [MESSAGE.ERROR]: (state, action: PayloadAction<string>) => {
    state.messageSaving = false;
    state.messageError = action.payload;
  },
  [MESSAGE_FEED.LOAD]: (state, action: PayloadAction<ActivityEventSearchRequest>) => {
    state.messageSearch = action.payload;
    state.messageList = undefined;
    state.messageListStart = undefined;
    state.messageListIsFullHistory = false;
    state.messageListError = undefined;
    state.messageListLoading = true;
    state.messageListRefreshing = false;
    state.messageListHistoryLoading = false;
  },
  [MESSAGE_FEED.REFRESH]: (state, action: PayloadAction<ActivityEventSearchRequest>) => {
    state.messageSearch = action.payload;
    state.messageListError = undefined;
    state.messageListRefreshing = true;
  },
  [MESSAGE_FEED.LOADED]: (state, action: PayloadAction<ActivityEventItem[]>) => {
    state.messageListError = undefined;
    state.messageListRefreshing = false;
    state.messageListLoading = false;
    if (action.payload && hasItems(action.payload)) {
      const feedOldest = action.payload[action.payload.length - 1].dateCreated;
      const currentList = selectMessageList(state)??[];
      const newList: Map<string, ActivityEventItem> = new Map(currentList.map((x) => { return [x.id??'', x]}));
      action.payload.forEach(item => newList.set(item.id??'', item));
      // This approach ensures all new values for existing messages overwrites old values
      state.messageList = [...newList.values()];
      state.messageListStart = feedOldest;
    }
  },
  [MESSAGE_FEED.HISTORY_LOAD]: (state, action: PayloadAction<ActivityEventSearchRequest>) => {
    state.messageSearch = action.payload;
    state.messageListError = undefined;
    state.messageListHistoryLoading = true;
  },
  [MESSAGE_FEED.HISTORY_LOADED]: (state, action: PayloadAction<ActivityEventItem[]>) => {
    if (action.payload && hasItems(action.payload)) {
      if (action.payload && hasItems(action.payload)) {
        const historyOldest = action.payload[action.payload.length - 1].dateCreated;
        const currentHistoryList = selectMessageList(state)??[];
        const newHistoryList: Map<string, ActivityEventItem> = new Map(currentHistoryList.map((x) => { return [x.id??'', x]}));
        action.payload.forEach(item => {newHistoryList.set(item.id??'', item)});
        // This approach ensures all new values for existing messages overwrites old values
        state.messageList = [...newHistoryList.values()];
        state.messageListStart = historyOldest;
      }
    } else {
      state.messageListIsFullHistory = true;
    }
    state.messageListRefreshing = false;
    state.messageListLoading = false;
    state.messageListError = undefined;
    state.messageListHistoryLoading = false;
  },
  [MESSAGE_FEED.ERROR]: (state, action: PayloadAction<string>) => {
    state.messageListError = action.payload;
    state.messageListRefreshing = false;
    state.messageListLoading = false;
  },
  [NOTIFICATIONS.LOAD]: (state) => {
    state.notificationsSummary = undefined;
    state.notificationsError = undefined;
    state.notificationsLoading = true;
    state.notificationsRefreshing = false;
  },
  [NOTIFICATIONS.REFRESH]: (state) => {
    state.notificationsError = undefined;
    state.notificationsLoading = false;
    state.notificationsRefreshing = true;
  },
  [NOTIFICATIONS.ERROR]: (state, action: PayloadAction<string>) => {
    state.notificationsError = action.payload;
    state.notificationsLoading = false;
    state.notificationsRefreshing = false;
  },
  [NOTIFICATIONS.LOADED]: (state, action: PayloadAction<InboxThreadSummary>) => {
    state.notificationsSummary = action.payload;
    state.notificationsError = undefined;
    state.notificationsLoading = false;
    state.notificationsRefreshing = false;
  },
});

export default messagingReducer;