import {AppRouteData, BreadcrumbItemProps} from '../../types/AppRoutesPropType';
import React from 'react';
import {AppPermissions} from '../../shared/constants/AppEnums';
import {developerPaths} from './paths';

const developerSectionBreadcrumbs = [new BreadcrumbItemProps('Admin', developerPaths.DevReindex)];

const ReIndexPage = React.lazy(() => import('./DevReIndex'));

const developerAuth = [AppPermissions.PlatformAdmin];

const developerRouteConfig = [
    { path: developerPaths.DevReindex, element: <ReIndexPage />, handle: AppRouteData.create('Dev Reindex', developerAuth, developerSectionBreadcrumbs) } ,
];

export default developerRouteConfig;
