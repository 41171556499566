export class PersonCountEvent implements IPersonCountEvent {
  personId?: string;
  ownerId?: string | undefined;
  accountId?: string | undefined;
  offsetDateUpdated?: Date;
  dateUpdated?: Date;
  eventContext?: string | undefined;
  totalEvents?: number;
  incompleteEvents?: number;
  unreadEvents?: number;
  totalMessages?: number;
  totalUnreadMessages?: number;
  totalArchivedEvents?: number;
  totalArchivedMessages?: number;

  constructor(data?: IPersonCountEvent) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.personId = _data['personId'];
      this.ownerId = _data['ownerId'];
      this.accountId = _data['accountId'];
      this.offsetDateUpdated = _data['offsetDateUpdated'] ? new Date(_data['offsetDateUpdated'].toString()) : <any>undefined;
      this.dateUpdated = _data['dateUpdated'] ? new Date(_data['dateUpdated'].toString()) : <any>undefined;
      this.eventContext = _data['eventContext'];
      this.totalEvents = _data['totalEvents'];
      this.incompleteEvents = _data['incompleteEvents'];
      this.unreadEvents = _data['unreadEvents'];
      this.totalMessages = _data['totalMessages'];
      this.totalUnreadMessages = _data['totalUnreadMessages'];
      this.totalArchivedEvents = _data['totalArchivedEvents'];
      this.totalArchivedMessages = _data['totalArchivedMessages'];
    }
  }

  static fromJS(data: any): PersonCountEvent {
    data = typeof data === 'object' ? data : {};
    const result = new PersonCountEvent();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['personId'] = this.personId;
    data['ownerId'] = this.ownerId;
    data['accountId'] = this.accountId;
    data['offsetDateUpdated'] = this.offsetDateUpdated ? this.offsetDateUpdated.toISOString() : <any>undefined;
    data['dateUpdated'] = this.dateUpdated ? this.dateUpdated.toISOString() : <any>undefined;
    data['eventContext'] = this.eventContext;
    data['totalEvents'] = this.totalEvents;
    data['incompleteEvents'] = this.incompleteEvents;
    data['unreadEvents'] = this.unreadEvents;
    data['totalMessages'] = this.totalMessages;
    data['totalUnreadMessages'] = this.totalUnreadMessages;
    data['totalArchivedEvents'] = this.totalArchivedEvents;
    data['totalArchivedMessages'] = this.totalArchivedMessages;
    return data;
  }
}

export interface IPersonCountEvent {
  personId?: string;
  ownerId?: string | undefined;
  accountId?: string | undefined;
  offsetDateUpdated?: Date;
  dateUpdated?: Date;
  eventContext?: string | undefined;
  totalEvents?: number;
  incompleteEvents?: number;
  unreadEvents?: number;
  totalMessages?: number;
  totalUnreadMessages?: number;
  totalArchivedEvents?: number;
  totalArchivedMessages?: number;
}