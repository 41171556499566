export class ActivityTracking implements IActivityTracking {
  isSeen?: boolean;
  offsetDateFirstSeen?: Date | undefined;
  dateFirstSeen?: Date | undefined;
  offsetDateLastSeen?: Date | undefined;
  dateLastSeen?: Date | undefined;
  isBounced?: boolean;
  bouncedMessage?: string | undefined;
  offsetDateFirstBounced?: Date | undefined;
  dateFirstBounced?: Date | undefined;
  offsetDateLastBounced?: Date | undefined;
  dateLastBounced?: Date | undefined;
  isEmailDelivered?: boolean;
  offsetDateEmailDelivered?: Date | undefined;
  dateEmailDelivered?: Date | undefined;
  hasSourceData?: boolean;
  resolveEmailFlag?: boolean;
  resolveEmailAddress?: string | undefined;
  resolveEmailResolution?: string | undefined;
  offsetResolveEmailDateResolved?: Date | undefined;
  resolveEmailDateResolved?: Date | undefined;

  constructor(data?: IActivityTracking) {
    if (data) {
      for (const property in data) {
        if (Object.prototype.hasOwnProperty.call(data, property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.isSeen = _data['isSeen'];
      this.offsetDateFirstSeen = _data['offsetDateFirstSeen'] ? new Date(_data['offsetDateFirstSeen'].toString()) : <any>undefined;
      this.dateFirstSeen = _data['dateFirstSeen'] ? new Date(_data['dateFirstSeen'].toString()) : <any>undefined;
      this.offsetDateLastSeen = _data['offsetDateLastSeen'] ? new Date(_data['offsetDateLastSeen'].toString()) : <any>undefined;
      this.dateLastSeen = _data['dateLastSeen'] ? new Date(_data['dateLastSeen'].toString()) : <any>undefined;
      this.isBounced = _data['isBounced'];
      this.bouncedMessage = _data['bouncedMessage'];
      this.offsetDateFirstBounced = _data['offsetDateFirstBounced'] ? new Date(_data['offsetDateFirstBounced'].toString()) : <any>undefined;
      this.dateFirstBounced = _data['dateFirstBounced'] ? new Date(_data['dateFirstBounced'].toString()) : <any>undefined;
      this.offsetDateLastBounced = _data['offsetDateLastBounced'] ? new Date(_data['offsetDateLastBounced'].toString()) : <any>undefined;
      this.dateLastBounced = _data['dateLastBounced'] ? new Date(_data['dateLastBounced'].toString()) : <any>undefined;
      this.isEmailDelivered = _data['isEmailDelivered'];
      this.offsetDateEmailDelivered = _data['offsetDateEmailDelivered'] ? new Date(_data['offsetDateEmailDelivered'].toString()) : <any>undefined;
      this.dateEmailDelivered = _data['dateEmailDelivered'] ? new Date(_data['dateEmailDelivered'].toString()) : <any>undefined;
      this.hasSourceData = _data['hasSourceData'];
      this.resolveEmailFlag = _data['resolveEmailFlag'];
      this.resolveEmailAddress = _data['resolveEmailAddress'];
      this.resolveEmailResolution = _data['resolveEmailResolution'];
      this.offsetResolveEmailDateResolved = _data['offsetResolveEmailDateResolved'] ? new Date(_data['offsetResolveEmailDateResolved'].toString()) : <any>undefined;
      this.resolveEmailDateResolved = _data['resolveEmailDateResolved'] ? new Date(_data['resolveEmailDateResolved'].toString()) : <any>undefined;
    }
  }

  static fromJS(data: any): ActivityTracking {
    data = typeof data === 'object' ? data : {};
    const result = new ActivityTracking();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['isSeen'] = this.isSeen;
    data['offsetDateFirstSeen'] = this.offsetDateFirstSeen ? this.offsetDateFirstSeen.toISOString() : <any>undefined;
    data['dateFirstSeen'] = this.dateFirstSeen ? this.dateFirstSeen.toISOString() : <any>undefined;
    data['offsetDateLastSeen'] = this.offsetDateLastSeen ? this.offsetDateLastSeen.toISOString() : <any>undefined;
    data['dateLastSeen'] = this.dateLastSeen ? this.dateLastSeen.toISOString() : <any>undefined;
    data['isBounced'] = this.isBounced;
    data['bouncedMessage'] = this.bouncedMessage;
    data['offsetDateFirstBounced'] = this.offsetDateFirstBounced ? this.offsetDateFirstBounced.toISOString() : <any>undefined;
    data['dateFirstBounced'] = this.dateFirstBounced ? this.dateFirstBounced.toISOString() : <any>undefined;
    data['offsetDateLastBounced'] = this.offsetDateLastBounced ? this.offsetDateLastBounced.toISOString() : <any>undefined;
    data['dateLastBounced'] = this.dateLastBounced ? this.dateLastBounced.toISOString() : <any>undefined;
    data['isEmailDelivered'] = this.isEmailDelivered;
    data['offsetDateEmailDelivered'] = this.offsetDateEmailDelivered ? this.offsetDateEmailDelivered.toISOString() : <any>undefined;
    data['dateEmailDelivered'] = this.dateEmailDelivered ? this.dateEmailDelivered.toISOString() : <any>undefined;
    data['hasSourceData'] = this.hasSourceData;
    data['resolveEmailFlag'] = this.resolveEmailFlag;
    data['resolveEmailAddress'] = this.resolveEmailAddress;
    data['resolveEmailResolution'] = this.resolveEmailResolution;
    data['offsetResolveEmailDateResolved'] = this.offsetResolveEmailDateResolved ? this.offsetResolveEmailDateResolved.toISOString() : <any>undefined;
    data['resolveEmailDateResolved'] = this.resolveEmailDateResolved ? this.resolveEmailDateResolved.toISOString() : <any>undefined;
    return data;
  }
}

export interface IActivityTracking {
  isSeen?: boolean;
  offsetDateFirstSeen?: Date | undefined;
  dateFirstSeen?: Date | undefined;
  offsetDateLastSeen?: Date | undefined;
  dateLastSeen?: Date | undefined;
  isBounced?: boolean;
  bouncedMessage?: string | undefined;
  offsetDateFirstBounced?: Date | undefined;
  dateFirstBounced?: Date | undefined;
  offsetDateLastBounced?: Date | undefined;
  dateLastBounced?: Date | undefined;
  isEmailDelivered?: boolean;
  offsetDateEmailDelivered?: Date | undefined;
  dateEmailDelivered?: Date | undefined;
  hasSourceData?: boolean;
  resolveEmailFlag?: boolean;
  resolveEmailAddress?: string | undefined;
  resolveEmailResolution?: string | undefined;
  offsetResolveEmailDateResolved?: Date | undefined;
  resolveEmailDateResolved?: Date | undefined;
}